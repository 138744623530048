import { getYesOrNo } from "../projectConfiguration";

export const apiField = 'fully_adhered'

const config = {
    label: 'fullyAdhered',
    type: 'Select',
    apiField,
    missing: undefined,
    data: getYesOrNo()
};

export default config;
