import { asce_710, isASCE716 } from "__common/constants/buildingCodes";
import { SOIL_CLASS } from "../../fields/soilClass";
import { RoofExposureOption } from "../../fields/types/roofExposure";
import { windExposureOption } from "../../fields/types/windExposure";
import { calculateKe, calculateKz, get_design_life_factor, get_numerical_coefficient } from "../coefficients";
const DEFAULT_MEAN_RECURRENCE_INTERVAL = 50
export default () => {
  const ecofootProducts = {
    zipcode: '',
    client_name: '',
    building_code: 2,
    wind_speed: 115,
    snow_load: 5,
    seismic_ss: 0.46,
    seismic_s1: 0,
    elevation: 5499,
    mean_recurrence_interval: DEFAULT_MEAN_RECURRENCE_INTERVAL,
    block_weight: 32,
    dead_load_factor_modification: 0.6,
    deflectors: 0,
    setback_distance: 3,
    wind_exposure: 'B',
    ecofoot_surface: 1,
    slip_sheet: 2,
    central_support: 0,
    limit_down_point_loads: 0,
    risk_category: 2,
    nbcc_wind_pressure: 0.30,
    nbcc_category: 2,
    nbcc_roughness: 0,
    rain_load: 0.1,
    soil_class: SOIL_CLASS.D,
    velocity_pressure_exposure_coefficient_kz: calculateKz(windExposureOption.inTown.value, 15,''),
    ground_elevation_factor_ke: calculateKe(5499, isASCE716(2)),
    wind_directionality_factor_kd: 0.85,
    roof_exposure: RoofExposureOption.partiallyExposed.value,
    numberical_coefficient: get_numerical_coefficient(asce_710.value, 5499),
    topographical_factor_kzt: 1,
    design_life_factor_fc: get_design_life_factor(DEFAULT_MEAN_RECURRENCE_INTERVAL),
    building_height_greater_than_6_storey: 0,
    input_unit: 1,
    tornado_speed: 0,
    seismic_sds: 0.01,
    seismic_sd1: 0.01,
  };
  return ecofootProducts;
};
