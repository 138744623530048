import SnowLoadTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/snowLoadTooltip';
import { SnowLoadField, apiField } from 'projectDesign/components/projectConfiguration/fields/types/snowLoad';
import { InputUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';

function makeConfig(max: number = 100, label: string = 'snowpsf', disable: boolean = false): SnowLoadField {  
  return {
    type: 'Number',
    label,
    apiField,
    tooltip: SnowLoadTooltip,
    min: 0,
    max,
    missing:undefined,
    disable
  };
}

export const getSnowLoadField = (biggerRange = false, inputUnit: number = InputUnit.CUSTOMARY, isAurora: boolean = false) => {
  if (isMetricUnit(inputUnit)){
    if (isAurora){
      return SmAndNxtSnowLoadMetric(isAurora);
    }
    return snowLoadMetric;
  } 
  let upperLimit = 60;
  if (biggerRange) upperLimit = 100;
  return makeConfig(upperLimit, 'snowpsf', isAurora);
};

export const snowLoadMetric =  makeConfig(undefined, 'snowkpa', undefined);

const config = getSnowLoadField();

export const SmAndNxtSnowLoadConfig = (isAurora: boolean = false) => { return getSnowLoadField(false, InputUnit.CUSTOMARY, isAurora) };

export const SmAndNxtSnowLoadMetric = (isAurora: boolean=false)=>{return makeConfig(100, 'snowkpa', isAurora)};


export default config;
