export const getRoofApproximateCenter = () => {
  google.maps.Polygon.prototype.getBoundingBox = function boundingBox() {
    const bounds = new google.maps.LatLngBounds();

    this.getPath().forEach((element) => {
      bounds.extend(element);
    });


    return (bounds);
  };

  google.maps.Polygon.prototype.getBounds = function getBounds() {
    const boundingBox = this.getBoundingBox();

    return [
      new google.maps.LatLng(boundingBox.getNorthEast().lat(), boundingBox.getNorthEast().lng()),
      new google.maps.LatLng(boundingBox.getSouthWest().lat(), boundingBox.getSouthWest().lng()),
    ];
  };

  google.maps.Polygon.prototype.getRoofApproximateCenter = function approxmiteCenter() {
    const polygonBounds = this.getBoundingBox();
    const centerPoint = polygonBounds.getCenter();
    // we want the roof area marker be the center of the polygon bounds,
    // so that the FogOfWar hole poly correctly aligns
    // with the roof area edges drawn using PIXI on the panels editor
    return centerPoint;
  
  };

  google.maps.Polygon.prototype.getMarkerPointInsideRoof = function MarkerPointInsideRoof(){

    const polygonBounds = this.getBoundingBox();
    const centerPoint = polygonBounds.getCenter();

    let boundsHeight = 0,
      boundsWidth = 0,
      heightIncr = 0,
      maxSearchLoops,
      maxSearchSteps = 10,
      n = 1,
      northWest,
      pointInsidePolygon,
      widthIncr = 0;
  if (google.maps.geometry.poly.containsLocation(centerPoint, this)) {
      // Nothing to do Centroid is in polygon use it as it is
      return centerPoint;
  } else {
      maxSearchLoops = maxSearchSteps / 2;
      // Calculate NorthWest point so we can work out height of polygon NW->SE
      northWest = new google.maps.LatLng(
        polygonBounds.getNorthEast().lat(), 
        polygonBounds.getSouthWest().lng()
      );
      // Work out how tall and wide the bounds are and what our search
      // increment will be
      boundsHeight = google.maps.geometry.spherical.computeDistanceBetween(
        northWest, 
        polygonBounds.getSouthWest()
      );
      heightIncr = boundsHeight / maxSearchSteps;
      boundsWidth = google.maps.geometry.spherical.computeDistanceBetween(
         northWest, polygonBounds.getNorthEast()
      );
      widthIncr = boundsWidth / maxSearchSteps;
      // Expand out from Centroid and find a point within polygon at 
      // 0, 90, 180, 270 degrees
      for (; n <= maxSearchSteps; n++) {
          // Test point North of Centroid
          pointInsidePolygon = google.maps.geometry.spherical.computeOffset(
            centerPoint, 
            (heightIncr * n), 
            0
          );
          
          if (google.maps.geometry.poly.containsLocation(pointInsidePolygon, this)) {
              break;
          }
          // Test point East of Centroid
          pointInsidePolygon = google.maps.geometry.spherical.computeOffset(
            centerPoint, 
            (widthIncr * n), 
            90
          );
          if (google.maps.geometry.poly.containsLocation(pointInsidePolygon, this)) {
              break;
          }
          // Test point South of Centroid
          pointInsidePolygon = google.maps.geometry.spherical.computeOffset(
            centerPoint, 
            (heightIncr * n), 
            180
          );
          if (google.maps.geometry.poly.containsLocation(pointInsidePolygon, this)) {
              break;
          }
          // Test point West of Centroid
          pointInsidePolygon = google.maps.geometry.spherical.computeOffset(
            centerPoint, 
            (widthIncr * n), 
            270
          );
          if (google.maps.geometry.poly.containsLocation(pointInsidePolygon, this)) {
              break;
          }
      }
      return pointInsidePolygon;
    };
  }

};
