import { state, dispatch } from '__common/store';
import { getPanelsContainer } from './panelsContainer';
import panelModel, { bayModel } from '__editor/panelsEditor/models/panel';
import { getPanelStickPoints, PANEL_EW_POSITION, PANEL_NS_POSITION } from '../../cursor/utils/snapToGridStickyPoints';
import { _drawStickyPoints, _drawRestrictedArea, getStoredPanelBounds, changePanelRoofZone, isPanelInsideOfRoofEdgesMemoized as isPanelInsideOfRoofEdges, setPanelRestrictedArea, setPanelsToBeSelectedForMovement, getPanelCollisionBoundsGf5,  } from '../panels';
import { insertKdTreePoints } from '../../cursor/utils/kdTreeStore';
import { LOAD_PANELS, BACKGROUND_SET_ROTATION, CLEAR_HISTORY, REQUEST_ADD_PANELS, BLOCK_CREATING_PANEL, REMOVE_PANELS, RESET_PANELS_STATE, SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH, TOGGLE_PANEL_ATTACHED } from 'actions';
import { rotateBgToLeadEdge } from '../../leadEdge/leadEdge';
import { isRMDT, isGroundProduct, isRMIFIProduct, isGFT, isULA, isAscender, isRM10orRM10Evo, isEcoFoot2Plus, isRMGridFlex, isRM5, isRM10Evolution, isRmGridflex10 } from '__common/constants/products';
import { isBlankMap } from '__common/constants/map';
import { groupBy, reduce, Dictionary } from 'lodash';
import { removePanelFromRtree, resetRTree, checkCollision, insertPanelIntoRtree, clearRTree } from '../panelsCollisions';
import { snap } from '../../cursor/snapToGrid';
import { mousePosition } from '../../cursor/cursor';
import { panelsInteractionBlock } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { isShiftPressed } from '__editor/googleMapsRoofsSelector/components/advanceRoofSelecting/advanceRoofSelecting';
import { startRubberBand } from '../../rubberBand/rubberBand';
import { setSnappedGroup } from './panelsSnappingGroup';
import { isASCE716or722Selected } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import { checkPanelCollisionWithRestrictedZones } from '../../roofZones/utils/restrictedZoneCollisionsDetections';
import { checkBlankMapPanelsInsideSetBackDistance, checkPanelCollisionWithSetBackDistance, getMaxSetBack, getPanelSetBackDistance } from '../../roofZones/utils/setBackDistanceCollisionDetections';
import { isPanelCollideWithObstacles } from '../../obstructions/obstructionsCollisions';
import { insertPanelCenterPoints } from './panelsCenterStore';
import { degreesToRadians } from '__common/calculations/degreesToRadians';
import { PanelsDisplayObject } from '__editor/panelsEditor/models/panelsContainer';
import nextUid from '__common/calculations/nextUid';
import { _isAscenderFamily, _isGFT, _isULA } from '../../cursor/utils/snapToGridHelper';
import { shouldUpdateRoofEdgesOnPanelChange, shouldUseDesiredBuildingLengthWidth, shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { getPanelsRange } from './calculatePanelsRange';
import { metersToFeets } from '__common/calculations/metersToFeets';
import { ADD_PANEL_BAY_MAPPING, RESET_PANELS_ARRAY_FOR_MOVEMENT, TOGGLE_BAY_ATTACHED, UPDATE_PANELS} from '../panelsActions';
import { getPanelBounds, getRoofBoundsMemoized as getRoofBounds, panelAreaPolygonBound } from '../../roofEdges/roofEdgesCollisions';
import { is2PStructureType } from 'projectDesign/components/projectConfiguration/fields/types/structureType';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { getProjectRoofs } from 'projectDesign/components/asce716switch/asce716switch';
import { getEditorCenter } from '../../background/background';
import { showErrorAlert } from '__common/modules/alerts';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { getUpdatedPanelList } from '../panelsEpics';
import { greaterThanEqualToProjectVersion } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
export const metadataMap = new Map();

export const createPanel = (x: number, y: number, groupId: number, siblingId?: number, siblingSide?: number, panelEWPosition?: number, panelNSPosition?: number, tableHeight?: number, attached: boolean = false, pairId?: number, panelConfig?: number, exposed?: boolean, manualAttachments:(0|1)[]=[0,0,0,0], panelText?:string): panelInState => {
  const { settings: { panelWidth, panelHeight, canvasCenter } } = state();
  return {
    x: x - canvasCenter.x,
    y: y - canvasCenter.y,
    width: panelWidth,
    height: panelHeight,
    id: nextUid(),
    groupId,
    siblingId: siblingId || -1,
    siblingSide,
    panelEWPosition,
    nearObstruction: false,
    landscape: panelWidth > panelHeight,
    panelNSPosition,
    tableHeight,
    attached,
    pairId,
    panelConfig,
    exposed,
    manualAttachments: manualAttachments
  };
};

export const getPixiPanel = (id: number): any => {
  const panelsContainer = getPanelsContainer();

  if (panelsContainer) {
    const panel = panelsContainer.children.filter((panel: PanelsDisplayObject) => panel.id === id)[0];

    if (panel) {
      return panel;
    }
  }
};

export const removeEntirePanelTablesContainingRemovablePanels = (panels: panelInState[], filteredPanels: panelInState[]): panelInState[] => {
  const filterPanelsIds = filteredPanels.map(panel => panel.id);
  const groupIdsToRemove = panels.reduce<Set<number>>(
    (acc, panel) => {
      if (!filterPanelsIds.includes(panel.id)) {
        return acc.add(panel.groupId);
        // here I will use sibling id in place of groupid for ascender
      }
      return acc;
    },
    new Set(),
  );
  return panels.filter(panel => {
    if (groupIdsToRemove.has(panel.groupId)) {
      if (panel.rTreeBounds) {
        removePanelFromRtree(panel.rTreeBounds);
      }
      return false;
    }
    return true;
  });
};
export const removePanelsBasedOnTableWidth = (panels: panelInState[], filteredPanels: panelInState[]): panelInState[] => {
  const filterPanelsIds = filteredPanels.map(panel => panel.id);
  const pairIdsToRemove = panels.reduce<Set<number>>(
    (acc, panel) => {
      if (!filterPanelsIds.includes(panel.id)) {
        return acc.add(panel.pairId);
      }
      return acc;
    },
    new Set(),
  );
  return panels.filter(panel => {
    if (pairIdsToRemove.has(panel.pairId)) {
      if (panel.rTreeBounds) {
        removePanelFromRtree(panel.rTreeBounds);
      }
      return false;
    }
    return true;
  });
};
export const removePanelsBasedOnOffsets = (filteredPanels: panelInState[]): panelInState[] => {
  const {  background: { metersPerPixel}, settings: {rowSpacing, columnSpacing}, panels: {panels : existingPanels} } = state();
  const columnsSpacingInPixels = columnSpacing / metersPerPixel;
  const rowSpacingInPixels = rowSpacing / metersPerPixel;

  clearRTree();
  metadataMap.clear();

  const totalPanels = [...existingPanels,...filteredPanels]
  const updatedPanelsWithGroupIds = getUpdatedPanelList(totalPanels, rowSpacingInPixels, columnsSpacingInPixels)
  const existingPanelsIds = existingPanels.map(panel => panel.id);
  const filterPanelsIds = filteredPanels.map(panel => panel.id);

  updatedPanelsWithGroupIds.forEach(p=>{
    if(existingPanelsIds.includes(p.id)){
    // p.rTreeBounds.groupId = p.groupId
    insertPanelIntoRtree(p.rTreeBounds);
    metadataMap.set(p.rTreeBounds, p.groupId);
    }
  })

  const filtredUpdatedPanels = updatedPanelsWithGroupIds.filter(panel => {
    if (filterPanelsIds.includes(panel.id)) {
        const nearCollisionPanelsVertical = getPanelCollisionBoundsGf5(panel, existingPanels, rowSpacingInPixels, columnsSpacingInPixels, metersPerPixel);
        if (!nearCollisionPanelsVertical.length) {
            panel.rTreeBounds.groupId = panel.groupId
            insertPanelIntoRtree(panel.rTreeBounds);
            metadataMap.set(panel.rTreeBounds, panel.groupId);
            return true; 
        }
        return false;
    }
    return false; 
});
    return filtredUpdatedPanels;
};

export const removePanelsFromState = (panels: panelInState[], ids: number[]) => {
  const { projectConfiguration: { productId } } = state();
  const filteredPanels = panels.filter(
    panel => !ids.includes(panel.id),
  );

  if (isGroundProduct(productId)) {
    return removeEntirePanelTablesContainingRemovablePanels(panels, filteredPanels);
  }
  if (isAscender(productId)) {
    return removePanelsBasedOnTableWidth(panels, filteredPanels);
  }
  if (isRMDT(productId)) {
    return removePanelsWithNoSiblings(filteredPanels);
  }
  return filteredPanels;
};

export const filterPanels = (panels: panelInState[]): panelInState[] => {

  const {
    background: {
      roofEdges,
      cords,
      zoom,
      rotationDegrees,
      bgXY,
      roofEdgesPixiCords
    },
    projectConfiguration: {
      productId,
      projectVersion,
      projectEnvConfig: {tilt,}
    },
    settings: {
      panelWidth,
      panelHeight,
    },
    tiltedRoof: {
      roofPitch,
    },
    roofsSelector: {
      mapType,
    }
  } = state();

  let completelyInsideRoof = false;
  if(panels?.length > 0) {
    const areaPoly = panelAreaPolygonBound(panels[0], panels[panels.length-1], true,  bgXY.x, 
      bgXY.y, panelWidth, panelHeight);
    const roofEdgesPolygon = getRoofBounds({roofEdges, roofCenter: cords, roofEdgesPixiCords, bgOffSet: {x: bgXY.x, y: bgXY.y}, zoom, rotationRadians: degreesToRadians(rotationDegrees), roofPitch, tilt, productId, mapType});
    completelyInsideRoof  = roofEdgesPolygon.contains(areaPoly) || shouldUpdateRoofEdgesOnPanelChange(mapType, productId, projectVersion) || (isBlankMap(mapType) && !shouldUseSetBackDistance(mapType, productId, projectVersion)) ;
  }

  const filteredPanels: panelInState[] = panels.filter(panel => panelsReducerFilter(panel, completelyInsideRoof));
  if (isGroundProduct(productId)) {
    return removeEntirePanelTablesContainingRemovablePanels(panels, filteredPanels);
  }
  if (isAscender(productId)) {
    return removePanelsBasedOnTableWidth(panels, filteredPanels);
  }
  if (isRMGridFlex(productId) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['gf5_south_row_panel_editor_updates'])){
    return removePanelsBasedOnOffsets(filteredPanels);
  }
  return filteredPanels;
};

export const drawPanel = (panel: panelInState) => {
  let pixiPanel = panelModel(panel);
  pixiPanel = setPanelRemovable(pixiPanel);
  const { x, y, width, height } = pixiPanel.getBounds();
  pixiPanel.stickPoints = getPanelStickPoints(x, y, width, height, panel.siblingSide, panel.panelEWPosition, panel.panelNSPosition, panel.tableHeight);
  pixiPanel.centerPoint = { x: panel.x, y: panel.y, id: panel.id };
  // For debug snap to grid
  // For debug restricted area
  _drawStickyPoints(pixiPanel.stickPoints);
  _drawRestrictedArea(panel.rTreeBounds, pixiPanel);
  insertKdTreePoints(pixiPanel.stickPoints, pixiPanel.groupId, pixiPanel.id); // snapping to grid
  insertPanelCenterPoints(pixiPanel.centerPoint);
  return pixiPanel;
};
export const drawBay = (panel: panelInState, bay: {id:number, p:number, attached: 0 | 1}, rightEdgePanels, leftEdgePanels, northEdgePanels, northRightEdgePanels, northMostRow) => {
  let pixiPanel = bayModel(panel, bay, rightEdgePanels, leftEdgePanels, northEdgePanels, northRightEdgePanels, northMostRow);
  pixiPanel = toggleManualAttachment(pixiPanel, panel, bay);
  pixiPanel.centerPoint = { x: panel.x, y: panel.y, id: panel.id };
  // For debug snap to grid
  // For debug restricted area
  return pixiPanel;
};

export const loadPanels = () => {
  const {
    background: { selectedRoofId, metersPerPixel, blank_map_building_length, blank_map_building_width },
    drawingManager: { roofs },
    leadEdgeRoofSelector: { leadEdges },
    roofsSelector: { mapType },
    settings: { panelWidth: pWidth, panelHeight: pHeight, rowSpacing, columnSpacing },
    editorCursor: { landscape },
    projectConfiguration: { productId, projectVersion, projectEnvConfig, inputUnit },
  } = state();

  let panelWidth = pWidth;
  let panelHeight = pHeight;
  if (roofs && Object.keys(roofs[selectedRoofId]).length) {
    const oldMetersPerPixel = roofs[selectedRoofId].metersPerPixel;
    const panels = roofs[selectedRoofId].panels;
    let newPanels = panels;
    const exposureRecaluclationHash = roofs[selectedRoofId].exposureRecaluclationHash;
    let [coordMultiplier, dimensionMultiplier] = [1, 1];
    // get correct multipliers for panel coord and dimensions transformation, if required.
    // only 1/2, 1 &  2 are valid values for these multipliers, since the background zoom can change only by 1 level.
    if (panels && panels.length && panelWidth && panelHeight) {

      let index = 0;

      const { width: oldWidth, height: oldHeight } = panels[index];
      if (panels[index].landscape !== landscape) {
        [panelWidth, panelHeight] = [panelHeight, panelWidth];
      }

      if (panelWidth !== oldWidth || panelHeight !== oldHeight) {
        dimensionMultiplier = panelHeight / oldHeight;
        dimensionMultiplier = parseFloat(dimensionMultiplier.toFixed(1));
      }

      if (oldMetersPerPixel && metersPerPixel) {
        coordMultiplier = oldMetersPerPixel / metersPerPixel;
        coordMultiplier = parseFloat(coordMultiplier.toFixed(1));
      }
    }

    // sanitize multipliers in case the corrupted panels data is not restorable
    const validMultiplierVals = [0.5, 1, 2];
    if (!validMultiplierVals.includes(coordMultiplier)) coordMultiplier = 1;
    if (!validMultiplierVals.includes(dimensionMultiplier)) dimensionMultiplier = 1;

    if (dimensionMultiplier !== 1 || coordMultiplier !== 1) {
      newPanels = panels.map(panel => ({
        ...panel,
        x: panel.x * coordMultiplier,
        y: panel.y * coordMultiplier,
        width: panel.width * dimensionMultiplier,
        height: panel.height * dimensionMultiplier,
      }));
    }

    if (shouldUseDesiredBuildingLengthWidth(mapType, productId, projectVersion) && (!blank_map_building_length || !blank_map_building_width) && newPanels && newPanels.length) {
      const offset = getMaxSetBack(projectEnvConfig, inputUnit, productId, projectVersion) / metersPerPixel;
      const { minX, minY, maxX, maxY } = getPanelsRange(<panelInState[]>newPanels);

      let newWidth = Math.max(Math.abs(minX), Math.abs(maxX));
      let newLength = Math.max(Math.abs(minY), Math.abs(maxY));

      newWidth += offset;
      newLength += offset;

      let widthFeets = Math.ceil(metersToFeets((2 * newWidth * metersPerPixel)));
      let lengthFeets = Math.ceil(metersToFeets((2 * newLength * metersPerPixel)));

      const minSizeforMin1Panel = metersToFeets((offset * 2 + Math.max(panelWidth, panelHeight)) * metersPerPixel);
      const minSize = Math.ceil(minSizeforMin1Panel);

      if (widthFeets < minSize) widthFeets = minSize;
      if (lengthFeets < minSize) lengthFeets = minSize;

      dispatch(SAVE_BLANK_MAP_ROOF_LENGTH_WIDTH(lengthFeets, widthFeets));
    }

    if (leadEdges[selectedRoofId] > -1) {
      rotateBgToLeadEdge();
    } else {
      dispatch(BACKGROUND_SET_ROTATION(roofs[selectedRoofId].bgRotation || 0));
    }
    dispatch(LOAD_PANELS(newPanels || [], isBlankMap(mapType), rowSpacing, columnSpacing, metersPerPixel, exposureRecaluclationHash));
    // dispatch(LOAD_PANELS(newPanels || [], false, rowSpacing, columnSpacing, metersPerPixel, exposureRecaluclationHash));
    dispatch(ADD_PANEL_BAY_MAPPING(roofs[selectedRoofId].panelBayMapping?? new Map()))
    dispatch(CLEAR_HISTORY());
  }
};

export function removePanelsWithNoSiblings(panels: panelInState[]): panelInState[] {
  const { projectConfiguration: { productId } } = state();

  if (isRMDT(productId)) {
    const groupedPanels = groupBy(panels, 'siblingId');
    const panelsOnlyWithSiblings = reduce(groupedPanels, (acc, value) => {
      if (value.length > 1) {
        return acc.concat(value);
      }

      if (value.length === 1) {
        removePanelFromRtree(value[0].rTreeBounds);
      }

      return acc;
    }, []);
    return panelsOnlyWithSiblings;
  }

  return panels;
}

export function setExposureForSibligns(panels: panelInState[], productId: number) {
  if (!isRMDT(productId)) {
    return panels;
  }

  const groupedPanels = groupBy(panels, 'siblingId');
  const panelsOnlyWithSiblings = reduce<Dictionary<panelInState[]>, panelInState[]>(
    groupedPanels,
    (acc, value) => {
      const firstPanel = value[0];
      const secondPanel = value[1];

      const exposure = firstPanel.exposed || secondPanel.exposed;
      const superExposed = firstPanel.exposedNeighbour || secondPanel.exposedNeighbour;
      value[0].exposed = exposure;
      value[0].exposedNeighbour = superExposed;

      value[1].exposed = exposure;
      value[1].exposedNeighbour = superExposed;

      return acc.concat(value);
    },
    [],
  );

  return panelsOnlyWithSiblings;
}

export function drawDoublePanels(group: number) {
  const { settings: { panelWidth } } = state();

  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  const siblingId = nextUid();

  dispatch(REQUEST_ADD_PANELS([
    createPanel(x - (panelWidth / 2), y, group, siblingId, 0),
    createPanel(x + (panelWidth / 2), y, group, siblingId, 1),
  ]));
}
// I will be using this for 2p case in ascender
export function drawDoublePanelsPortrait(group: number) {
  const { settings: { panelHeight } } = state();

  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  const siblingId = nextUid();

  dispatch(REQUEST_ADD_PANELS([
    createPanel(x, y - (panelHeight / 2), group, siblingId, 0),
    createPanel(x, y + (panelHeight / 2), group, siblingId, 1),
  ]));
}

export function drawDesiredTableLengthPanelsPortrait(group: number): void {
  const {
    settings: {
      panelWidth, panelHeight, columnSpacing,
    },
    background: { metersPerPixel },
  } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const {
    panels: {
      desiredTableLength: desiredLength,
    },
  } = state();
  let tableLength = desiredLength

  const panelsArray: panelInState[] = [];
  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  for (let i = 0; i < tableLength; i++) {
    const siblingId = nextUid();
    let panelEWPos = PANEL_EW_POSITION.MIDDLE;
    if (i === 0) {
      panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
    } else if (i === tableLength - 1) {
      panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
    }
    panelsArray.push(createPanel(x + (panelWidth + columnSpacingPx) * i, y - (panelHeight / 2), group, siblingId, 0, panelEWPos));
    panelsArray.push(createPanel(x + (panelWidth + columnSpacingPx) * i, y + (panelHeight / 2), group, siblingId, 1, panelEWPos));
  }

  dispatch(REQUEST_ADD_PANELS(panelsArray));
}

export function drawAscenderPanel(group: number): void {
  const {
    background: {
      metersPerPixel
    },
    settings: {
      panelHeight,
      rowSpacing
    },
    projectConfiguration: {
      projectEnvConfig: { structure_type }
    }
  } = state();
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const panelsArray: panelInState[] = [];
  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  const pairId = nextUid();
  const siblingId = nextUid();
  let panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
  panelsArray.push(createPanel(x , y, group, siblingId, 1, panelEWPos, 0, 0, false, pairId, structure_type, true));
  if(is2PStructureType(structure_type)) {
    panelsArray.push(createPanel(x , y  + panelHeight + rowSpacingPx, group, siblingId, 1, panelEWPos, 0, 0, false, pairId, structure_type, true)); 
  }
  dispatch(REQUEST_ADD_PANELS(panelsArray));
}



export function drawDesiredTableLengthAndWidthPanels(group: number): void {
  const { settings: { panelWidth, panelHeight, columnSpacing, rowSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const {
    panels: {
      desiredTableLength: desiredLength,
      desiredTableWidth: desiredWidth,
    },
  } = state();

  const panelsArray: panelInState[] = [];
  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  for (let i = 0; i < desiredLength; i++) {
    const siblingId = nextUid();
    let panelEWPos = PANEL_EW_POSITION.MIDDLE;
    if (i === 0) {
      panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
    } else if (i === desiredLength - 1) {
      panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
    }

    const initialY = y - panelHeight / 2;
    for (let j = 0; j < desiredWidth; j++) {
      let panelNSPos = PANEL_NS_POSITION.MIDDLE;
      if (j === 0) {
        panelNSPos = PANEL_NS_POSITION.TOP_EDGE;
      } else if (j === desiredWidth - 1) {
        panelNSPos = PANEL_NS_POSITION.BOTTOM_EDGE;
      }
      panelsArray.push(createPanel(x + (panelWidth + columnSpacingPx) * i, initialY + (j * (panelHeight + rowSpacingPx)), group, siblingId, j, panelEWPos, panelNSPos, desiredWidth));
    }
  }

  dispatch(REQUEST_ADD_PANELS(panelsArray));
}


export function drawPanelsArrayForMovement(group: number): void {
  const { projectConfiguration: { productId }, settings: { panelWidth, panelHeight, columnSpacing, rowSpacing }, background: { metersPerPixel } } = state();
  const columnSpacingPx = columnSpacing / metersPerPixel;
  const rowSpacingPx = rowSpacing / metersPerPixel;
  const {
    panels: {
      panelsArrangementToBeMoved
    },
  } = state();

  const panelsArray: panelInState[] = [];
  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;
  const gridCols = panelsArrangementToBeMoved.panelsToBeMovedGrid[0].length;
  const gridRows = panelsArrangementToBeMoved.panelsToBeMovedGrid.length;

  const initialY = y - panelHeight / 2;
  let firstModuleIndexOnFirstRow = 0;
  for (let j = 0; j < gridCols; j++) {
    if (panelsArrangementToBeMoved.panelsToBeMovedGrid[0][j] == 1) {
      firstModuleIndexOnFirstRow = j;
      break;
    }
  }

  let initialX = x - (panelWidth + columnSpacingPx) * firstModuleIndexOnFirstRow;
  if (isRMDT(productId)) {
    initialX = x - (panelWidth * 2 + columnSpacingPx) * firstModuleIndexOnFirstRow;
  }

  for (let j = 0; j < gridCols; j++) {
    let siblingId = nextUid();
    let panelEWPos = PANEL_EW_POSITION.MIDDLE;
    if (j === 0) {
      panelEWPos = PANEL_EW_POSITION.LEFT_EDGE;
    } else if (j === gridCols - 1) {
      panelEWPos = PANEL_EW_POSITION.RIGHT_EDGE;
    }

    for (let i = 0; i < gridRows; i++) {
      let panelNSPos = PANEL_NS_POSITION.MIDDLE;
      if (i === 0) {
        panelNSPos = PANEL_NS_POSITION.TOP_EDGE;
      } else if (i === gridRows - 1) {
        panelNSPos = PANEL_NS_POSITION.BOTTOM_EDGE;
      }
      if (panelsArrangementToBeMoved.panelsToBeMovedGrid[i][j] == 1) {
        if (isRMDT(productId)) {
          siblingId = nextUid();
          panelsArray.push(createPanel(initialX + (panelWidth * 2 + columnSpacingPx) * j, initialY + (i * (panelHeight + rowSpacingPx)), group, siblingId, 0));
          panelsArray.push(createPanel(initialX + panelWidth + (panelWidth * 2 + columnSpacingPx) * j, initialY + (i * (panelHeight + rowSpacingPx)), group, siblingId, 1));
        }
        else if (isGFT(productId)) {
          panelsArray.push(createPanel(initialX + (panelWidth + columnSpacingPx) * j, initialY + (i * panelHeight), group, siblingId, i, panelEWPos));
        }
        else if (isAscender(productId)) {
          panelsArray.push(createPanel(initialX + (panelWidth + columnSpacingPx) * j, initialY + (i * panelHeight + rowSpacingPx), group, siblingId, i, panelEWPos, undefined, undefined, undefined, siblingId, panelsArrangementToBeMoved.panelConfig));
        }
        else if(isULA(productId)) {
          panelsArray.push(createPanel(initialX + (panelWidth + columnSpacingPx) * j, initialY+(i*(panelHeight+rowSpacingPx)), group, siblingId, i, panelEWPos, panelNSPos, gridRows));
        } else if (isRM10Evolution(productId) || isRM5(productId)){
          panelsArray.push(createPanel(initialX + (panelWidth + columnSpacingPx) * j, initialY + (i * (panelHeight + rowSpacingPx)), group, undefined, undefined, undefined, undefined, undefined, false, undefined, undefined, undefined, panelsArrangementToBeMoved.panelsToggleAttachmentGrid[i][j]));
        }
        else {
          panelsArray.push(createPanel(initialX + (panelWidth + columnSpacingPx) * j, initialY + (i * (panelHeight + rowSpacingPx)), group, undefined, undefined, undefined, undefined, undefined, isRMIFIProduct(productId) ? panelsArrangementToBeMoved.panelsToggleAttachmentGrid[i][j] : false));
        }
      }
    }
  }


  if (panelsArray.every(panelsReducerFilterForMoveArray)) {
    dispatch(REQUEST_ADD_PANELS(panelsArray));
    dispatch(RESET_PANELS_ARRAY_FOR_MOVEMENT());
  }
}

export function drawSinglePanel(group: number) {
  const x = snap(mousePosition()).x;
  const y = snap(mousePosition()).y;

  dispatch(REQUEST_ADD_PANELS([
    createPanel(x, y, group),
  ]));
}

const setPanelRemovable = (panel) => {

  panel.on('keyup', (e) => {
    const { background: { moveArrayMode }, } = state();

    // keycode 37, 38, 39, 40 -  denotes for arrow buttons on keyboard for four directions
    let code = e.keyCode - 37;

    const dirs = [0, 1, 2, 3]

    if (dirs.includes(code)) {
      if (moveArrayMode) {
        displacePanels(panel.groupId, code);
      }
    }
  })


  panel.on('mouseup', () => {
    const { background: { moveArrayMode }, panels: { panelsToBeMoved } } = state();
    if (moveArrayMode && panelsToBeMoved.length == 0) {
      setPanelsToBeSelectedForMovement(panel.groupId);
    }
  })

  panel.on('pointerup', () => {
    const { rubberBand, background: { moveMapMode, toggleAttachments, moveArrayMode }, editorCursor: { visible }, advanceRoofSelecting: { enabled: advanceRoofSelectingEnabled }, measurementTool: { enabled: measurementToolEnabled }, projectConfiguration: { productId, projectEnvConfig }, user: { isStaff } } = state();

    if (moveArrayMode) {
      return;
    }

    if (panelsInteractionBlock || advanceRoofSelectingEnabled || measurementToolEnabled) {
      return;
    }

    if (isShiftPressed()) {
      return changePanelRoofZone(panel.id);
    }

    if (rubberBand.mode === 'remove') {
      dispatch(BLOCK_CREATING_PANEL());
      startRubberBand(mousePosition().x, mousePosition().y);
      dispatch(REMOVE_PANELS([panel.id]));
      // dispatch(REMOVE_BAYS([panel.id]))
      setSnappedGroup(null);
      return;
    }

    if ((!projectEnvConfig?.is_aurora_project && !moveMapMode && visible && !toggleAttachments)) {
      dispatch(REMOVE_PANELS([panel.id]));
    }

    if (rubberBand.mode === 'disabled' && toggleAttachments && !moveMapMode && (isRMGridFlex(productId) || isRmGridflex10(productId)) && isStaff) {
      dispatch(TOGGLE_PANEL_ATTACHED(panel.id));
    }
  });
  return panel;
};

const toggleManualAttachment = (pixiPanel, panel, bay)=>{
  pixiPanel.on('pointerdown', () => {
    const { rubberBand, background: { moveMapMode, toggleAttachments }, user: { isStaff } } = state();
    if (rubberBand.mode === 'disabled' && toggleAttachments && !moveMapMode && isStaff) {
      dispatch(TOGGLE_BAY_ATTACHED(panel.id, bay.id));
    }
  });
  return pixiPanel;
}

export const deleteAllPanels = () => {
  dispatch(RESET_PANELS_STATE());
  resetRTree();
};

export function panelsReducerFilter(panel: panelInState, completelyInsideRoof: boolean = false) {
  const {
    background: {
      roofEdges,
      cords,
      zoom,
      metersPerPixel,
      selectedRoofId,
      rotationDegrees,
      bgXY,
      roofEdgesPixiCords,
    },
    projectConfiguration: {
      productId,
      projectEnvConfig: {
        tilt
      },
      projectVersion,
    },
    settings: {
      panelWidth,
      panelHeight,
      rowSpacing,
      columnSpacing,
    },
    roofsSelector: {
      mapType,
    },
    tiltedRoof: {
      roofPitch,
    },
  } = state();

  // panel bounds
  const panelBound = getPanelBounds(panel, true, bgXY.x, bgXY.y, metersPerPixel);
  
  // roof bounds
  const roofBound = getRoofBounds({roofEdges, roofCenter: cords, roofEdgesPixiCords, bgOffSet: {x: bgXY.x, y: bgXY.y}, zoom, rotationRadians:degreesToRadians(rotationDegrees), roofPitch, tilt, productId, mapType});

  // panel inside roof check
  const isPanelInsideRoof = completelyInsideRoof ? true : isPanelInsideOfRoofEdges(
  {panelBound, roofBound, mapType, productId,});
  if(!isPanelInsideRoof) return false;

  // overlap check
  const panelNotOverlapAnotherPanel = !checkCollision(getStoredPanelBounds(panel));
  if(!panelNotOverlapAnotherPanel) return false;

  const insideOfPolygon = true;
  const isInRestrictedZone = !shouldUseSetBackDistance(mapType, productId, projectVersion) && isASCE716or722Selected() && !isBlankMap(mapType) ? checkPanelCollisionWithRestrictedZones(
    panel,
    true,
    roofEdges,
    selectedRoofId,
    cords,
    zoom,
    metersPerPixel,
    rotationDegrees,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    productId,
    insideOfPolygon,
    roofPitch,
    roofEdgesPixiCords,
    mapType,
    tilt,
  ) : false;
  if(isInRestrictedZone) return false;

  const isInSetBackDistance = shouldUseSetBackDistance(mapType,productId, projectVersion) ? checkPanelCollisionWithSetBackDistance(
    panel,
    true,
    roofEdges,
    selectedRoofId,
    cords,
    zoom,
    metersPerPixel,
    rotationDegrees,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    productId,
    insideOfPolygon,
    roofPitch,
    roofEdgesPixiCords,
    mapType,
    tilt,
  ) : false;

  if((isRM10orRM10Evo(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRMGridFlex(productId) || isRmGridflex10(productId)) && !isInSetBackDistance) {
      panel.panelSetback = getPanelSetBackDistance(
        panel,
        true,
        roofEdges,
        cords,
        zoom,
        metersPerPixel,
        rotationDegrees,
        bgXY.x,
        bgXY.y,
        panelWidth,
        panelHeight,
        roofPitch,
      );
  }

  if(isInSetBackDistance) return false;
  
  // collision with obstructions check
  const panelHasCollisionWithObstruction = isPanelCollideWithObstacles(
    panel,
    selectedRoofId,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    metersPerPixel
  );
  if(panelHasCollisionWithObstruction) return false;
  
  // insert into r-tree for collision checks
  setPanelRestrictedArea(
    panel, 
    rowSpacing, 
    columnSpacing, 
    metersPerPixel,
  );


  return true;
}


export function panelsReducerFilterForMoveArray(panel: panelInState) {
  const {
    background: {
      roofEdges,
      cords,
      zoom,
      metersPerPixel,
      selectedRoofId,
      rotationDegrees,
      bgXY,
      roofEdgesPixiCords,
    },
    projectConfiguration: {
      productId,
      projectVersion,
      projectEnvConfig: {tilt},
    },
    settings: {
      panelWidth,
      panelHeight,
    },
    roofsSelector: {
      mapType,
    },
    tiltedRoof: {
      roofPitch,
    },
  } = state();

  const isInRestrictedZone = !shouldUseSetBackDistance(mapType, productId, projectVersion) && isASCE716or722Selected() && !isBlankMap(mapType) ? checkPanelCollisionWithRestrictedZones(
    panel,
    true,
    roofEdges,
    selectedRoofId,
    cords,
    zoom,
    metersPerPixel,
    rotationDegrees,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    productId,
    true,
    roofPitch,
    roofEdgesPixiCords,
    mapType,
    tilt,
  ) : false;

  const isInSetBackDistance = (shouldUseSetBackDistance(mapType, productId, projectVersion) ? checkPanelCollisionWithSetBackDistance(
    panel,
    true,
    roofEdges,
    selectedRoofId,
    cords,
    zoom,
    metersPerPixel,
    rotationDegrees,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    productId,
    true,
    roofPitch,
    roofEdgesPixiCords,
    mapType,
    tilt,
  ) : false );

  const panelHasCollisionWithObstruction = isPanelCollideWithObstacles(
    panel,
    selectedRoofId,
    bgXY.x,
    bgXY.y,
    panelWidth,
    panelHeight,
    metersPerPixel
  );

  return !panelHasCollisionWithObstruction && !isInRestrictedZone && !isInSetBackDistance;

}

export function pairWithSiblings(panelsWithoutSiblings: rbush.BBox[]): number[] {
  const { panels: { panels } } = state();
  if (!panelsWithoutSiblings[0].siblingId || panelsWithoutSiblings[0].siblingId === -1) {
    return panelsWithoutSiblings.map(panel => panel.id);
  }
  const siblingIds = panelsWithoutSiblings.map(panel => panel.siblingId);
  const siblingIdsSet = new Set(siblingIds);
  if (panelsWithoutSiblings.length === 1 && siblingIdsSet.has(-1)) {
    return panelsWithoutSiblings.map(panel => panel.id);
  }
  return panels.filter((panel: panelInState) => {
    return siblingIdsSet.has(panel.siblingId);
  }).map((panel: panelInState) => panel.id);
}


export const displacePanels = (groupId: number, direction: number) => {
  const {
    background: { metersPerPixel, },
    panels: { panels: allPanels },
  } = state();
  let dx, dy;

  /*
  0 : left
  1: up
  2: right
  3: down
  */

  if (direction === 0) {
    [dx, dy] = [-1, 0];
  }
  else if (direction === 1) {
    [dx, dy] = [0, -1];
  }
  else if (direction === 2) {
    [dx, dy] = [1, 0];
  }
  else {
    [dx, dy] = [0, 1]
  }
  let distance = 0.1 / metersPerPixel;
  [dx, dy] = [distance * dx, distance * dy];

  if (allPanels.length > 0) {
    const panels = allPanels.filter(p => p.groupId == groupId);
    const newPanels = panels.map(panel => ({
      ...panel,
      x: panel.x + dx,
      y: panel.y + dy,
    }));
    panels.forEach(p => removePanelFromRtree(p.rTreeBounds));
    if (newPanels.every(panelsReducerFilter)) {
      dispatch(UPDATE_PANELS(newPanels));
    }
    else {
      resetRTree();
      allPanels.forEach(panel => panelsReducerFilter(panel));
      showErrorAlert('Panels colliding obstructions or roof boundaries');
    }

  }
};

export function areAllPanelsInSetbackDistance(setbackDistance: number): boolean {
  const { projectConfiguration: {
    inputUnit,
    productId,
    projectVersion,
    projectEnvConfig: {tilt},
  },
    roofsSelector: { mapType },
    settings: {
      panelWidth,
      panelHeight,
    },
  } = state();

  if (!shouldUseSetBackDistance(mapType, productId, projectVersion)) {
    return false;
  }

  const roofs = getProjectRoofs();

  if (roofs) {
    const editorCenter = getEditorCenter();

    return Object.values(roofs).some(roof => {
      let panels: panelInState[] = roof.panels;

      if (!panels || !Array.isArray(panels) || !panels.length) {
        return false;
      }
      const insideOfPolygon = true;

      const {
        bgRotation,
        roofPitch,
        zoom,
        metersPerPixel,
        marker: roofCenter,
        coords: roofEdges,
        id: roofId,
        bgScale,
        blank_map_building_length,
        blank_map_building_width,
        roofEdgesPixiCords,
      } = roof;

      const setbackInMeters =  isMetricUnit(inputUnit) ? cmsToMeters(setbackDistance) : feetsToMeters(setbackDistance);
      return isBlankMap(mapType) ? checkBlankMapPanelsInsideSetBackDistance(
        blank_map_building_length, 
        blank_map_building_width, 
        setbackInMeters, 
        metersPerPixel, 
        bgScale, 
        panelWidth, 
        panelHeight, 
        panels,
        ) :
        panels.some(panel => {
          return checkPanelCollisionWithSetBackDistance(
            panel,
            true,
            roofEdges,
            roofId,
            roofCenter,
            zoom,
            metersPerPixel,
            bgRotation,
            editorCenter.x,
            editorCenter.y,
            panelWidth,
            panelHeight,
            productId,
            insideOfPolygon,
            roofPitch,
            roofEdgesPixiCords,
            mapType,
            tilt,
            setbackInMeters
          );
        }
      )
    });
  }
}