import React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { DISABLE_RUBBER_BAND, MOVE_ARRAY_MODE_ENABLE, SHOW_CURSOR, TOGGLE_ATTACHMENTS_DISABLE, TURN_OFF_MEASUREMENT_TOOL } from 'actions';
import { TERMINATE_ADVANCE_ROOF_SELECTING } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { DISABLE_BAYS_CONTAINER, MOVE_MAP_MODE_DISABLE } from '../../background/backgroundActions';
import { RESET_PANELS_ARRAY_FOR_MOVEMENT } from '../../panels/panelsActions';
import { AlertInstance, showCustomNotifyAlert } from '__common/modules/alerts';
import { isULA } from '__common/constants/products';

type Props = {
  moveArrayEnabled: boolean,
  length?: number,
  width?: number,
  product?: number
  enableArrayMove: () => void,
  terminateDrawObstructions: () => void,
  disableToggleAttachment: () => void,
  disableMapMove: () => void,
  disableRubberBand:() => void,
  disableMeasurementTool: () => void,
  disableManualAttachments: () => void
};


type State = {
  tooltipOpen: boolean,
  tooltipInstance: AlertInstance | null,
};

class MoveArray extends React.Component<Props, State> {
  
  constructor(props) {
    super(props);
    this.state = {
      tooltipOpen: false,
      tooltipInstance: null,
    };
  }
  
  onMoveArrayHandler = () => {
    this.props.terminateDrawObstructions();
    this.props.disableToggleAttachment();
    this.props.disableMapMove();
    this.props.disableRubberBand();
    this.props.disableMeasurementTool();
    this.props.disableManualAttachments()
    return this.props.enableArrayMove();
  }

  tooltipDiv = () => {
    return `<p><b>Tip&#8594;</b> Move array mode is enabled. Please click somewhere within an array to select the array you would like to displace.</p>
    <p><b>Tip&#8594;</b>Once an array is clicked, one can keep the cursor inside the array and use arrow keys to displace using keyboard. Once cursor is moved outside array, mouse mode gets activated and can be used for larger displacements.</p>
    <p><b>Tip&#8594;</b>If keyboard is used to displace, pressing Enter key would fix the updated position. Clicking in the new position would fix the updated position in case of mouse displacement.</p>
    <p><b>Tip&#8594;</b>Before pressing Enter key or clicking on new position, pressing Escape key would restore the original position for both keyboard and mouse movement.</p>`;
  }
  
  componentDidUpdate(prevProps: Props) {
    const { moveArrayEnabled } = this.props;
    if(moveArrayEnabled && !prevProps.moveArrayEnabled){
      if (this.state.tooltipOpen && this.state.tooltipInstance) {
        this.state.tooltipInstance.dismiss();
      } 
      const alert = showCustomNotifyAlert(this.tooltipDiv(), 15);
      alert.ondismiss = () => this.setState({
        tooltipOpen: false,
        tooltipInstance: null,
      });
      this.setState({
        tooltipOpen: true,
        tooltipInstance: alert,
      });
    }
  }

  render() {
    const { moveArrayEnabled, product, length, width } = this.props;
    return (
      <Button onClick={this.onMoveArrayHandler} className={moveArrayEnabled ? 'roofs-selector-control-panel-active roofs-selector-button' : 'roofs-selector-button'} flat={true} tooltipLabel="Move Array"
      disabled={isULA(product) && (length === null || width === null)}>
          <SVG src={require('assets/media/icons/move_array_icon.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(appState: appState) {
  return {
    moveArrayEnabled: appState.background.moveArrayMode,
    length: appState.panels.desiredTableLength,
    width: appState.panels.desiredTableWidth,
    product: appState.projectConfiguration.productId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    enableArrayMove: () => {
      dispatch(RESET_PANELS_ARRAY_FOR_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_ENABLE());
    },
    terminateDrawObstructions: () => {
      dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    },
    disableToggleAttachment: () => {
      dispatch(TOGGLE_ATTACHMENTS_DISABLE());
    },
    disableMapMove: () => {
      dispatch(MOVE_MAP_MODE_DISABLE());
    },
    disableRubberBand: () => {
      dispatch(SHOW_CURSOR());
      dispatch(DISABLE_RUBBER_BAND());
    },
    disableMeasurementTool: () => dispatch(TURN_OFF_MEASUREMENT_TOOL()),
    disableManualAttachments: () => dispatch(DISABLE_BAYS_CONTAINER()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoveArray);
