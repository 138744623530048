import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import  { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import { tiltForUla } from 'projectDesign/components/projectConfiguration/fields/tilt';
import riskCategory, { riskRMIToIIICategory } from 'projectDesign/components/projectConfiguration/fields/riskCategory';
import seismicS1 from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import windOnIce from 'projectDesign/components/projectConfiguration/fields/windOnIce';
import iceThickness from 'projectDesign/components/projectConfiguration/fields/iceThickness';
import { ulaClampsType } from 'projectDesign/components/projectConfiguration/fields/clampTypeChoice';
import frontEdgeHeight from 'projectDesign/components/projectConfiguration/fields/frontEdgeHeight';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import { asce7107And716WindSpeedForUla } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkAdditionalGFTFactors, checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { railsPerModule } from 'projectDesign/components/projectConfiguration/fields/railsPerModuleChoice';
import foundationDiameter from 'projectDesign/components/projectConfiguration/fields/foundationDiameterUla';
import foundationType, { options as foundationTypeOptions } from 'projectDesign/components/projectConfiguration/fields/foundationTypeUla';
import foundationScrewLength from 'projectDesign/components/projectConfiguration/fields/foundationScrewLength';
import {foundationScrewLengthKrinner} from 'projectDesign/components/projectConfiguration/fields/foundationScrewLength';
import { state } from '__common/store';
import { railType } from '../fields/railType';
import { clampLocation } from '../fields/clampLocations';
import AdditionalLoadCombinationField from '../fields/gftAndUlaAdditionalLoadCombinationFactor';
import { nsDiagnolBrace } from '../fields/nsDiagnolBrace';
import { ULAClampLocationType, ULARailType } from '../utils/constants';
import { railArrangementType } from '../fields/railArrangementType';
import groundScrewManufacturerULA from '../fields/groundScrewManufacturerULA';
import { applyKrinnerGroundScrewULA } from '__common/utils/versionCompare/versionCompare';
import { asce710716722BuildingCode } from '../fields/buildingCode';
import { isASCE722 } from '__common/constants/buildingCodes';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSd1 from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeed from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import longTransitionPeriods from 'projectDesign/components/projectConfiguration/fields/longTransitionPeriods';
import { checkFieldNotEmpty, checkLongTransitionPeriods, checkLongestBuildingLength, checkLongestRowLength, checkSeismicSd1, checkSeismicSds, checkShortestBuildingLength, checkShortestRowLength, checkTornadoSpeed } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import shortestRowLength from '../fields/shortestRowLength';
import longestRowLength from '../fields/longestRowLength';

function getWindSpeedField(buildingCodeValue: number) {
  return asce7107And716WindSpeedForUla;
}

export default (buildingCodeValue: number) => {

  const {
    projectConfiguration: {
      projectEnvConfig: { 
        foundation_type,
        rail_type,
        clamps_location, 
        clamp_type, 
        risk_category, 
        seismic_sds, 
        seismic_sd1, 
        tornado_speed, 
        long_transition_periods_tl, 
        longest_row_length, 
        shortest_row_length,
        elevation: current_elevation,
        wind_speed,
        snow_load,
       },
       projectVersion,
    },
    user: {
      isPrivilegedUser,
      isStaff
    },
  } = state();

  const buildingCode = asce710716722BuildingCode;
  const windSpeed = getWindSpeedField(buildingCodeValue);
  const snowLoad  = getSnowLoadField(true);
  
  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, seismicSs, elevation);
  const ulaRiskCategory = isStaff ? riskCategory : riskRMIToIIICategory;

  const ulaAdditionalLoadCombinationForStaffUser = (isStaff || isPrivilegedUser) ? AdditionalLoadCombinationField : false;
  checkAdditionalGFTFactors(railArrangementType(clamp_type), seismicS1, WindExposure, ulaRiskCategory, windOnIce, iceThickness, frontEdgeHeight);

  const concreteSelected = foundation_type === foundationTypeOptions.concrete.value;
  const groundScrewSelected = foundation_type === foundationTypeOptions.groundScrew.value;
  const tornadoSpeedField = isASCE722(buildingCodeValue) && riskCategoryCheckForTornadoSpeed(risk_category) ? tornadoSpeed : [];
  const seismicSdsField = isASCE722(buildingCodeValue) ? seismicSds : [];
  const seismicSd1Field = isASCE722(buildingCodeValue) ? seismicSd1 : [];
  const longTransitionPeriodsField = isASCE722(buildingCodeValue) ? longTransitionPeriods : [];
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  seismicSd1.missing = !checkSeismicSd1(seismic_sd1);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  longTransitionPeriods.missing = !checkLongTransitionPeriods(long_transition_periods_tl);
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);
  const longestRowLengthField = longestRowLength;
  const shortestRowLengthField = shortestRowLength;

  longestRowLength.missing = !checkLongestRowLength(longest_row_length * 1);
  shortestRowLength.missing = !checkShortestRowLength(shortest_row_length * 1);

  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        ulaRiskCategory,
        seismicSs,
        seismicS1,
        ...(isASCE722(buildingCodeValue) ? [ seismicSdsField, seismicSd1Field, longTransitionPeriodsField, ] : []),
        ulaAdditionalLoadCombinationForStaffUser,
        ...(isASCE722(buildingCodeValue) ? [ tornadoSpeedField, longestRowLengthField, shortestRowLengthField, ] : []),
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
      ],
    },
    Spans: {
      label: '',
      fields: [
        tiltForUla,
        frontEdgeHeight,
        railsPerModule,
        foundationType,
        ...(concreteSelected ? [foundationDiameter] : []),
        ...(groundScrewSelected && applyKrinnerGroundScrewULA(projectVersion)?[groundScrewManufacturerULA, foundationScrewLengthKrinner] : groundScrewSelected ?[foundationScrewLength]: []),
        railType(projectVersion, isPrivilegedUser),
        ...(rail_type === ULARailType.SM_HD ? [clampLocation] : []),
        ...(rail_type === ULARailType.SM_HD && clamps_location === ULAClampLocationType.BOTTOM_CLAMPS ? [] : [ulaClampsType]),
        nsDiagnolBrace
      ],
    },
    railFinish: false,
    railTypes: false,
  };

  return cfg;
};
