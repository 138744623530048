import { uAnchorImprovements } from "__common/utils/versionCompare/versionCompare";

export const apiField = 'choice_of_fastend';

function makeConfig(data) {
  return{
    label: apiField,
    type: 'Select',
    apiField,
    missing: undefined,
    tooltip: undefined,
    data
}};


function choiceOfFastendMap(projectVersion, key) {
  const isFastener = uAnchorImprovements(projectVersion);
  const map = {
      "2000": { value: 20, name: isFastener ? "NON-FASTENER" : 'U-2000'},
      "2400": { value: 24, name: isFastener ? "FASTENER" : 'U-2400' },
      "2600": { value: 26, name: isFastener ? "FASTENER" : 'U-2600' },
      "2800": { value: 28, name: isFastener ? "FASTENER" : 'U-2800'}
  };

  return map[key] || null;
}

export default function choiceOfFastend(projectVersion, keys_array){ 
  const filteredList = keys_array.map(key => choiceOfFastendMap(projectVersion, key));
      return makeConfig(filteredList);
    }