import { ERROR_SEND_REPORT_PROBLEM_FORM, SET_TICKET_ID, SUCCESS_SEND_REPORT_PROBLEM_FORM } from './ReportProblemActions';
import { hitApi, ObservableAjax } from '__common/utils/api';
import { ReportProblemActionTypes } from 'actionsConstants';

export function reportIssue(action$) {
  return action$.ofType(ReportProblemActionTypes.SEND_REPORT_PROBLEM_FORM)
    .switchMap(action => 
      ObservableAjax({
        takeUntil: action$.ofType(ReportProblemActionTypes.SEND_REPORT_PROBLEM_FORM),
        onSuccess: [SET_TICKET_ID, SUCCESS_SEND_REPORT_PROBLEM_FORM],
        onError: ERROR_SEND_REPORT_PROBLEM_FORM,
        link: hitApi('post', `api/v1/feedback/`, action.payload.body),
      }));
}
