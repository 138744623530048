import { isNxtHorizon, isRMGridFlex, isRmGridflex10, isSM2Standard, products } from "__common/constants/products";
import { apiField as clientNameApiField } from "../fields/clientName";
import { apiField as clientAddressApiField } from "../fields/clientAddress";
import { apiField as buildingCodeApiField } from "../fields/buildingCode";
import { apiField as windExposureApiField } from "../fields/types/windExposure";
import { apiField as windSpeedApiField } from "../fields/windSpeed";
import { apiField as windOnIceApiField } from "../fields/windOnIce";
import { apiField as iceThicknessApiField } from "../fields/iceThickness";
import { apiField as elevationApiField } from "../fields/elevation";
import { apiField as meanRecurrenceIntervalApiField } from "../fields/meanRecurrenceInterval";
import { apiField as snowLoadApiField } from "../fields/types/snowLoad";
import { apiField as seismicSsApiField } from "../fields/types/seismicSs";
import { apiField as seismicS1ApiField } from "../fields/seismicS1";
import { apiField as riskCategoryApiField } from "../fields/riskCategory";
import { apiField as deadLoadFactorApiField } from "../fields/types/deadLoadFactor";
import { apiField as buildingHeightApiField } from "../fields/buildingHeight";
import { apiField as roofTypeApiField } from "../fields/roofType";
import { apiField as  manufacturerTypeField } from "../fields/manufacturerField";
import { apiField as  colouTypeField } from "../fields/colourField";
import { apiField as  choiceOfFastendTypeField } from "../fields/choiceOfFastend";
import { apiField as subCategoryField } from "../fields/roofTypeSubCategoryField";
import { apiField as parapetHeightApiField } from "../fields/parapetHeight";
import { apiField as longestBuildingLengthApiField } from "../fields/longestBuildingLength";
import { apiField as shortestBuildingLengthApiField } from "../fields/shortestBuildingLength";
import { apiField as roundingHalfBlockApiField } from "../fields/roundingHalfBlock";
import { apiField as ballastHalfBlockWeightApiField } from "../fields/ballastHalfBlockWeight";
import { apiField as ballastBlockWeightApiField } from "../fields/ballastBlockWeight";
import { apiField as deflectorsApiField } from "../fields/deflectors";
import { apiField as centralSupportApiField } from "../fields/centralSupport";
import { apiField as allowMechanicalAttachmentsApiField } from "../fields/allowMechanicalAttachments";
import { apiField as allowManualAttachmentsApiField } from "../fields/allowManualAttachments";
import { apiField as attachmentOptimizationCriteriaApiField } from "../fields/attachmentOptimizationCriteria";
//import { apiField as attachmentTypeApiField, sfmInfinityAttachmentType } from "../fields/attachmentType";
import { apiField as upliftAllowableApiField } from "../fields/upliftAllowable";
import { apiField as shearAllowableApiField } from "../fields/shearAllowable";
import { apiField as uAnchorTypeApiField } from "../fields/uAnchorType";
import { apiField as roofPsfLimitApiField } from "../fields/roofPsfLimit";
import { apiField as velocityPressureExposureCoefficientKzApiField } from "../fields/velocityPressureExposureCoefficientKz";
import { apiField as groundElevationFactorKeApiField } from "../fields/groundElevationFactorKe";
import { apiField as windDirectionalityFactorKdApiField } from "../fields/windDirectionalityFactorKd";
import { apiField as topographicalFactorKztApiField } from "../fields/topographicalFactorKzt";
import { apiField as designLifeFactorFcApiField } from "../fields/designLifeFactorFc";
import { apiField as numericalCoefficientApiField } from "../fields/numericalCoefficient";
import { apiField as roofExposureApiField } from "../fields/types/roofExposure";
import { apiField as soilClassApiField } from "../fields/soilClass";
import { apiField as endClampsChoiceApiField, endClampsApiField } from "../fields/smClamps";
import { midClampsApiField as midClampsApiFieldChoiceApiField } from "../fields/smClamps";
import { apiField as railFinishApiField } from "../fields/railFinish";
import { apiField as railTypeApiField } from "../fields/railType";
import { apiField as preferredSpanApiField } from "../fields/span";
import { apiField as rafterSpacingApiField } from "../fields/rafterSpacing";
import { apiField as limitDownPointLoadsApiField } from "../fields/limitDownPointLoads";
import { apiField as maxDownPointLoadToBeAllowedApiField } from "../fields/maxDownPointLoadToBeAllowed";
import { apiField as frostDepthGftApiField } from "../fields/frostDepthGft";
import { apiField as additionalLoadCombinationApiField } from "../fields/types/additionalLoadCombination";
import { apiField as adjustableTiltSystemApiField } from "../fields/types/adjustableTiltSystemType";
import { apiField as tiltApiField } from "../fields/tilt";
import { apiField as railArrangementTypeApiField } from "../fields/railArrangementType";
import { apiField as clampTypeChoiceApiField } from "../fields/clampTypeChoice";
import { apiField as foundationTypeApiField } from "../fields/foundationType";
import { apiField as foundationLengthApiField } from "../fields/foundationLength";
import { apiField as foundationDiameterApiField } from "../fields/foundationDiameter";
import { apiField as frontEdgeHeightApiField } from "../fields/frontEdgeHeight";
import { apiField as railsPerModuleChoiceApiField } from "../fields/railsPerModuleChoice";
import { apiField as nsDiagnolBraceApiField } from "../fields/nsDiagnolBrace";
import { apiField as ecoFoot2PlusSurfaceApiField } from "../fields/ecoFoot2PlusSurface";
import { apiField as slipSheetApiField } from "../fields/ecoFoot2PlusSlipSheet";
import { apiField as roofSubstrateApiField } from "../fields/roofSubstrate";
import { apiField as surfaceTypeApiField } from "../fields/surface_type";
import allowThermalGapField from "../fields/allowThermalGap";
import { apiField as railLengthForThermalGapApiField } from '../fields/railLengthForThermalGap';
import { ADDITIONAL_USER_INPUTS_PAGE, LOCATION_AND_LOADS_PAGE, RAIL_SYSTEM_PAGE } from "./projectConfigurationDrawerPages";
import { apiField as foundationScrewLength } from "../fields/foundationScrewLength";
import { apiField as buildingstorey } from "../fields/buildingStorey";
import { apiField as nbccCategoryApiField } from "../fields/nbccCategory"
import { apiField as nbccWindPressureApiField } from "../fields/nbccWindPressure"
import { apiField as nbccRoughnessApiField } from "../fields/nbccRoughness";
import { apiField as rainLoadApiField } from "../fields/rainLoad"
import { apiField as seismicSdsApiField } from "../fields/seismicSds";
import { apiField as seismicSd1ApiField } from "../fields/seismicSd1";
import { apiField as tornadoSpeedField } from "../fields/tornadoSpeed";
import { apiField as longTransitionPeriodsApiField } from "../fields/longTransitionPeriods";
import { apiField as longestRowLengthApiField } from "../fields/longestRowLength";
import { apiField as shortestRowLengthApiField } from "../fields/shortestRowLength";
import { apiField as  moduleTypeApiField } from "../fields/moduleType";
import { apiField as  allowSecondaryBallastTrayApiField } from "../fields/allowSecondaryBallastTray";
import { apiField as  allowDualAttachmentsApiField } from "../fields/allowDualAttachments";
import { apiField as  centralRailsCountApiField } from "../fields/centralRailsCount";
import { apiField as parapetHeightNumericApiField } from '../fields/parapetHeightNumeric';
import { apiField as smTileReplacementsOrSolarhooksApiField } from '../fields/smTileReplacementsOrSolarhooks';
import { apiField as staggerAttachmentsApiField } from '../fields/types/staggerAttachments';
import { apiField as roofMaterialThicknessSolarMountFlushApiField } from '../fields/roofMaterialThicknessSolarMountFlush';
import { apiField as rmIFIParapetHeightApiField } from '../fields/rmIFIParapetHeight';
import { apiField as topChordApiField } from '../fields/topChord';
import { adjustableTiltSystem, applyRemovalOfUpslopeRail } from "__common/utils/versionCompare/versionCompare";
import { apiField as railClampOptionApiField } from "../fields/railClampOption"
import { apiField as addRoofPadsEverywhere } from "../fields/roofPadsEveryWhereField";
import { apiField as useFriction } from "../fields/useFrictionField";
import { AttachmentType } from "../fields/attachmentType";
import { apiField as railDirectionApiField } from "../fields/railDirection";
import { apiField as loadSharingTypeApiField } from "../fields/loadSharingType";
import { apiField as thermalFactorApiField } from "../fields/thermalFactor";
import { apiField as frictionFactorApiField } from "../fields/frictionFactor";
import { apiField as  fullyAdheredApiField } from "../fields/fullyAdhered";
import { apiField as seismicIpApiField } from "../fields/seismicIp";
import { apiField as snowIsApiField } from "../fields/snowIs";
import { apiField as windIwApiField } from "../fields/windIw";
import { apiField as isUL3741RequiredField } from "../fields/ul3741Required";
import { apiField as blocksOrientationApiField } from "../fields/blocksOrientation";
import { apiField as isDomesticMLPERequiredField } from "../fields/isDomesticMLPERequired";


const setBackDistanceApiField = 'setback_distance'
const attachmentTypeApiField = 'attachment_type'
export const uAnchorPartNumberApiField = 'uanchor_part_number';


export const RoofType = Object.freeze({
  SHINGLE: 1,
  STANDING_SEAM:  2,
  R_PANEL: 5,
  TILE : 3,
  OTHERS : 4,
  GABLE: 6,
  CONCRETE: 7,
});

export const RmRoofType = Object.freeze({
  MINERAL_CAP : 5,
  EPDM : 6,
  TPO : 7,
  PVC : 8,
  OTHER : 9,
  FINE_GRAVEL : 13,
  HONED_CONCRETE : 14,
  PAINTED_CONCRETE : 15,
  BROOMED_CONCRETE : 16,
  KEE : 17,
  TPA : 18,
  APP : 19,
  SBS : 20,
  ACRYLIC : 21,
  COATED : 22,
})

export const UanchorSubCategoryType = Object.freeze({
  REGULAR : "07",
  TORCHED : "08",
  SOLVENT_BASED : "10",
  SILICONE_BASED : "11",
})

export const TileReplacementOrSolarhooks = Object.freeze({
  TILE_REPLACEMENT: 1,
  SOLARHOOKS:  2,
});

export const RoofMaterialThickness = Object.freeze({
  GA_22 : 8,
  GA_24 : 9,
  GA_26 : 10,
});

export const RoofSubstrate = Object.freeze({
  OSB : 1,
  PLYWOOD : 2,
  RAFTERS : 3,
});

export const SmFlushRailType = Object.freeze({
  SM:1,
  SM_HD:2,
  SM_LT:5,
  SM_ASCENDER_FLUSH: 10,
});

export const MidEndClampChoice = Object.freeze({
  PRO_CLAMP:1,
  STANDARD_CLAMP:2,
  UNIVERSAL_CLAMP:3,
  NXT_HORIZON_COMBO_CLAMP:4,
  NXT_HORIZON_HIDDEN_CLAMP:5,
});

export const SMMidClampToModuleSpacingMap = Object.freeze({
  [MidEndClampChoice.PRO_CLAMP]: 1,
  [MidEndClampChoice.UNIVERSAL_CLAMP]: 0.5,
  [MidEndClampChoice.STANDARD_CLAMP]: 0.25,
}); 

export const NxtHorizonRailType = Object.freeze({
  NH:6,
  NH_HD:7,
  NH_LT:8,
});

export const AscenderRailType = Object.freeze({
  ASCENDER: 10,
});

export enum ULARailType{
  SM = 1,
  SM_HD = 2,
  GFT = 9,
};

export enum ULAClampLocationType {
  TOP_CLAMPS = 1,
  BOTTOM_CLAMPS = 2,
  BOTH_TOP_AND_BOTTOM_CLAMPS = 3,
};

export const SMTiltPRRailType = Object.freeze({
  SMTPR : 11,
})

export const IS_SMFLUSH = [products.solarmount_2,
                                   products.solarmount_hd,
                                   products.solarmount_lt,
                                  ]


export const IS_GRIDPLEX_RM10_EVO_RM5_RMDT = [products.rm_gridflex,
                                              products.rm_5,
                                              products.rm_dt,
                                              products.rm_10_evolution,
                                              products.rm
                                             ]

export const isMultipleValues = (arr: any[], targetArr: any[]) => {
  // check multiple values in the list
  return targetArr.every(value => {
    return arr.includes(value);
  });
}

export const TILE_FLASHINGS = ["TILE FLASHING FLAT", "TILE FLASHING SPANISH", "TILE FLASHING W"]
export const SUB_FLASHINGS = ["SOLARHOOK FLASHING SPANISH FLCT", "SOLARHOOK FLASHING FLAT"]

export const DEFAULT_VERIFIED_FLYOUTS_DATA={
  'module-selection': {checked: false, error: false},
  'location-and-loads': {checked: false, error: false},
  'rail-system': {checked: false, error: false},
  'additional-user-inputs': {checked: false, error: false},
}

export const DEFAULT_VERIFIED_FLYOUTS_DATA_FOR_AURORA_IMPORTED_PROJECTS={
  'module-selection': {checked: true, error: false},
  'location-and-loads': {checked: false, error: false},
  'rail-system': {checked: false, error: false},
  'additional-user-inputs': {checked: true, error: false},
}

export const locationAndLoadsPageApiFields = [
  clientNameApiField, clientAddressApiField, 'zipcode',
  buildingCodeApiField, windExposureApiField, windSpeedApiField,
  elevationApiField, meanRecurrenceIntervalApiField,
  snowLoadApiField, seismicSsApiField, seismicS1ApiField, riskCategoryApiField,
  deadLoadFactorApiField, windOnIceApiField, iceThicknessApiField, frostDepthGftApiField,
  additionalLoadCombinationApiField, topographicalFactorKztApiField, seismicSdsApiField, 
  seismicSd1ApiField, tornadoSpeedField, longTransitionPeriodsApiField, 
  longestRowLengthApiField, shortestRowLengthApiField, moduleTypeApiField, soilClassApiField,
]

export const railSystemPageApiFields = [
  buildingHeightApiField, roofTypeApiField, parapetHeightApiField,
  longestBuildingLengthApiField, shortestBuildingLengthApiField,
  roundingHalfBlockApiField, ballastHalfBlockWeightApiField,
  ballastBlockWeightApiField, deflectorsApiField, centralSupportApiField,
  limitDownPointLoadsApiField, allowMechanicalAttachmentsApiField,
  attachmentOptimizationCriteriaApiField, attachmentTypeApiField,
  upliftAllowableApiField, shearAllowableApiField, uAnchorTypeApiField,
  roofPsfLimitApiField, midClampsApiFieldChoiceApiField, endClampsChoiceApiField, 
  railFinishApiField, railTypeApiField, railDirectionApiField, adjustableTiltSystemApiField, 
  preferredSpanApiField, rafterSpacingApiField, maxDownPointLoadToBeAllowedApiField,
  tiltApiField, railArrangementTypeApiField, clampTypeChoiceApiField,
  foundationTypeApiField, foundationLengthApiField,
  foundationDiameterApiField, frontEdgeHeightApiField, 
  railsPerModuleChoiceApiField, nsDiagnolBraceApiField,
  slipSheetApiField, ecoFoot2PlusSurfaceApiField, 'mandatory_mid_support', roofSubstrateApiField,
  slipSheetApiField, surfaceTypeApiField, setBackDistanceApiField,
  allowThermalGapField.apiField, railLengthForThermalGapApiField, 
  foundationScrewLength, buildingstorey, allowSecondaryBallastTrayApiField, centralRailsCountApiField,
  parapetHeightNumericApiField, endClampsApiField, allowDualAttachmentsApiField,
  smTileReplacementsOrSolarhooksApiField, roofMaterialThicknessSolarMountFlushApiField,
  staggerAttachmentsApiField, 'clamps_location', rmIFIParapetHeightApiField,
  allowManualAttachmentsApiField, topChordApiField, railClampOptionApiField,
  addRoofPadsEverywhere, useFriction, manufacturerTypeField, colouTypeField,
  choiceOfFastendTypeField, subCategoryField, isUL3741RequiredField, blocksOrientationApiField, isDomesticMLPERequiredField
]

export const additionalUserInputsPage = (productId) => {
  const apiFields = [
   
  ]
  if (isRMGridFlex(productId)){
    apiFields.splice(0,0, ...[loadSharingTypeApiField, thermalFactorApiField, frictionFactorApiField, designLifeFactorFcApiField, seismicIpApiField, snowIsApiField, fullyAdheredApiField, windIwApiField])
  } else if (isRmGridflex10(productId)){
    apiFields.splice(0,0, ...[
      velocityPressureExposureCoefficientKzApiField, 
      groundElevationFactorKeApiField, windDirectionalityFactorKdApiField, 
      topographicalFactorKztApiField, numericalCoefficientApiField, 
      designLifeFactorFcApiField, roofExposureApiField,
      loadSharingTypeApiField, thermalFactorApiField, 
      frictionFactorApiField, soilClassApiField, seismicIpApiField, snowIsApiField, fullyAdheredApiField, windIwApiField])
  }
  else{
    apiFields.splice(0,0, ...[velocityPressureExposureCoefficientKzApiField,groundElevationFactorKeApiField,topographicalFactorKztApiField,numericalCoefficientApiField,windDirectionalityFactorKdApiField,roofExposureApiField,soilClassApiField, designLifeFactorFcApiField])
  }
  return apiFields
}

export const additionalUserInputsPageDependencyFields = [
  elevationApiField, windExposureApiField, buildingHeightApiField, 
  buildingCodeApiField, meanRecurrenceIntervalApiField
]

export const FIELD_FLYOUT_MAPPING = {
  'client_name': LOCATION_AND_LOADS_PAGE,
  'client_address': LOCATION_AND_LOADS_PAGE,
  'zipcode': LOCATION_AND_LOADS_PAGE,
  [buildingCodeApiField]: LOCATION_AND_LOADS_PAGE,
  [windExposureApiField]: LOCATION_AND_LOADS_PAGE,
  [windSpeedApiField]: LOCATION_AND_LOADS_PAGE,
  [elevationApiField]: LOCATION_AND_LOADS_PAGE,
  [meanRecurrenceIntervalApiField]: LOCATION_AND_LOADS_PAGE,
  [snowLoadApiField]: LOCATION_AND_LOADS_PAGE,
  [seismicSsApiField]: LOCATION_AND_LOADS_PAGE,
  [seismicS1ApiField]: LOCATION_AND_LOADS_PAGE,
  [riskCategoryApiField]: LOCATION_AND_LOADS_PAGE,
  [deadLoadFactorApiField]: LOCATION_AND_LOADS_PAGE,
  [topographicalFactorKztApiField]: LOCATION_AND_LOADS_PAGE,
  [seismicSdsApiField]: LOCATION_AND_LOADS_PAGE,
  [seismicSd1ApiField]: LOCATION_AND_LOADS_PAGE,
  [tornadoSpeedField]: LOCATION_AND_LOADS_PAGE,
  [longTransitionPeriodsApiField]: LOCATION_AND_LOADS_PAGE,
  [longestRowLengthApiField]: LOCATION_AND_LOADS_PAGE,
  [shortestRowLengthApiField]: LOCATION_AND_LOADS_PAGE,
  [nbccWindPressureApiField]: LOCATION_AND_LOADS_PAGE,
  [nbccRoughnessApiField]: LOCATION_AND_LOADS_PAGE,
  [nbccCategoryApiField]: LOCATION_AND_LOADS_PAGE,
  [rainLoadApiField]: LOCATION_AND_LOADS_PAGE,
  [moduleTypeApiField] : LOCATION_AND_LOADS_PAGE,

  [buildingHeightApiField]: RAIL_SYSTEM_PAGE,
  [roofTypeApiField]: RAIL_SYSTEM_PAGE,
  [parapetHeightApiField]: RAIL_SYSTEM_PAGE,
  [longestBuildingLengthApiField]: RAIL_SYSTEM_PAGE,
  [shortestBuildingLengthApiField]: RAIL_SYSTEM_PAGE,
  [roundingHalfBlockApiField]: RAIL_SYSTEM_PAGE,
  [ballastHalfBlockWeightApiField]: RAIL_SYSTEM_PAGE,
  [ballastBlockWeightApiField]: RAIL_SYSTEM_PAGE,
  [deflectorsApiField]: RAIL_SYSTEM_PAGE,
  [centralSupportApiField]: RAIL_SYSTEM_PAGE,
  [limitDownPointLoadsApiField]: RAIL_SYSTEM_PAGE,
  [allowMechanicalAttachmentsApiField]: RAIL_SYSTEM_PAGE,
  [allowManualAttachmentsApiField]: RAIL_SYSTEM_PAGE,
  [attachmentOptimizationCriteriaApiField]: RAIL_SYSTEM_PAGE,
  [attachmentTypeApiField]: RAIL_SYSTEM_PAGE,
  [upliftAllowableApiField]: RAIL_SYSTEM_PAGE,
  [shearAllowableApiField]: RAIL_SYSTEM_PAGE,
  [uAnchorTypeApiField]: RAIL_SYSTEM_PAGE,
  [roofPsfLimitApiField]: RAIL_SYSTEM_PAGE,
  [midClampsApiFieldChoiceApiField]: RAIL_SYSTEM_PAGE,
  [endClampsChoiceApiField]: RAIL_SYSTEM_PAGE,
  [railFinishApiField]: RAIL_SYSTEM_PAGE,
  [railTypeApiField]: RAIL_SYSTEM_PAGE,
  [railDirectionApiField]: RAIL_SYSTEM_PAGE,
  [tiltApiField]: RAIL_SYSTEM_PAGE,
  [preferredSpanApiField]: RAIL_SYSTEM_PAGE,
  [rafterSpacingApiField]: RAIL_SYSTEM_PAGE,
  [maxDownPointLoadToBeAllowedApiField]: RAIL_SYSTEM_PAGE,
  [railArrangementTypeApiField]: RAIL_SYSTEM_PAGE,
  [clampTypeChoiceApiField]: RAIL_SYSTEM_PAGE,
  [foundationDiameterApiField]: RAIL_SYSTEM_PAGE,
  [foundationTypeApiField]: RAIL_SYSTEM_PAGE,
  [foundationScrewLength]: RAIL_SYSTEM_PAGE,
  [foundationLengthApiField]: RAIL_SYSTEM_PAGE,
  [frontEdgeHeightApiField]: RAIL_SYSTEM_PAGE,
  [railsPerModuleChoiceApiField]: RAIL_SYSTEM_PAGE,
  [nsDiagnolBraceApiField]: RAIL_SYSTEM_PAGE,
  'clamps_location': RAIL_SYSTEM_PAGE,
  [slipSheetApiField]: RAIL_SYSTEM_PAGE,
  [surfaceTypeApiField]: RAIL_SYSTEM_PAGE,
  [setBackDistanceApiField]: RAIL_SYSTEM_PAGE,
  [allowThermalGapField.apiField]: RAIL_SYSTEM_PAGE,
  [railLengthForThermalGapApiField]: RAIL_SYSTEM_PAGE,
  [roofSubstrateApiField]: RAIL_SYSTEM_PAGE,
  [allowSecondaryBallastTrayApiField]: RAIL_SYSTEM_PAGE,
  [allowDualAttachmentsApiField]: RAIL_SYSTEM_PAGE,
  [centralRailsCountApiField]: RAIL_SYSTEM_PAGE,
  [parapetHeightNumericApiField]: RAIL_SYSTEM_PAGE,
  [endClampsApiField]: RAIL_SYSTEM_PAGE,
  [smTileReplacementsOrSolarhooksApiField]: RAIL_SYSTEM_PAGE,
  [roofMaterialThicknessSolarMountFlushApiField]: RAIL_SYSTEM_PAGE,
  [staggerAttachmentsApiField]: RAIL_SYSTEM_PAGE,
  [rmIFIParapetHeightApiField]: RAIL_SYSTEM_PAGE,
  [topChordApiField]: RAIL_SYSTEM_PAGE,
  [railClampOptionApiField]: RAIL_SYSTEM_PAGE,
  [addRoofPadsEverywhere]: RAIL_SYSTEM_PAGE,
  [useFriction]: RAIL_SYSTEM_PAGE,
  [manufacturerTypeField] : RAIL_SYSTEM_PAGE,
  [colouTypeField] : RAIL_SYSTEM_PAGE,
  [choiceOfFastendTypeField]: RAIL_SYSTEM_PAGE,
  [subCategoryField]: RAIL_SYSTEM_PAGE,

  [velocityPressureExposureCoefficientKzApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [groundElevationFactorKeApiField]: ADDITIONAL_USER_INPUTS_PAGE ,
  [designLifeFactorFcApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [numericalCoefficientApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [windDirectionalityFactorKdApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [roofExposureApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [soilClassApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  [isUL3741RequiredField]: RAIL_SYSTEM_PAGE,
  [blocksOrientationApiField]: RAIL_SYSTEM_PAGE,
  // [loadSharingTypeApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  // [thermalFactorApiField]: ADDITIONAL_USER_INPUTS_PAGE,
  // [frictionFactorApiField]: ADDITIONAL_USER_INPUTS_PAGE,
}

export const IS_RM10_EVO_RM5_RMDT = [8, 14, 15, 22, 28]


export const AscenderStructureType= Object.freeze({
  ELEVATED_1P: 1,
  NON_ELEVATED_1P: 2,
  ELEVATED_2P: 3,
  NON_ELEVATED_2P: 4
});

export function PRODUCTS_WITHOUT_ROOF_PITCH(productId, projectVersion) {
  const list = [ products.rm_dt, products.ascender, products.gft, products.ula ]
  if (!adjustableTiltSystem(projectVersion)){
    list.push(products.solarmount_2_tilt);
  }
  return list;
}
export const PRODUCTS_WITH_STRUCTURE_TILT = [products.sm_tilt_pr_lt, products.sm_tilt_pr]

export const removeUpslopeRail = (attachment_type, projectVersion) => attachment_type === AttachmentType.FLASHLOC_DUO && applyRemovalOfUpslopeRail(projectVersion);