import buildingHeight from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import rafterSpacing from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import railFinish from 'projectDesign/components/projectConfiguration/fields/railFinish';
import roofType from 'projectDesign/components/projectConfiguration/fields/roofType';
import  { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import span from 'projectDesign/components/projectConfiguration/fields/span';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/windExposure';
import windSpeed from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { checkRafterSpacing } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { getBuildingCodeSF } from '../utils/getBuildingCode';
import {  
  getASCE716BuildingDescriptionAdditionalInputsForSF,
} from '../fields/getASCE716BuildingDescriptionResidentalAdditionalInputs';
import { 
  getASCE716722LocationsResidentalAdditionalInputs,
} from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { state } from '__common/store';

export default (buildingCodeValue: number) => {
  const buildingCode = getBuildingCodeSF();
  const snowLoad  = getSnowLoadField();
  const {projectConfiguration : {projectEnvConfig: {rafter_spacing_inches}}} = state();
  
  checkEnvironmentalFactors(buildingCode, windSpeed(), snowLoad, null, elevation);

  rafterSpacing.missing = !checkRafterSpacing(Number(rafter_spacing_inches));

  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        WindExposure,
        windSpeed(),
        snowLoad,
        elevation,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
      ],
    },
    Spans: {
      label: '',
      fields: [
        span,
        rafterSpacing,
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        roofType,
        ...getASCE716BuildingDescriptionAdditionalInputsForSF(buildingCodeValue),
      ],
    },
    railFinish,
  };

  return cfg;
};

