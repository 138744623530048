import { getRoofColor, roofHasPanels } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManagerHelper';
import nextUid from '__common/calculations/nextUid';

export default (roof: googleRoofObject, map: google.maps.Map) => {
  const marker = new google.maps.Marker({
    position: roof.overlay.getMarkerPointInsideRoof(),
    // position: roof.overlay.getRoofApproximateCenter(),
    map,
    icon: getGoogleMarkerIcon(roof.id),
    zIndex: 3,
  });
  marker.id = nextUid();
  return marker;
};


export function getGoogleMarkerIcon(roofId: number) {
  const hasPanels = roofHasPanels(roofId);
  return {
    // path: google.maps.SymbolPath.CIRCLE,
    url: hasPanels ? require('assets/media/icons/edit_panels_icon.svg') : require('assets/media/icons/add_panels_icon.svg'),
    fillColor: getRoofColor(roofId),
    fillOpacity: 1,
    strokeWeight: 0,
    size: new google.maps.Size(50, 50),
    anchor: new google.maps.Point(25, 25),
  };
}
