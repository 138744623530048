import * as React from 'react';
import ConfirmationSelectField from '__common/components/ConfirmationSelectField';
import CustomModuleSelector from '__common/components/moduleSelector/components/customModuleSelector';
import Loader from '__common/components/LoaderComponent';
import natsort from 'natsort';
import { Link } from 'react-router-dom';
import { anyPanelsDrawn } from '__editor/googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { connect } from 'react-redux';
import { POPULATE_MODELS_BY_MODULE, SET_MODEL, FETCH_MFGS_AND_MODELS_REQUEST, SET_SAME_PANEL_DIMS } from './moduleSelectorActions';
import { makeAllRoofsEmpty } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { Button } from 'react-md';
import { RESIZE_PANELS, REPLACE_PANELS_ON_ROOF } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import { shouldConfirmModelChange, isSingleOrientationInProject } from './moduleSelectorHelper';
import { getEditorCenter } from '__editor/panelsEditor/components/background/background';
import { isPanelCollideWithObstacles, checkObstructionZoneForPanels } from '__editor/panelsEditor/components/obstructions/obstructionsCollisions';
import { updateProjectOptionsOnModuleDimsChange } from 'projectDesign/components/projectConfiguration/utils/updateModuleDims';
import { isRM5, products } from '__common/constants/products';
import { state } from '__common/store';
import { SET_FLYOUT_VERIFIED, SET_PROJECT_OPTION } from 'actions';
import { MODULE_SELECTION_PAGE } from 'projectDesign/components/projectConfiguration/utils/projectConfigurationDrawerPages';
import { greaterThanEqualToProjectVersion } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import { cmsToMeters } from '__common/calculations/unitConversions';
import { filterOutPanelsNotInsideRoofEdges } from 'projectDesign/rmGridflexBlankMapUtils';
import { feetsToMeters } from '__common/calculations/feetsToMeters';
import { shouldUseSetBackDistance } from '__editor/panelsEditor/panelsEditorHelper';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { apiField as ul3741Required } from 'projectDesign/components/projectConfiguration/fields/ul3741Required';
import { contains_all } from 'projectDesign/components/projectConfiguration/utils/validation';

interface Props {
  dispatch: Function;
  setMfgAndModelByIds: (productId: string, mfg: number, modelId: number, modelData: modelData, projectVersion: any, inputUnit: number) => void;
  populateModelsBySelectedMfg: (productId: string, mfg: number, projectVersion: string) => void;
  setModelById: (modelId: number, modelData?: modelData, productId?: number, inputUnit?: number) => void;
  setFlyoutVerified: (flyout: string, value: boolean, error: boolean) => void,
  setSamePanelsDims: (width?: number, length?: number) => void;
  resizePanels: (oldModel: modelData, newModel: panelModel, productId: number) => void;
  replacePanelsOnRoof: (panels: panelInState[], roofId: number) => void;
  turnOffUL3741Required: () => void;
  buildingCode: number;
  mapType: string;
  fullSelectWidth?: boolean;
  modelOnChange?: Function;
  moduleSelector: moduleSelectorState;
  productId: string;
  selectedMfgId?: number;
  selectedModelId?: number;
  saveLoadProject: saveLoadProjectState;
  roofsOnMap: { [roofId: string]: drawingManagerRoof };
  noCustomModule: boolean;
  noTitle: boolean;
  hideSameDimsButton?: boolean;
  hideRefreshButton?: boolean;
  projectVersion?: string;
  inputUnit?: number;
}

interface State {
  oldMfg: number;
  oldModel: number;
  modelData: modelData;
}
 
class ModuleSelector extends React.Component<Props, State> {

  state = {
    oldMfg: null,
    oldModel: null,
    modelData: null,
  };

  _isWhiteBg = () => {
    const { mapType } = this.props;
    return mapType === 'white';
  }

  _resizePanels = (newModelId: number) => {
    const { moduleSelector: { models, modelData }, productId } = this.props;
    let oldModel;
    const newModel = models.find(model => model.id === newModelId);

    if (!modelData || Object.keys(modelData).length === 0) {
      oldModel = this.state.modelData;
    } else {
      oldModel = modelData;
    }
    this.props.resizePanels(oldModel, newModel, products[productId]);
    this._checkObstructionsCollisions();
  }

  _checkObstructionsCollisions = () => {
    const { roofsOnMap } = this.props;

    if (!roofsOnMap || Object.keys(roofsOnMap).length === 0) {
      return;
    }

    // I am doing the same in many places in app
    // Iterating over roof should be done with
    // better manuer
    Object.keys(roofsOnMap).map(roofId => {
      const roof = roofsOnMap[roofId];
      const panels = roof.panels;
      const { blank_map_building_length: length, blank_map_building_width: width, bgScale } = roof;
      const { roofsSelector: { mapType }, projectConfiguration: { projectEnvConfig: { setback_distance: setbackDistance }, inputUnit, productId, projectVersion }, settings: { panelWidth, panelHeight } } = state();
      let filteredPanels;
      if (!panels || panels.length === 0) {
        return;
      }
      const center = getEditorCenter();
      const metersPerPixel = roof.metersPerPixel;

      const panelsNotOnObstructions = panels.filter(panel => {
        return !isPanelCollideWithObstacles(panel, Number(roofId), center.x, center.y, panel.width, panel.height, metersPerPixel);
      });

      const panelsInObstructionZone = checkObstructionZoneForPanels(panelsNotOnObstructions, Number(roofId), metersPerPixel, center.x, center.y);
      if (shouldUseSetBackDistance(mapType, productId, projectVersion)){
        const setbackInMeters = isMetricUnit(inputUnit) ? cmsToMeters(setbackDistance) : feetsToMeters(setbackDistance);
        filteredPanels = filterOutPanelsNotInsideRoofEdges({ length, width, setbackDistance: setbackInMeters, metersPerPixel, bgScale, panelWidth, panelHeight, panels: panelsInObstructionZone });
      }
      else{
        filteredPanels = panelsInObstructionZone;
      }
      this.props.replacePanelsOnRoof(filteredPanels, Number(roofId));
    });
  }

  resetSelectedModel = () => {
    this.setState({ oldMfg: null, oldModel: null, modelData: null });
  }

  storeSelectedModel = () => {
    const { moduleSelector: { selectedMfgId, selectedModelId, modelData } } = this.props;
    if (selectedMfgId && selectedModelId) {
      this.setState({ oldMfg: selectedMfgId, oldModel: selectedModelId, modelData });
    }
  }

  mfgChange = (mfg: number) => {
    const { productId, projectVersion,} = this.props;
    this.props.populateModelsBySelectedMfg(productId, mfg, projectVersion);
    [375,164,93,247,281,8,362,341].includes(mfg) ? this.props.turnOffUL3741Required() : null;
    this.storeSelectedModel();
    this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, true);
  }
 
  modelOnChange = (modelId: number, sameWidth: boolean) => {
    const {inputUnit} = this.props;
    const customModel = modelId === 1000001 ? this.state.modelData : undefined;
    this.props.setModelById(modelId, customModel, products[this.props.productId], inputUnit);
    contains_all(state().moduleSelector.modelData.name, "Q,PEAK,DUO,XL,G11") ? this.props.turnOffUL3741Required() : null;
    this.resetSelectedModel();
    this.props.setFlyoutVerified(MODULE_SELECTION_PAGE, false, false);

    if (anyPanelsDrawn() && this._isWhiteBg() && isSingleOrientationInProject()) {
      return this._resizePanels(modelId);
    }
    if (anyPanelsDrawn() && !sameWidth) {
      makeAllRoofsEmpty();
    }
    
    updateProjectOptionsOnModuleDimsChange();
  }
  
  sorter = (panels) => {
    const sorter = natsort();
    panels.sort((a, b) => {
      return sorter(a.name, b.name);
    });
 
    return panels;
  }

  _getSavedPanelsDims = () => {
    const { moduleSelector: { modelData: { width, height } } } = this.props;
    const { modelData } = this.state;
    
    if (modelData) {
      return { width: modelData.width, height: modelData.height };
    }

    if (width && height) {
      return { width, height };
    }

    return { width: null, height: null };
  }

  _isSelectedMfgOrModelOnList = (selectedId: number, arr:{ id: number }[]) => {
    return arr.some(el => el.id === selectedId);
  }

  setPanelsWithSameDims = (pan: panelModel[]) => {
    const { mapType } = this.props;
    const { width, height } = this._getSavedPanelsDims();

    const panels = pan.map((panel) => {
      if (width === Number(panel.width) && height === Number(panel.height) && mapType !== 'white' || panel.id === 1000001) {
        panel.highlight = true;
      } else {
        panel.highlight = false;
      }
      
      return panel;
    });

    return panels;
  }

  setWithSameDims = () => {
    const { moduleSelector: { sameDims, selectedMfgId, selectedModelId }, productId, projectVersion, inputUnit } = this.props;

    if (sameDims.width && sameDims.length) {
      this.props.setSamePanelsDims();
    } else {
      const { width, height } = this._getSavedPanelsDims();
      this.props.setSamePanelsDims(width, height);
    }

    this.props.setMfgAndModelByIds(productId, selectedMfgId, selectedModelId, this.props.moduleSelector.modelData, projectVersion, inputUnit );
  }

  refreshModules = () => {
    const { oldMfg, oldModel, modelData } = this.state;
    const { productId, projectVersion, inputUnit, setFlyoutVerified } = this.props;
    if (oldMfg && oldModel) {
      this.props.setMfgAndModelByIds(productId, oldMfg, oldModel, modelData, projectVersion, inputUnit);
      this.resetSelectedModel();
      setFlyoutVerified(MODULE_SELECTION_PAGE, false, false);
    }
  }

  sameDimsTooltip = () => {
    const { moduleSelector: { modelData, sameDims } } = this.props;

    if (sameDims && sameDims.width && sameDims.length) {
      return `Modules filtered to only with ${sameDims.width}" width and ${sameDims.length}" length`;
    }

    if (modelData && modelData.width && modelData.height) {
      return `Filter modules only by ${modelData.width}" width and ${modelData.height}" length`;
    }

    return '';
  }

  filterModelsWithSameWidth = (panels: panelModel[]) => {
    const { moduleSelector: { sameDims: { width, length } } } = this.props;

    if (width && length) {
      return panels.filter(panel => (width === Number(panel.width) && length === Number(panel.height)) || panel.id === 1000001);
    }

    return panels;
  }

  filterModelsBasedOnLength = (models: panelModel[], length) => {
    return models.filter(model => (model.height <= length))
  }

  refreshTooltip = () => {
    const { oldMfg, oldModel } = this.state;

    if (oldMfg && oldModel) {
      return `Restore selection to previous panel model`;
    }

    return '';
  }

  renderLoader = () => {
    return <Loader />;
  }

  renderSameDimsButton = () => {
    const { moduleSelector: { sameDims: { width, length } }, hideSameDimsButton } = this.props;

    if (hideSameDimsButton) {
      return null;
    }

    const activeClass = width && length ? 'active' : '';
    return (<Button flat={true} tooltipLabel={this.sameDimsTooltip()} className={`same-dims-modules ${activeClass}`} onClick={this.setWithSameDims}><div className="material-icons">aspect_ratio</div></Button>);
  }

  renderRefresh = () => {
    const { hideRefreshButton } = this.props;
    const { oldMfg, oldModel } = this.state;

    if (hideRefreshButton) {
      return null;
    }

    if (oldMfg && oldModel) {
      return (<Button flat={true} tooltipLabel={this.refreshTooltip()} className="refresh-module" onClick={this.refreshModules}><div className="material-icons">refresh</div></Button>);
    }
  }
 
  renderSelectsInputs = () => {
    const {
      selectedMfgId,
      selectedModelId,
      mfgs,
      models,
      modelData: {
        width,
        height,
        thickness,
      },
    } = this.props.moduleSelector;
    const { projectConfiguration : { productId, projectVersion, projectEnvConfig : { external_mfg, external_model, is_aurora_project} }, moduleSelector : { modelData : { height: lengthInInches } } } = state();    
    const { noCustomModule, noTitle } = this.props;
    const modelsWithSameDims = this.setPanelsWithSameDims(models);
    const filteredModels = this.filterModelsWithSameWidth(modelsWithSameDims);
    var rm5ValidationLengthLimit = greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['removed_25_yrs_mri_option_in_ef2+']) ? 98 : 92
    const filteredModelsOnLength = isRM5(productId) ? this.filterModelsBasedOnLength(filteredModels,rm5ValidationLengthLimit) : []; //only for rm5     // Custom module selector relies on this data to be loaded when mounted to perform initial validation.
    const moduleDataLoaded = width !== undefined && height !== undefined && thickness !== undefined;
    const optional_array_values = []
    if (is_aurora_project) {
      optional_array_values.push(external_mfg, external_model, is_aurora_project)
    }

    return (
      <>
        {is_aurora_project && (<div className="warning-message-aurora" >The greyed fields are read-only and cannot be edited in the Ubuilder.</div>)}
        {!noTitle && (
          <div className="drawer-section-title">
            Module Selection 
            {!is_aurora_project && (
              <Link to="/userpreferences">
                <span className="set-default-values-text">Set default values</span>
              </Link>
            )} 
          </div>
        )}
        <div className="module-selector">
          <div className="mfg">
            <div className={`input-label ${selectedMfgId === null || !this._isSelectedMfgOrModelOnList(selectedMfgId, mfgs) ? 'input-error' : ''}`}>MFG</div>
            <ConfirmationSelectField
              items={this.sorter(mfgs)}
              value={selectedMfgId}
              autocomplete={true}
              onConfirm={this.mfgChange}
              title="Are you sure you want to change the module?"
              content="All array layouts will be lost."
              shouldConfirm={false}
              selectFieldProps={{
                id: 'module',
                itemLabel: 'name',
                itemValue: 'id',
                fullWidth: true,
                listHeightRestricted: true,
              }}
              optional_array_values = {optional_array_values}
              disabled={is_aurora_project}
            />
          </div>
          <div className="model">
            <div className={`input-label ${selectedModelId === null || !this._isSelectedMfgOrModelOnList(selectedModelId, modelsWithSameDims) ? 'input-error' : ''}`}>MODEL</div>
            <ConfirmationSelectField
              items={isRM5(productId) && lengthInInches > rm5ValidationLengthLimit ? this.sorter(filteredModelsOnLength) : this.sorter(filteredModels)}
              value={selectedModelId}
              autocomplete={true}
              onConfirm={this.modelOnChange}
              title="Are you sure you want to change the model?"
              content={this._isWhiteBg() ? "All the array layouts will be updated." : "All array layouts will be lost."}
              shouldConfirm={shouldConfirmModelChange()}
              selectFieldProps={{
                id: 'model',
                itemLabel: 'name',
                itemValue: 'id',
                fullWidth: true,
                sameWidth: true,
                position: 'tr',
                listHeightRestricted: true,
              }}
              optional_array_values = {optional_array_values}
              disabled={is_aurora_project}
            />
          </div>
          {!is_aurora_project && (this.renderSameDimsButton())}
          {this.renderRefresh()}
        </div>
        {!noCustomModule && moduleDataLoaded && (
          <>
            <div className="drawer-section-title">Custom Module</div>
            <CustomModuleSelector />
          </>
        )}
      </>
    );
  }
 
  render() {
    const {
      mfgsError,
      modelsError,
      mfgsLoading,
      modelsLoading,
    } = this.props.moduleSelector;

    if (mfgsError) {
      return <p> Cannot get mfgs</p>;
    }
    if (modelsError) {
      return <p> Cannot get models</p>;
    }
    if (mfgsLoading || modelsLoading) {
      return this.renderSelectsInputs();
    }
 
    return this.renderSelectsInputs();
  }
}
 
const mapDispatchToProps = (dispatch: Function) => {
  return {
    populateModelsBySelectedMfg: (productId: string, mfg: number, projectVersion: string) => dispatch(POPULATE_MODELS_BY_MODULE(productId, mfg, projectVersion)),
    setModelById: (modelId: number, customModel?: modelData, productId?: number,  inputUnit?: number) => dispatch(SET_MODEL(modelId, customModel, productId,  inputUnit)),
    setFlyoutVerified: (flyout: string, value: boolean, error: boolean) => dispatch(SET_FLYOUT_VERIFIED(flyout, value, error)),
    setMfgAndModelByIds: (productId: string, mfg: number, modelId: number, modelData: modelData, projectVersion: any, inputUnit?: number) => dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, mfg, modelId, modelData, projectVersion, inputUnit)),
    setSamePanelsDims: (width?: number, length?: number) => dispatch(SET_SAME_PANEL_DIMS({ width, length })),
    resizePanels: (oldModel: modelData, newModel: modelData, productId: number) => dispatch(RESIZE_PANELS(oldModel, newModel, productId)),
    replacePanelsOnRoof: (panels: panelInState[], roofId: number) => dispatch(REPLACE_PANELS_ON_ROOF(panels, roofId)),
    turnOffUL3741Required: () => dispatch(SET_PROJECT_OPTION(ul3741Required, 0)),
  };
};
 
const mapStateToProps = (state: appState) => {
  return {
    moduleSelector: state.moduleSelector,
    saveLoadProject: state.saveLoadProject,
    mapType: state.roofsSelector.mapType,
    roofsOnMap: state.drawingManager.roofs,
    buildingCode: state.projectConfiguration.projectEnvConfig.building_code,
    projectVersion: state.projectConfiguration.projectVersion,
    inputUnit: state.projectConfiguration.inputUnit,
  };
};
 
export default connect(mapStateToProps, mapDispatchToProps)(ModuleSelector);
