import React from 'react';
import { isCanadianZipcode } from '../../utils/validation';

export const projectConfigurationWarning = () => {
    return (
            <div className="warning-message" >For large modules sizes or heavy site conditions, please check with Unirac about mid-supports</div>
   );
};

export const zipcodeMessage = (zipcode: string) => {
    if (isCanadianZipcode(zipcode)) {
        return <div className="warning-message" > Check with local jurisdiction to verify loading conditions </div>
    }
}
