import { getYesOrNo } from "../projectConfiguration";

export const apiField = 'is_domestic_mlpe_required'

const config = {
    label: 'isDomesticMlpeRequired',
    type: 'Select',
    apiField,
    missing: undefined,
    data: getYesOrNo()
};

export default config;
