import { createReducer } from '__common/utils/helpers';
import { ReportProblemActionTypes } from 'actionsConstants';
import { TypeOfSupport } from './constants';

export let reportProblemState: reportProblemState = {
  open: false,
  imageOpen: false,
  fileName: null,
  sending: false,
  success: false,
  loading: false,
  errors: {},
  formData: {},
  contact_support_category: TypeOfSupport.ISSUE_REPORT,
  ticket_id: null,
};

export default {
  reportProblem: createReducer(reportProblemState, {
    [ReportProblemActionTypes.SHOW_REPORT_PROBLEM_FORM](state: reportProblemState):reportProblemState {
      return { ...state, open: true };
    },
    [ReportProblemActionTypes.HIDE_REPORT_PROBLEM_FORM](state: reportProblemState):reportProblemState {
      return { ...state, open: false, sending: false, success: false, errors: {}, formData: {}, loading: false };
    },
    [ReportProblemActionTypes.SEND_REPORT_PROBLEM_FORM](state: reportProblemState):reportProblemState {
      return { ...state, sending: true, success: false, errors: {}, loading: true };
    },
    [ReportProblemActionTypes.SUCCESS_SEND_REPORT_PROBLEM_FORM](state: reportProblemState):reportProblemState {
      return { ...state, sending: false, success: true, errors: {}, formData: {}, loading: false };
    },
    [ReportProblemActionTypes.ERROR_SEND_REPORT_PROBLEM_FORM](state: reportProblemState, action):reportProblemState {
      return { ...state, sending: false, success: false, errors: action.payload.errors || {}, loading: false };
    },
    [ReportProblemActionTypes.CLEAR_REPORT_PROBLEM_ERRORS](state: reportProblemState, action):reportProblemState {
      return { ...state, errors: {} };
    },
    [ReportProblemActionTypes.SET_PROBLEM_FORM_DATA](state: reportProblemState, action: { payload: { field: string, value: string | File} }):reportProblemState {
      const { field, value } = action.payload;
      
      state.formData[field] = value;

      return { ...state };
    },
    [ReportProblemActionTypes.START_REPORT_FORM_LOADING](state: reportProblemState): reportProblemState {
      return { ...state, loading: true };
    },
    [ReportProblemActionTypes.RESET_PROBLEM_FORM]():reportProblemState {
      return {
        open: false,
        imageOpen: false,
        fileName: null,
        sending: false,
        success: false,
        errors: {},
        formData: {},
        loading: false,
        contact_support_category: TypeOfSupport.ISSUE_REPORT,
        ticket_id: null,
      };
    },
    [ReportProblemActionTypes.SHOW_IMAGE](state: reportProblemState, action: { payload: { enable: boolean, fileName: string} }):reportProblemState {
      const { enable, fileName } = action.payload;
      
      state.imageOpen = enable;
      state.fileName = fileName;
      return { ...state };
    },
    [ReportProblemActionTypes.SELECT_TYPE_OF_CONTACT_SUPPORT](state: reportProblemState, action: { payload: { contact_support_category: TypeOfSupport } }):reportProblemState {
      const { contact_support_category } = action.payload;
      state.contact_support_category = contact_support_category
      return { ...state };
    },
    [ReportProblemActionTypes.SET_TICKET_ID](state: reportProblemState, action: { payload: { ticket_id: string } }):reportProblemState {
      const { ticket_id } = action.payload;
      state.ticket_id = ticket_id
      return { ...state };
    },
  }),
};
