import { loadSharingOptions } from "projectDesign/components/projectConfiguration/fields/loadSharingType";
import nextUid from "__common/calculations/nextUid";
import store from "__common/store";

export interface gridSettings {
  rowSpacingMeters: number;
  columnSpacingMeters: number;
  moduleWidthMeters: number;
  moduleLengthMeters: number;
  productId: number;
  metersPerPixel: number;
}


export const addAttachmentsOnCornersAndEdgesForEachArray = (panels: panelInState[], rowSpacingPx: number, columnSpacingPx: number, loadSharingType: string) => {
    const panelMap = new Map();
    const corners = [];
    const edges = [];

    // Create a map for checking
    panels.forEach(({ x, y, id }) => {
        panelMap.set(`${Number(x.toFixed(4))},${Number(y.toFixed(4))}`, id);
    });

    const panelNeighbours = (panel) => {
      return {
        top: panelMap.get(`${Number(panel.x.toFixed(4))},${Number((panel.y - rowSpacingPx - panel.height).toFixed(4))}`),
        bottom: panelMap.get(`${Number(panel.x.toFixed(4))},${Number((panel.y + rowSpacingPx + panel.height).toFixed(4))}`),
        left: panelMap.get(`${Number((panel.x - columnSpacingPx - panel.width).toFixed(4))},${Number(panel.y.toFixed(4))}`),
        right: panelMap.get(`${Number((panel.x + columnSpacingPx + panel.width).toFixed(4))},${Number(panel.y.toFixed(4))}`),
    };
    }

    // Classify corners and edges
    panels.forEach((panel) => {
        const neighbors = panelNeighbours(panel);

        if ((!neighbors.top && !neighbors.left) || (!neighbors.top && !neighbors.right) ||
            (!neighbors.bottom && !neighbors.left) || (!neighbors.bottom && !neighbors.right)) {
            corners.push(panel.id);
            panel.attached = true;
        } 
        else if (!neighbors.top || !neighbors.bottom || !neighbors.left || !neighbors.right) {
          edges.push(panel.id);
        }
    });

    if (loadSharingType === loadSharingOptions.fmg.value) {
      edges.forEach(edgeId => {
      const edgePanel = panels.find(p => p.id === edgeId);
      const edgePanelNeighbours = panelNeighbours(edgePanel)
        if (edgePanelNeighbours?.top && edgePanelNeighbours?.bottom) {
          const topPanel = panels.find(p => p.id === edgePanelNeighbours.top);
          const bottomPanel = panels.find(p => p.id === edgePanelNeighbours.bottom);

          if (!topPanel.attached && !bottomPanel.attached) {
            edgePanel.attached = true;
          }
          else if (!topPanel.attached && (bottomPanel.attached && corners.includes(bottomPanel.id))){
            edgePanel.attached = true;
          }
        }

        else if (edgePanelNeighbours?.left && edgePanelNeighbours?.right) {
          const leftPanel = panels.find(p => p.id === edgePanelNeighbours.left);
          const rightPanel = panels.find(p => p.id === edgePanelNeighbours.right);

          if (!leftPanel.attached && !rightPanel.attached) {
            edgePanel.attached = true;
          }
          else if (!leftPanel.attached && (rightPanel.attached && corners.includes(rightPanel.id))){
            edgePanel.attached = true;
          }
        }
        else {
          edgePanel.attached = false;
        }
    });
    }
    

    return panels;
}

export const constructGraph = (panels: panelInState[], rowSpacingPx: number, columnSpacingPx: number) => {
  const leftMap = {};
  const rightMap = {};
  const topMap = {};
  const bottomMap = {};
  const precision = 1;

  const getKey = (x, y) => `${x.toFixed(precision)}-${y.toFixed(precision)}`;

  const getNeighborCoords = p => {
    const leftXCoord = p.x - columnSpacingPx - p.width;
    const leftYCoord = p.y;
    const leftCoord =  getKey(leftXCoord, leftYCoord);

    const rightXCoord = p.x + columnSpacingPx + p.width;
    const rightYCoord = p.y;
    const rightCoord =  getKey(rightXCoord, rightYCoord);

    const topXCoord = p.x;
    const topYCoord = p.y - rowSpacingPx - p.height;
    const topCoord =  getKey(topXCoord, topYCoord);

    const bottomXCoord = p.x;
    const bottomYCoord = p.y + rowSpacingPx + p.height;
    const bottomCoord = getKey(bottomXCoord, bottomYCoord);

    return { leftCoord, rightCoord, topCoord, bottomCoord };
    
  }
  const _panels = [];
  panels.forEach(p=>{
    const pData = {id: p.id, groupId: p.groupId, left: null, right: null, top: null, bottom: null};
    const pKey = getKey(p.x, p.y);
    if (leftMap[pKey]) {
      const p1Data = leftMap[pKey];
      p1Data.left = pData;
      pData.right = p1Data;
    } 
    if (rightMap[pKey]) {
      const p1Data = rightMap[pKey];
      p1Data.right = pData;
      pData.left = p1Data;
    } 
    if (topMap[pKey]) {
      const p1Data = topMap[pKey];
      p1Data.top = pData;
      pData.bottom = p1Data;
    }
    if (bottomMap[pKey]) {
      const p1Data = bottomMap[pKey];
      p1Data.bottom = pData;
      pData.top = p1Data;
    }
    const { leftCoord, rightCoord, topCoord, bottomCoord } = getNeighborCoords(p);  
    leftMap[leftCoord] = pData;
    rightMap[rightCoord] = pData;
    topMap[topCoord] = pData;
    bottomMap[bottomCoord] = pData;
    _panels.push(pData);
  });
  return _panels;
};

export const createArrayGrid = (panels: panelInState[]) => {
  const EMPTY_CELL = 0;
  const {
    settings: {
      rowSpacing, 
      columnSpacing,
    },
    background: {
      metersPerPixel,
    },
  } = store.getState();

  const panelHeight = panels[0].height;
  const panelWidth = panels[0].width;
    
  const moduleLengthMeters = panelHeight * metersPerPixel;
  const moduleWidthMeters = panelWidth * metersPerPixel;
  const xMargin = 0.001 + columnSpacing + 0.05;
  const yMargin = 0.001 + rowSpacing + 0.05;
  const xValues = panels.map((panel) => panel.x * metersPerPixel);
  const yValues = panels.map((panel) => panel.y * metersPerPixel);

  const xMin = Math.min(...xValues);
  const xMax = Math.max(...xValues);
  const yMin = Math.min(...yValues);
  const yMax = Math.max(...yValues);

  const dxRaw = (xMax - xMin) / (moduleWidthMeters + columnSpacing);
  const dyRaw = (yMax - yMin) / (moduleLengthMeters + rowSpacing);

  let dx = Number(Math.round(dxRaw)) + 1;

  let dy = Number(Math.round(dyRaw)) + 1;

  const grid = [];

  for (let i = 0; i < dy; i++) {
      grid.push([]);
      for (let j = 0; j < dx; j++) {
          grid[i].push(EMPTY_CELL);
      }
  }
  panels.forEach(panel => {
      const { x, y } = panel;
      const column = Math.abs(
      Number((
          (xMin - x * metersPerPixel) / (moduleWidthMeters + columnSpacing)
      ).toFixed(4)),
      );

      const row = Math.abs(
      Number((
          (yMin - y * metersPerPixel) / (moduleLengthMeters + rowSpacing)
      ).toFixed(4)),
      );
      dx = Math.abs(column - Number(column.toFixed(1)));
      dy = Math.abs(row - Number(row.toFixed(1)));

      if (dx < xMargin && dy < yMargin) {
          const columnIndex = Number(Math.round(column));
          const rowIndex = Number(Math.round(row));
          grid[rowIndex][columnIndex] = panel;
      }
  });
  return grid;
};

const depthFirstSearch = ({panel, groupId, visited}) => {
  const stack = [panel];
  const neighbors = ['left', 'right', 'top', 'bottom', 'sibling'];
  while (stack.length) {
      const p = stack.pop();
      if (visited[p.id]) {
          continue;
      }
      visited[p.id] = groupId;
      neighbors.forEach(key=>{
          if (p[key] && !visited[p[key].id]) stack.push(p[key]); 
      });
  }
};

export const splitArraysIntoSubarrays = (graph) => {
  const visited = {};
  graph.forEach(p=>visited[p.id]=0);
  graph.forEach(p=> {
    if (!visited[p.id]) {
        let groupId = p.groupId;
        groupId = nextUid();
        depthFirstSearch({panel: p, groupId, visited});
    }
  });
  return visited;
};

export const constructGraphForRMDT = (panels, rowSpacingPx, columnSpacingPx) => {
  const leftMap = {};
  const rightMap = {};
  const topMap = {};
  const bottomMap = {};
  const precision = 1;

  const getKey = (x, y) => `${x.toFixed(precision)}-${y.toFixed(precision)}`;

  const getNeighborCoords = (p) => {
    const leftCoord = getKey(p.x - columnSpacingPx - p.width, p.y);
    const rightCoord = getKey(p.x + columnSpacingPx + p.width, p.y);
    const topCoord = getKey(p.x, p.y - rowSpacingPx - p.height);
    const bottomCoord = getKey(p.x, p.y + rowSpacingPx + p.height);
    return { leftCoord, rightCoord, topCoord, bottomCoord };
  };

  const _panels = [];
  const siblingMap = new Map();
  panels.forEach((p) => {
    const pData = { id: p.id, groupId: p.groupId, siblingId: p.siblingId, left: null, right: null, top: null, bottom: null };
    const pKey = getKey(p.x, p.y);
    
    if (!siblingMap.has(p.siblingId)) {
      siblingMap.set(p.siblingId, []);
    }
    siblingMap.get(p.siblingId).push(pData);

    if (leftMap[pKey]) {
      leftMap[pKey].left = pData;
      pData.right = leftMap[pKey];
    }
    if (rightMap[pKey]) {
      rightMap[pKey].right = pData;
      pData.left = rightMap[pKey];
    }
    if (topMap[pKey]) {
      topMap[pKey].top = pData;
      pData.bottom = topMap[pKey];
    }
    if (bottomMap[pKey]) {
      bottomMap[pKey].bottom = pData;
      pData.top = bottomMap[pKey];
    }
    
    const { leftCoord, rightCoord, topCoord, bottomCoord } = getNeighborCoords(p);
    leftMap[leftCoord] = pData;
    rightMap[rightCoord] = pData;
    topMap[topCoord] = pData;
    bottomMap[bottomCoord] = pData;
    _panels.push(pData);
  });

  siblingMap.forEach((siblings) => {
    if (siblings.length === 2) {
      siblings[0].sibling = siblings[1];
      siblings[1].sibling = siblings[0];
    }
  });

  return _panels;
};
