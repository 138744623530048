import buildingCode from '../projectConfiguration/fields/buildingCode';
import buildingHeight from '../projectConfiguration/fields/buildingHeight';
import { isASCE716or722BuildingCode } from 'projectDesign/components/projectConfiguration/projectConfiguration';
import residentalBuildingTypes from '../projectConfiguration/fields/residentalBuildingTypes';
import shortestBuildingLength from '../projectConfiguration/fields/shortestBuildingLength';
import longestBuildingLength from 'projectDesign/components/projectConfiguration/fields/longestBuildingLength';
import { isCommercialProduct } from '__common/constants/products';

export const shouldRecalculateAsce716 = (
  field: string, 
  newFieldValue: number|string,
  previousValues: projectEnvConfig,
  currentValues: projectEnvConfig,
  productId: number,
) => {
  const { 
    building_code, 
  } = previousValues;
  const  {building_code:current_building_code} = currentValues;
  const isBuildingCodeField = field === buildingCode.apiField;
  const isBuildingHeightField = field === buildingHeight.apiField;
  const isBuildingTypeField = field === residentalBuildingTypes.apiField;
  const isShortestBuildingLengthField = field === shortestBuildingLength.apiField;
  const isLongestBuildingLengthField = field === longestBuildingLength.apiField;

  if (field === buildingCode.apiField && !isASCE716or722BuildingCode(newFieldValue)) {
    return false;
  }

  // Changing to asce7-16 means calculating exposure and roof zones.
  // And to make these calculations we need some fields to be set to determine zones' size. 
  if (!areRequiredFieldsSet({
    buildingHeightValue: currentValues.building_height,
    field,
    longestBuildingLengthValue: currentValues.building_length,
    newFieldValue,
    productId,
    shortestBuildingLengthValue: currentValues.shortest_building_length,
  })) {
    return false;
  }

  return ( 
    isASCE716or722BuildingCode(current_building_code) &&
    (
    buildingCodeHasChangedToAsce716or722(isBuildingCodeField, Number(newFieldValue), building_code, currentValues.building_height) ||
    buildingHeightHasChangedToAsce716or722(isBuildingHeightField, newFieldValue, building_code) ||
    buildingTypeHasChangedToASCE716or722(isBuildingTypeField, newFieldValue, building_code) ||
    shortestBuildingLengthHasChangedToASCE716or722(isShortestBuildingLengthField, newFieldValue, building_code) ||
    longestBuildingLengthHasChanged(isLongestBuildingLengthField, newFieldValue, building_code)
    )
  );
};

interface requiredFieldsSetConfig {
  field: string;
  newFieldValue: number|string;
  productId: number;
  shortestBuildingLengthValue: number|undefined;
  longestBuildingLengthValue: number|undefined;
  buildingHeightValue: number|undefined;
}

export const areRequiredFieldsSet = (config: requiredFieldsSetConfig): boolean => {
  const { 
    buildingHeightValue,
    field,
    longestBuildingLengthValue,
    newFieldValue,
    productId,
    shortestBuildingLengthValue,
  } = config;

  const isShortestBuildingLengthField = field === shortestBuildingLength.apiField;
  const isLongestBuildingLengthField = field === longestBuildingLength.apiField;
  const isBuildingHeightField = field === buildingHeight.apiField;
  if (isCommercialProduct(productId)) {
    return (
      longestBuildingLengthValue !== undefined || 
      // because it could have been just set, and was undefined before.
      (isLongestBuildingLengthField && newFieldValue !== undefined)
    );
  }

  return (
    shortestBuildingLengthValue !== undefined ||
    (isShortestBuildingLengthField && newFieldValue !== undefined)
  ) && (
    buildingHeightValue !== undefined ||
    (isBuildingHeightField && newFieldValue !== undefined)
  );
};

export const shouldRecalculateNonAsce716or722 = (field: string, newFieldValue: number) => {
  const isBuildingCodeField = field === buildingCode.apiField;
  return isBuildingCodeField && !isASCE716or722BuildingCode(newFieldValue);
};

export const buildingCodeHasChangedToAsce716or722 = (
  isBuildingCodeField: boolean, 
  newFieldValue: number, 
  building_code: number, 
  building_height: number,
) => (
  isBuildingCodeField && 
  isASCE716or722BuildingCode(newFieldValue) && 
  newFieldValue !== building_code && 
  building_height !== undefined
);

const buildingHeightHasChangedToAsce716or722 = (
    isBuildingHeightField: boolean,
    newFieldValue: number|string, 
    building_code: number, 
  ) => 
  (isASCE716or722BuildingCode(building_code) && isBuildingHeightField && newFieldValue !== undefined);

const buildingTypeHasChangedToASCE716or722 = (
    isBuildingTypeField: boolean,
    newFieldValue: number|string, 
    building_code: number, 
  ) => 
  isASCE716or722BuildingCode(building_code) && isBuildingTypeField && Number(newFieldValue) > -1;

const shortestBuildingLengthHasChangedToASCE716or722 = (
    isShortestBuildingLengthField: boolean,
    newFieldValue: number|string, 
    building_code: number, 
  ) => 
  isASCE716or722BuildingCode(building_code) && isShortestBuildingLengthField && Number(newFieldValue) > -1;
  
const longestBuildingLengthHasChanged = (
  isLongestBuildingLengthField: boolean,
  newFieldValue: number|string,
  building_code: number,
) => (
  isASCE716or722BuildingCode(building_code) && isLongestBuildingLengthField && !!newFieldValue
);
