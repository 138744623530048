import * as PIXI from 'pixi.js';
import { getTexture } from '__editor/panelsEditor/models/utils/textureManager';
import { state } from '__common/store';
import { getPanelColor } from './utils/panelColor';
import { getEdgeTypeName } from '../components/roofZones/roofZones';
import { DEBUG } from 'debug';
import { _baseBayTexture } from './utils/panelTexture';
import { isRM10, isRM10Evolution, isRMGridFlex, isRmGridflex10, isRMDT } from '__common/constants/products';
function panelModel({ x, y, id, height, width, groupId, roofZone, exposed, nearObstruction, skewAffected, edgeType, exposedNeighbour, attached, panelConfig, siblingId }: panelInState): any {
  const { settings: { canvasCenter }, projectConfiguration: {productId}, roofZones: { skewAffectedModules }, panels:{ panels } } = state();
  const edgeName = getEdgeTypeName(roofZone, edgeType) as string;
  const texture = getTexture('panel', width, height, roofZone, nearObstruction, exposed, exposedNeighbour, edgeName, attached, panelConfig, skewAffected && skewAffectedModules ? 1 : 0);
  const panel = new PIXI.Sprite(texture);
  panel.id = id;

  if (DEBUG.showPanelId || DEBUG.showGroupId) {
    // Be aware that it may cause CONTEXT_LOST_WEBGL issue for large arrays.
    // This is because of the https://github.com/pixijs/pixi.js/issues/5178
    const text = DEBUG.showPanelId? id: groupId;
    const panelIdText = new PIXI.Text(text.toString(), { fontSize: '10px', fontWeight:'500' });
    panelIdText.width = width;
    panelIdText.height = height;
    panelIdText.x = width / 2;
    panelIdText.y = height / 2;
    panel.addChild(panelIdText);
  }

  if (DEBUG.showSlopMark && ((isRMGridFlex(productId) || isRmGridflex10(productId)) ? !attached : true)) {
    const slopMark = slopMarks(panel)
    const slopeMark = new PIXI.Text(slopMark.toString(), { fontSize: '60px', fontWeight:'1000' });
    slopeMark.width = width/4;
    slopeMark.height = height/2;
    slopeMark.x = width / 1;
    slopeMark.y = height / 1;
    panel.addChild(slopeMark);
  }
  
  panel.groupId = groupId;
  panel.roofZone = roofZone;
  panel.interactive = true;
  panel.buttonMode = isRMGridFlex(productId) || isRmGridflex10(productId) ? true: false;
  panel.exposed = exposed;
  panel.nearObstruction = nearObstruction;
  panel.exposedNeighbour = exposedNeighbour;
  panel.attached = attached;
  panel.zIndex = 1;
  panel.defaultCursor = 'crosshair';
  panel.x = x + canvasCenter.x - width / 2;
  panel.y = y + canvasCenter.y - height / 2;
  panel.width = width;
  panel.height = height;
  if (attached) {
    addManualAttachmentMarker(panel, width, height);
  }
  return panel;
}
export function bayModel(panelInfo: panelInState, bayInfo: {id: number, p: number, attached: 0 | 1, rightEdge?:boolean}, rightEdgePanels:number[]=[], leftEdgePanels:number[]=[], northEdgePanels:number[]=[], northRightEdgePanels:number[]=[], northMostRow:number[]=[]): any {
  const { settings: { canvasCenter }, background: { metersPerPixel }, projectConfiguration: { productId }} = state();
  const { x, y, height, width, groupId } = panelInfo
  const texture = _baseBayTexture(width, height, bayInfo.attached)
  const bay = new PIXI.Sprite(texture);
  let BAY_HEIGHT = undefined
  if(isRM10(productId)){
    BAY_HEIGHT = 0.4429125
  } else if(isRM10Evolution(productId)){
    BAY_HEIGHT = 0.3901186
  }
  const BAY_SIZE_Y = 0.3901186 / metersPerPixel
  bay.id = bayInfo.id;
  bay.groupId = groupId;
  bay.interactive = true;
  bay.buttonMode = true;
  bay.attached = bayInfo.attached;
  bay.zIndex = 1;
  bay.defaultCursor = 'crosshair';
  const leftEdgeXOffset = 2 * (width / 3)
  const leftEdgeBayXTuckInOffset = width / 2

  const rightEdgeBayXoffset = width / 3
  const rightEdgeTuckInOffset = width / 6

  const northBaysYoffset = height
  const southBaysYoffset = height / 2
  if(bayInfo.p == 1){
    if(northEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x - leftEdgeBayXTuckInOffset;
      bay.y = y - northBaysYoffset + height/2 + canvasCenter.y;
    } else if (leftEdgePanels.includes(panelInfo.id)) {
      bay.x = x + canvasCenter.x - leftEdgeBayXTuckInOffset;
      bay.y = y - northBaysYoffset + canvasCenter.y;
    } else {
      bay.x = x + canvasCenter.x - leftEdgeBayXTuckInOffset;
      bay.y = y - northBaysYoffset + canvasCenter.y;
    }
  } else if(bayInfo.p == 2){
    if(rightEdgePanels.includes(panelInfo.id) && northEdgePanels.includes(panelInfo.id)) {
      bay.x = x + canvasCenter.x + rightEdgeTuckInOffset;
      bay.y = y - northBaysYoffset + height/2 + canvasCenter.y; 
    } else if(rightEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x + rightEdgeTuckInOffset;
      bay.y = y - northBaysYoffset + canvasCenter.y;
    } else if(northEdgePanels.includes(panelInfo.id) && northRightEdgePanels.includes(panelInfo.id)) {
      bay.x = x + canvasCenter.x + rightEdgeTuckInOffset;
      bay.y = y - northBaysYoffset + height/2 + canvasCenter.y;
    } else if(northEdgePanels.includes(panelInfo.id)) {
      bay.x = x + canvasCenter.x + rightEdgeBayXoffset;
      bay.y = y - northBaysYoffset + height/2 + canvasCenter.y; 
    } else {
      bay.x = x + canvasCenter.x + rightEdgeBayXoffset;
      bay.y = y - northBaysYoffset + canvasCenter.y; 
    }
  } else if(bayInfo.p == 3){
    if(rightEdgePanels.includes(panelInfo.id) && northMostRow.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x + rightEdgeBayXoffset;
      bay.y = y + southBaysYoffset + canvasCenter.y; 
    } else if(rightEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x + rightEdgeTuckInOffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;
    } else {
      bay.x = x + canvasCenter.x + rightEdgeBayXoffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;  
    }
  } else if(bayInfo.p == 4){
    if (leftEdgePanels.includes(panelInfo.id) && northEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x - leftEdgeBayXTuckInOffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;
    } else if(leftEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x - leftEdgeBayXTuckInOffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;
    } else if (northEdgePanels.includes(panelInfo.id)){
      bay.x = x + canvasCenter.x - leftEdgeXOffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;
    } else {
      bay.x = x + canvasCenter.x - leftEdgeXOffset;
      bay.y = y + southBaysYoffset + canvasCenter.y;
    }
  }
  bay.width = width / 3;
  bay.height = BAY_SIZE_Y;
  if (DEBUG.showBayId) {
    // Be aware that it may cause CONTEXT_LOST_WEBGL issue for large arrays.
    // This is because of the https://github.com/pixijs/pixi.js/issues/5178
    const text = DEBUG.showPanelId? bayInfo.id: groupId;
    const panelIdText = new PIXI.Text(text.toString() + '/' + bayInfo.p, { fontSize: '10px', fontWeight: '700', fill: '0xFFA500' });
    panelIdText.position.set(bay.width + bay.width/2, bay.height - bay.height/2 - bay.height/2 - bay.height/2)
    bay.addChild(panelIdText);
  }
  if (bayInfo.attached) {
    addManualAttachmentMarker(bay, width, height);
  }
  return bay;
}
export function addManualAttachmentMarker(panel: PIXI.Sprite, width, height) {
  const attachmentMarker = new PIXI.Text('X', { fontSize: '30px', fontWeight: 'bold' });
  attachmentMarker.width = width
  attachmentMarker.height = height
  attachmentMarker.position.set(2 * width / 3, 2 * height / 3);
  // Add the attachmentMarker as a child of the panel
  panel.addChild(attachmentMarker);
}

export function slopMarks(panel){
  const { projectConfiguration: { productId }, panels : { panels }} = state();
  const pID = panels && panels.length>0 && panels.filter(p=>p.id === panel.id)
  if(isRMDT(productId)){
    if(pID[0]?.siblingSide === 0 ){
      return "⬅"
    }else{
      return "➡"
    }
  }
  return "⬇"
}

export function getColorByRoofZone(roofZone: roofZoneNumber, nearObstruction: boolean, skewAffected?: number) {
  const { 
    roofsSelector: { 
      mapType,
    }, 
    projectConfiguration: { 
      productId, 
      projectEnvConfig: { 
        building_code,
      },
      projectVersion,
    },
  } = state();

  const enabledRoofZones = state().panels.enabledRoofZones;
  
  return getPanelColor(
    roofZone,
    nearObstruction,
    building_code,
    productId,
    mapType,
    enabledRoofZones,
    projectVersion,
    skewAffected
  );
}

export default panelModel;
