import { DrawingManagerActionTypes } from 'actionsConstants';
import { Action } from '__common/store/action';


export function RESET_DRAWING_MANAGER() {
  return {
    type: DrawingManagerActionTypes.RESET_DRAWING_MANAGER,
  };
}

export function CHANGE_ROOF_ZOOM(roofId: number, zoom: number) {
  return {
    type: DrawingManagerActionTypes.CHANGE_ROOF_ZOOM,
    payload: {
      roofId,
      zoom,
    },
  };
}

export function SET_SELECTED_ROOF_AND_MARKER(roofId: number) {
  return {
    type: DrawingManagerActionTypes.SET_SELECTED_ROOF_AND_MARKER,
    payload: {
      roofId,
    },
  };
}

export function UNSET_SELECTED_ROOF_AND_MARKER(roofId: number) {
  return {
    type: DrawingManagerActionTypes.UNSET_SELECTED_ROOF_AND_MARKER,
    payload: {
      roofId,
    },
  };
}

export function UNSELECT_ALL_SELECTED_ROOF_AND_MARKER() {
  return {
    type: DrawingManagerActionTypes.UNSELECT_ALL_SELECTED_ROOF_AND_MARKER,
  };
}

export function ADD_ROOF(coords: { lat: number, lng: number }[], roofEdgesPixiCords: { x: number, y: number }[], marker: { lat: number, lng: number }, id: number) {
  return {
    type: DrawingManagerActionTypes.ADD_ROOF,
    payload: {
      coords,
      roofEdgesPixiCords,
      marker,
      id,
    },
  };
}

export function CLEAR_SELECTION() {
  return {
    type: DrawingManagerActionTypes.CLEAR_SELECTION,
  };
}

export function DELETE_ROOF(id: number) {
  return {
    type: DrawingManagerActionTypes.DELETE_ROOF,
    payload: {
      id,
    },
  };
}

export function DELETE_PANELS_INSIDE_OF_ROOF(id: number) {
  return {
    type: DrawingManagerActionTypes.DELETE_PANELS_INSIDE_OF_ROOF,
    payload: {
      id,
    },
  };
}

export function DELETE_ALL_ROOFS() {
  return {
    type: DrawingManagerActionTypes.DELETE_ALL_ROOFS,
  };
}

export function UPDATE_ONE_ROOF_CORNER(roofId: number, corner: number, coords: { lat: number, lng: number }) {
  return {
    type: DrawingManagerActionTypes.UPDATE_ONE_ROOF_CORNER,
    payload: {
      roofId,
      corner,
      coords,
    },
  };
}

export function SET_ROOF_AREA_SEEN(roofId: number, seen: boolean) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_AREA_SEEN,
    payload: {
      roofId,
      seen,
    },
  };
}

export function SET_ROOF_AREA_SEEN_FOR_EXPOSURE_CALCULATIONS(roofId: number, opened: boolean) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_AREA_SEEN_FOR_EXPOSURE_CALCULATIONS,
    payload: {
      roofId,
      opened,
    },
  };
}

export interface SAVE_PANELS_TO_DRAWING_MANAGER_ACTION {
  selectedRoofId: number;
  panels: panelInState[];
  bgRotation: number;
  bgOffset: pixelPoint;
  bgScale: pixelPoint;
  roofPitch: string;
  structureTilt: string;
  metersPerPixel: number;
  zoom: number;
  rowSpacing: number;
  tableRowSpacing: number;
  roofEdgesPixiCords: pixelPoint[];
  exposureRecaluclationHash?: string;
  blank_map_building_length?: number;
  blank_map_building_width?: number;
  panelsRotationDegrees?: number;
  lowEdgeToRoof?: number;
  panelBayMapping?: panelBayMapping
}

export function SAVE_PANELS_TO_DRAWING_MANAGER(
  selectedRoofId: number,
  panels: panelInState[],
  bgRotation: number,
  bgOffset: pixelPoint,
  bgScale: pixelPoint,
  roofPitch: string,
  structureTilt: string,
  metersPerPixel: number,
  zoom: number,
  rowSpacing: number,
  tableRowSpacing: number,
  roofEdgesPixiCords: pixelPoint[],
  exposureRecaluclationHash: string,
  blank_map_building_length: number,
  blank_map_building_width: number,
  panelsRotationDegrees?: number,
  lowEdgeToRoof?: number,
  panelBayMapping?: panelBayMapping,
): Action<SAVE_PANELS_TO_DRAWING_MANAGER_ACTION> {
  return {
    type: DrawingManagerActionTypes.SAVE_PANELS_TO_DRAWING_MANAGER,
    payload: {
      selectedRoofId,
      panels,
      bgRotation,
      bgScale,
      roofPitch,
      structureTilt,
      metersPerPixel,
      zoom,
      rowSpacing,
      tableRowSpacing,
      roofEdgesPixiCords,
      exposureRecaluclationHash,
      bgOffset,
      blank_map_building_length,
      blank_map_building_width,
      panelBayMapping,
      panelsRotationDegrees,
      lowEdgeToRoof,
    },
  };
}

export function LOAD_ROOFS_FROM_API(roofs: any[]) {
  return {
    type: DrawingManagerActionTypes.LOAD_ROOFS_FROM_API,
    payload: {
      roofs,
    },
  };
}

export function SET_ARRAY_NAME(roofName: string, arrayId: number) {
  return {
    type: DrawingManagerActionTypes.SET_ARRAY_NAME,
    payload: {
      roofName,
      arrayId,
    },
  };
}

export function UPDATE_ROOF_COORDS(coords: { lat: number, lng: number }[], roofId: number) {
  return {
    type: DrawingManagerActionTypes.UPDATE_ROOF_COORDS,
    payload: {
      coords,
      roofId,
    },
  };
}

export function SORT_ROOFS_ON_MAP() {
  return {
    type: DrawingManagerActionTypes.SORT_ROOFS_ON_MAP,
  };
}

export function TURN_ON_EDIT() {
  return {
    type: DrawingManagerActionTypes.TURN_ON_EDIT,
  };
}

export function TURN_OFF_EDIT() {
  return {
    type: DrawingManagerActionTypes.TURN_OFF_EDIT,
  };
}

export function SET_ROOF_ROW_SPACING(roofId: number, rowSpacing: number) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_ROW_SPACING,
    payload: {
      roofId,
      rowSpacing,
    },
  };
}

export function SET_ROOF_TABLE_ROW_SPACING(roofId: number, tableRowSpacing: number) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_TABLE_ROW_SPACING,
    payload: {
      roofId,
      tableRowSpacing,
    },
  };
}

export function ADD_POINT_TO_ROOF(index: number, position: { lat: number, lng: number }, roofId: number) {
  return {
    type: DrawingManagerActionTypes.ADD_POINT_TO_ROOF,
    payload: {
      index,
      position,
      roofId,
    },
  };
}

export function SET_ROOF_ZOOM(roofId: number, zoom: number) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_ZOOM,
    payload: {
      roofId,
      zoom,
    },
  };
}

export function SET_ROOF_ROOF_PITCH(roofId: number, roofPitch: string | null) {
  return {
    type: DrawingManagerActionTypes.SET_ROOF_ROOF_PITCH,
    payload: {
      roofId,
      roofPitch,
    },
  };
}

export function RESIZE_PANELS(oldModel: modelData, newModel: modelData, productId?: number) {
  return {
    type: DrawingManagerActionTypes.RESIZE_PANELS,
    payload: {
      oldModel,
      newModel,
      productId,
    },
  };
}

export function REPLACE_PANELS_ON_ROOF(panels: panelInState[], roofId: number) {
  return {
    type: DrawingManagerActionTypes.REPLACE_PANELS_ON_ROOF,
    payload: {
      panels,
      roofId,
    },
  };
}

export function REMOVE_PANELS_ON_ROOF(panelIds: number[], roofId: number) {
  return {
    type: DrawingManagerActionTypes.REMOVE_PANELS_ON_ROOF,
    payload: {
      panelIds,
      roofId,
    },
  };
}

export function UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF(roofId: number, length: number, width: number) {
  return {
    type: DrawingManagerActionTypes.UPDATE_BLANK_MAP_ROOF_LENGTH_WIDTH_FOR_BLANK_ROOF,
    payload: {
      roofId,
      blank_map_building_width: width,
      blank_map_building_length: length,
    },
  };
}
export function SET_USER_CLICKED_YES_OR_NO(roofId: number, isConfirmedYesorNo?: boolean | null) {
  return {
    type: DrawingManagerActionTypes.SET_USER_CLICKED_YES_OR_NO,
    payload: {
      roofId,
      isConfirmedYesorNo,
    }
  };
}

export function SET_PANELS_IN_OVERFLOWING_STATE(roofId: number, check?: boolean | null, numberOfOverflowedPanels?: number) {
  return {
    type: DrawingManagerActionTypes.SET_PANELS_IN_OVERFLOWING_STATE,
    payload: {
      roofId,
      check,
      numberOfOverflowedPanels,
    }
  };
}

export function SET_SKEW_ZONE_COORDINATES(roofId: number, skewEdgesPixiCords: pixelPoint[]) {
  return {
    type: DrawingManagerActionTypes.SET_SKEW_ZONE_COORDINATES,
    payload: {
      roofId,
      skewEdgesPixiCords,
    }
  };
}
