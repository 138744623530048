import keyMirror from 'keymirror';


export const ProjectConfigurationActionTypes = keyMirror({
  LOAD_PROJECT_CONFIGURATION: undefined,
  SET_PROJECT_OPTION: undefined,
  CLEAR_PROJECT_CONFIGURATION: undefined,
  SET_PRODUCT_ID: undefined,
  SET_PRODUCT_TITLE: undefined,
  CLEAR_PRODUCT_TITLE: undefined,
  SET_ZIP_AND_ADDRESS_CODE: undefined,
  GET_ENVIRONMENTAL_FACTORS: undefined,
  SET_ENVIRONMENTAL_FACTORS: undefined,
  BLANK_ENVIRONMENTAL_FACTORS: undefined,
  SET_PROJECT_NAME: undefined,
  SET_CITY_NAME: undefined,
  SET_RAILS_PRODUCT_ID: undefined,
  SET_SPAN: undefined,
  LOAD_HELIOSCOPE_PROJECT_CONFIGURATION: undefined,
  SAVE_PROJECT_TITLE: undefined,
  OPEN_DRAWER_PAGE: undefined,
  SET_PROJECT_ID: undefined,
  SET_PROJECT_ID_ON_AUTOSAVE: undefined,
  SET_PROJECT_CONFIGURED: undefined,
  RESET_PROJECT_CONFIGURED: undefined,
  CLEAR_ENV_FACTORS_FOR: undefined,
  ENV_LOADING_FINISHED: undefined,
  REMOVE_OPTION_FROM_CONFIG: undefined,
  REMOVE_OPTIONS_FROM_CONFIG: undefined,
  SET_FIELD_INVALID: undefined,
  CLEAR_INVALID_FIELD: undefined,
  SET_FIELD_WARNING: undefined,
  SET_CLAMP_CHECK_FAILED: undefined,
  FETCH_VALID_RAIL_ARRANGEMENT_LIST: undefined,
  SET_RAIL_ARRANGEMENT_VALID_LIST: undefined,
  RESET_CLAMP_CHECK: undefined,
  SET_ADDITIONAL_LOAD_COMBINATION: undefined,
  SET_DEAD_LOAD_FACTOR_MODIFICATION: undefined,
  SET_SEISMIC_DESIGN_CATEGORY: undefined,
  SET_PROJECT_VERSION: undefined,
  CHANGE_STRUCTURE_TYPE: undefined,
  CHANGE_STRUCTURE_TYPE_TILT: undefined,
  SET_ADJUSTABLE_TILT_SYSTEM: undefined,
  CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN: undefined,
  CLEAR_WARNING: undefined,
  FETCH_MINIMUM_VALID_SETBACK_DISTANCE: undefined,
  SET_SEISMIC_SETBACK_DISTANCE: undefined,
  SET_INPUT_UNIT: undefined,
  TOGGLE_INPUT_UNIT: undefined,
  SET_FLYOUT_VERIFIED: undefined,
  SET_GONE_THROUGH_THE_PANEL_EDITOR: undefined,
  LOAD_AURORA_PROJECT_CONFIGURATION: undefined,
  REDIRECT_TO_AURORA_PROJECT: undefined,
  UPDATE_AURORA_PROJECT_CONFIGURATION: undefined,
  SET_IS_AURORA_PROJECT: undefined,
  SET_GONE_THROUGH_THE_PANEL_EDITOR_FOR_EXPOSURE_CORRECT_CALCULATIONS:undefined,
});
