import React from 'react';
import AreaComponent from '__common/components/AreaComponent';
import { isCanadianZipcode, missingLocationAndLoadsPageValid, projectConfigurationWarningConditionValid, validateZipCode } from '../utils/validation';
import { missingPageWarning } from './components/missingPageWarning';
import { projectConfigurationWarning, zipcodeMessage } from './components/projectConfigurationWarning';
import { renderInputs } from './renderInputs';
import { getLocationAndLoadsTooltipContent } from '__common/components/Drawer/utils/getDrawerTooltipsContent';
import { dispatch, state } from '__common/store';
import { LOCATION_AND_LOADS_PAGE } from '../utils/projectConfigurationDrawerPages';
import { SET_FLYOUT_VERIFIED } from '../projectConfigurationActions';
import { isRMFamily } from '__common/constants/products';

export const locationAndLoadsPage = (
  currentConfig: Function,
  productId: number,
  initData: projectEnvConfig,
  mapType: string,
  location: string,
  envFactorsLoading: boolean,
  area: string,
  getErrorMessageIfInvalid: (fieldName: string) => string | null,
  getWarningMessage: (fieldName: string) => string | null,
) => {
  const { building_code, zipcode } = initData;
  const config = currentConfig(building_code);
  const { projectConfiguration: { verifiedFlyouts, formState: { invalidFields } , projectEnvConfig:{is_aurora_project}}, user: {isPrivilegedUser, isStaff} } = state();
  const missing = config.LocationAndLoads.fields.some(({missing})=>missing);
  if(!invalidFields.length){
    if(missing && !verifiedFlyouts[LOCATION_AND_LOADS_PAGE].error){
      dispatch(SET_FLYOUT_VERIFIED(LOCATION_AND_LOADS_PAGE, false, true));
    } else if(validateZipCode(zipcode) && (!missing && verifiedFlyouts[LOCATION_AND_LOADS_PAGE].error)){
      dispatch(SET_FLYOUT_VERIFIED(LOCATION_AND_LOADS_PAGE, false, false));
    }
  }
  return {
    content: (
      <>
        {is_aurora_project && (<div className="warning-message-aurora" >The greyed fields are read-only and cannot be edited in the Ubuilder.</div>)}
        <div className="drawer-section-title">Location And Loads</div>
        {missingLocationAndLoadsPageValid(productId)
          ? null
          : missingPageWarning()}
        <div className="drawer-fields">
          <div>{renderInputs(config.Client, mapType, location, initData, productId, getErrorMessageIfInvalid, getWarningMessage)}</div>
          <AreaComponent
            initData={initData}
            area={area}
            envFactorsLoading={envFactorsLoading}
            productId={productId}
            mapType={mapType}
          />
          <div>{renderInputs(config.LocationAndLoads, mapType, location, initData, productId, getErrorMessageIfInvalid, getWarningMessage)}</div>
        </div>
        {projectConfigurationWarningConditionValid(productId) ? projectConfigurationWarning(): null}
        {isCanadianZipcode(initData.zipcode) ? zipcodeMessage(initData.zipcode) : null}
      </>
    ),
    tooltip: getLocationAndLoadsTooltipContent(),
  };
};
