import config from 'local_config';
import { AscenderStructureType } from 'projectDesign/components/projectConfiguration/utils/constants';
import React from 'react';
import { products } from '__common/constants/products';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';

type Props = {
  productId: number,
  ascender_structure_types : number[]
  projectConfiguration : projectConfigurationState
};

export default class EngineeringProductDocuments extends React.Component<Props> {
  static SM() {
    return [
      ['SM INSTALLATION GUIDE', 'SM/SM-Installation-Guide_20241129.pdf'],
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'SM/Unirac_SM_Letter_-_NM_9-26-17_eVTLFFZ.pdf'],
      ['SM PRO SERIES INSTALLATION GUIDE', 'SM/SM_PRO_Installation-Guide.pdf'],
      ['SM FLASHKIT PRO BROCHURE & INSTALL GUIDE', 'SM/FlashKit-Pro-Install-Guide.pdf'],
      ['SM FLAT FLASHING INSTALLATION', 'SM/19_FLAT_FLASHING_INSTALLATION_19.pdf'],
      ['SM STANDOFFS INSTALLATION', 'SM/STANDOFFS_INSTALLATION_MANUAL_PUB_180119.pdf'],
      ['SM UL2703 CERTIFICATION', 'SM/SM-UL2703-Certificate-of-Compliance.pdf'],
      ['SM DESIGN AND ENGINEERING GUIDE', 'SM/SM_Design-and-Engineering-Guide.pdf'],
      ['SM COMPONENT DETAIL DRAWINGS', 'SM/SM_Detail-Drawings.pdf'],
      ['HIGH SNOW L-FOOT INSTALL', 'SM/HighLoadLFoot.png'],
      ['SM SALES BROCHURE', 'SM/SOLARMOUNT-Product-Marketing-Brochure.pdf'],
    ];
  }

  static SM_ASCENDER_FLUSH() {
    return [
      ['SM UL2703 CERTIFICATION', 'SM/SM-UL2703-Certificate-of-Compliance.pdf'],
      ['SM DESIGN AND ENGINEERING GUIDE', 'SM/SM_Design-and-Engineering-Guide.pdf'],
    ];
  }
  
  static SMTiltPR() {
    return [
      ['CERTIFICATION LETTER', 'SM_TILT_PR/Puerto_Rico_Tilt_Kit_Design_Tool_Certification_Letter_Final.pdf'],
      ['SM TILT PR INSTALLATION GUIDE','SM_TILT_PR/INSTALLATION_GUIDE_-_SM_TILT_LEGS_(LATIN_AMERICA)_-_17AUG28_-_MM.pdf'],
      ['SM TILT PR DESIGN AND ENGINEERING GUIDE','SM_TILT_PR/SM_Tilt_Legs_(Latin_America)_-_D_E_Guide_-_18JAN03_-_MM.pdf'],
      ['ASSEMBLY DRAWINGS 5 DEGREE TILT', 'SM_TILT_PR/ASSEMBLY_DRAWINGS_5_DG_TILT.pdf'],
      ['ASSEMBLY DRAWINGS 10 DEGREE TILT', 'SM_TILT_PR/ASSEMBLY_DRAWINGS_10_DG_TILT.pdf'],
    ];
  }

  static NH() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'NH/Unirac_NU_Letter_-_NM_9-26-17_eVTLFFZ.pdf'],
      ['NXT UMOUNT INSTALLATION GUIDE','NH/NXT-UMOUNT_Installation-Guide_20241129.pdf'],
      ['NXT UMOUNT UL2703 CERTIFICATION','NH/NXT-UMOUNT-UL2703-Certificate.pdf'],
      ['NXT UMOUNT DESIGN AND ENGINEERING GUIDE','NH/NXT-UMOUNT-Design-Engineering-Guide.pdf'],
      ['NXT UMOUNT DETAIL DRAWINGS','NH/NXT-UMOUNT-Detail-Drawings-082024.pdf'],
      ['NXT UMOUNT PRODUCT BROCHURE','NH/NXT-UMOUNT-Product-Brochure.pdf'],
    ];
  }

  static NxtTilt() {
    return [
      ['NXT TILT DETAIL DRAWING', 'NXT_TILT/NXT-UMOUNT-Tilt-Detail-Drawing.pdf'],
    ];
  }

  static SMTilt(projectVersion) {
    if (adjustableTiltSystem(projectVersion)) { 
      return []
    } else {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'SM/Unirac_SM_Letter_-_NM_9-26-17_eVTLFFZ.pdf'],
      ['SM PRO SERIES INSTALLATION GUIDE', 'SM/SM_PRO_Installation-Guide.pdf'],
      ['SM FLASHKIT PRO BROCHURE & INSTALL GUIDE', 'SM/FlashKit-Pro-Install-Guide.pdf'],
      ['SM TILT LEG INSTALLATION', 'SM/SM_TILT_-_IG_-_2016OCT28_-_TILT_LEG_MANUAL_-_Final_221.pdf'],
      ['SM REVERSE TILT TECHNICAL BULLETIN', 'SMTilt/88_SM_REVERSE_TILT_APPLICABILITY_88-1.pdf'],
      ['SM UL2703 CERTIFICATION', 'SM/SM-UL2703-Certificate-of-Compliance.pdf'],
      ['SM DESIGN AND ENGINEERING GUIDE', 'SM/SM_Design-and-Engineering-Guide.pdf'],
      ['SM COMPONENT DETAIL DRAWINGS', 'SM/SM_Detail-Drawings.pdf'],
      ['HIGH SNOW L-FOOT INSTALL', 'SM/HighLoadLFoot.png'],
      ['SM SALES BROCHURE', 'SM/SM_Brochure_20190725_Web.pdf'],
    ];
  }
  }
  
  static ASCENDER(structure_types: number[]) {
    return [
      ...(structure_types.includes(AscenderStructureType.ELEVATED_1P) ? [['SM Ascender 1-Row Elevated Installation Guide', 'SM_ASCENDER/SM_Ascender_1P_Elevated_InstallationGuide_20220816.pdf']] : []),
      ...(structure_types.includes(AscenderStructureType.NON_ELEVATED_1P) ?[['SM Ascender 1-Row Non Elevated Installation Guide', 'SM_ASCENDER/SM_Ascender_1P_Non_Elevated_InstallationGuide_20220721.pdf']] : []),
      ...(structure_types.includes(AscenderStructureType.ELEVATED_2P) ?[['SM Ascender 2-Row Elevated Installation Guide', 'SM_ASCENDER/SM_Ascender_2P_Elevated_InstallationGuide_20220704.pdf']] : []),
      ...(structure_types.includes(AscenderStructureType.NON_ELEVATED_2P) ?[['SM Ascender 2-Row Non Elevated Installation Guide', 'SM_ASCENDER/SM_Ascender_2P_Non_Elevated_InstallationGuide_20220714.pdf']] : []),
      ['SM Ascender CSA Certificate of Compliance', 'SM_ASCENDER/CSA_Certificate_20221013_F.pdf'],
      ['SM Ascender CSA Report', 'SM_ASCENDER/CSA_Report_20221013_F.pdf'],
    ];
  }

  static SFM2() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'SFM2/SFM_Cert_Letter_-_New_Mexico_-_DOTec.pdf'],
      ['SFM INSTALLATION GUIDE', 'SFM2/SFM_Install-Guide_20240622.pdf'],
      ['SFM UL2703 CERTIFICATION', 'SFM2/SFM-UL2703-Certification-Letter.pdf'],
      ['ROUGH-IN LETTER', 'SFM2/SFM_National_RoughIn_Letter.pdf'],
      ['SFM DESIGN AND ENGINEERING GUIDE', 'SFM2/SFM_Design-and-Engineering-Guide_20190522.pdf'],
      ['NRE INSTALLATION GUIDE', 'SFM2/SFM_NRE_Install-Guide_20190220.pdf'],
      ['SFM COMPONENT DETAIL DRAWINGS', 'SFM2/SFM-Detail-Drawings.pdf'],
      ['SFM STANDARD DETAILS (CAD)', 'SFM2/SFM_Roof-Attachment-Details_20170911.dwg'],
      ['SFM SALES BROCHURE', 'SFM2/SFM-INFINITY_Brochure_20190625_Web.pdf'],
    ];
  }

  static METALX() {
    return [
      ['UL2703 QUALIFICATION LIST METALX', 'metalx/UL2703_Qualification_List-MetalX.pdf'],
      ['METALX INSTALLATION GUIDE', 'metalx/METALX_Installation-Guide_20240823-v.5.0.pdf'],
      ['METALX SALES BROCHURE', 'metalx/MetalX_Brochure.pdf'],
    ];
  }


  static SF() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'SF/cc-sf-newmexico.pdf'],
      ['SUNFRAME INSTALLATION MANUAL', 'SF/12_SF-INSTALLATION-MANUAL_12.pdf'],
      ['SF INSTALLATION DETAILS', 'SF/43_SF_DETAILS.pdf'],
      ['SF 2 PIECE STANDOFF TECHNICAL DATASHEET', 'SF/44_ALUM_STANDOFF_TECHDATA_44.pdf'],
      ['SF FLAT FLASHING INSTALLATION MANUAL', 'SF/19_FLAT_FLASHING_INSTALLATION_45.pdf'],
      ['SPLICE & EXPANSION JOINT INSTALLATION MANUAL', 'SF/ii908_0.pdf'],
      ['GALLING AND ITS PREVENTION', 'SF/is910_galling_4.pdf'],
      ['SUNFRAME CAP STRIP PROFILES', 'SF/sunframecapstrips.pdf'],
      ['SUNFRAME SALES BROCHURE', 'SF/SF-SALES-BROCHURE_14.pdf'],
    ];
  }

  static RM5() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm5/RM5_-_RMDT_Cert_Letter_-_New_Mexico_Oou0A4P.pdf'],
      ['UNIVERSAL INSTALLATION INSPECTION GUIDE', 'rm10/inspectionguide.pdf'],
      ['RM5 INSTALLATION GUIDE', 'rm5/RM5_Installation-Guide_20241129.pdf'],
      ['RM5 MID SUPPORT INSTALLATION GUIDE', 'rm5/RM5_Mid_Support_Installation.pdf'],
      ['RM5 COMPONENT DETAIL DRAWINGS', 'rm5/RM5_Detail-Drawings-1.pdf'],
      ['RM5 / RMDT UL2703 CERTIFICATION', 'rm5/RM5-UL2703-Certificate-of-Compliance.pdf'],
      ['RM5 Product Brochure', 'rm5/RM5-Brochure.pdf'],
    ];
  }

  static RM10() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm10/RM_Cert_Letter_-_New_Mexico_7-31-2017.pdf'],
      ['UNIVERSAL INSTALLATION INSPECTION, O&M MANUAL', 'rm10/inspectionguide.pdf'],
      ['RM10 INSTALLATION GUIDE', 'rm10/RM10-Installation-Guide_20240823.pdf'],
      ['RM10 CSA CERTIFICATE OF COMPLIANCE', 'rm10/RM-2.0-70072584_Report-03Oct2017.pdf'],
      ['RM COMPONENT DETAIL DRAWINGS', 'rm10/RM_Details_72.pdf'],
      ['RM10 UL2703 CERTIFICATION', 'rm10/RM_TUV_Certification_Document_204.pdf'],
      ['RM ASSEMBLY DRAWING (DWG)', 'rm10/RM-A03_RM_4_Module_Assy.dwg'],
      ['RM10 Product Brochure', 'rm10/RM-SALES-BROCHURE_10.pdf'],
    ];
  }

  static RM10Evo() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm_10_evolution/RM_Cert_Letter_-_New_Mexico_7-31-2017.pdf'],
      ['UNIVERSAL INSTALLATION INSPECTION, O&M MANUAL', 'rm10/inspectionguide.pdf'],
      ['RM10-EVO INSTALLATION GUIDE', 'rm10_evo/RM10EVO_Installation-Guide_20240823.pdf'],
      ['RM10-EVO UL3741 INSTALLATION GUIDE', 'rm10_evo/RM10EVO_UL3741-Installation-Guide_20240830.pdf'],
      ['RM10-EVO CSA CERTIFICATE OF COMPLIANCE', 'rm10/RM-2.0-70072584_Report-03Oct2017.pdf'],
      ['RM COMPONENT DETAIL DRAWINGS', 'rm10_evo/RM10-EVO-Detail-Drawings.pdf'],
      ['RM10-EVO UL2703 CERTIFICATION', 'rm10/RM_TUV_Certification_Document_204.pdf'],
      ['RM ASSEMBLY DRAWING (DWG)', 'rm10/RM-A03_RM_4_Module_Assy.dwg'],
      ['RM10-EVO Product Brochure', 'rm10_evo/RM10-EVO-Product-Marketing-Brochure.pdf'],
    ]
  }
 
  static RMGridFlex() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm10/RM_Cert_Letter_-_New_Mexico_7-31-2017.pdf'],
      ['GF5 INSTALLATION GUIDE', 'gf5/GF5-Installation-Guide_20241111.pdf'],
      ['GF5 UL3741 INSTALLATION GUIDE', 'gf5/GridFlex5_UL3741-Installation-Guide_20240830.pdf'],
      ['GF5 DETAIL DRAWINGS', 'gf5/GridFlex5_Detail-Drawings.pdf'],
      ['GF5 PRODUCT MARKETING BROCHURE', 'gf5/GRIDFLEX-5-Product-Marketing-Brochure.pdf'],
      // ['UNIVERSAL INSTALLATION INSPECTION, O&M MANUAL', 'rm10/inspectionguide.pdf'],
      // ['RM10 INSTALLATION GUIDE', 'rm10/RM10_Installation-Guide_20180808.pdf'],
      // ['RM10 CSA CERTIFICATE OF COMPLIANCE', 'rm10/RM-2.0-70072584_Report-03Oct2017.pdf'],
      // ['RM COMPONENT DETAIL DRAWINGS', 'rm10/RM_Details_72.pdf'],
      // ['RM10 UL2703 CERTIFICATION', 'rm10/RM_TUV_Certification_Document_204.pdf'],
      // ['RM ASSEMBLY DRAWING (DWG)', 'rm10/RM-A03_RM_4_Module_Assy.dwg'],
      // ['RM10 Product Brochure', 'rm10/RM-SALES-BROCHURE_10.pdf'],
    ];
  }

  static RMGridFlex10() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm10/RM_Cert_Letter_-_New_Mexico_7-31-2017.pdf'],
      ['GF10 INSTALLATION GUIDE', 'gf10/GF10-Installation-Guide_20241129.pdf'],
      ['GF10 UL3741 INSTALLATION GUIDE', 'gf10/GRIDFLEX-10_UL3741-Installation-Guide_20241002.pdf'],
      ['GF10 DETAIL DRAWINGS', 'gf10/GridFlex-10-Detail-Drawings.pdf'],
      ['GRIDFLEX ATTACHMENT CLAMP INSTALLATION GUIDE', 'gf10/GridFlex-Attachment-Clamp-Installation-Guide.pdf'],
      ['GRIDFLEX 10 PRODUCT BR0CHURE', 'gf10/GridFleX10-UPDATED-Product-Marketing-Brochure-2025.pdf'],
    ];
  }

  static RMDT() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'rm_dt/RM5_-_RMDT_Cert_Letter_-_New_Mexico.pdf'],
      ['UNIVERSAL INSTALLATION INSPECTION, O&M MANUAL', 'rm10/inspectionguide.pdf'],
      ['RMDT INSTALLATION GUIDE', 'rm_dt/RMDT_Installation-Guide_20241129.pdf'],
      ['RMDT COMPONENT DETAIL DRAWINGS', 'rm_dt/RMDT_Detail-Drawings_20200722.pdf'],
      ['RM5 / RMDT UL2703 CERTIFICATION', 'rm_dt/RMDT-UL2703-Certificate-of-Compliance20220228-1.pdf'],
      ['RMDT Product Brochure', 'rm_dt/RMDT_Brochure_20190905_Web.pdf'],
    ];
  }

  static GFT() {
    // TODO: add all product documents
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'gft/GFT_Cert_Letter.pdf'],
      ['GFT Construction Drawings', 'gft/GFT-Construction-Drawings.pdf'],
      ['GFT Sales Brochure', 'gft/GFT_Sales Brochure.pdf'],
      ['GFT UL2703 Certification', 'gft/GFT-UL2703-Certificate-of-Compliance-2.pdf'],
      ['GFT Component Detail Drawings', 'gft/GFT_Detail-Drawings.pdf'],
      ['GFT Shared Rail Installation Guide', 'gft/GFT_-Shared-Rail-Installation-Guide.pdf'],
      ['GFT Installation Guide', 'gft/GFT_Installation-Guide_20240712.pdf'],
      ['GFT New Top Chord Installation Guide', 'gft/GFT_Installation-Guide_20241129.pdf'],
      ['GFT DETAIL DRAWINGS TOP CHORD', 'gft/GFT_-Detail-Drawings-Gen-2-–-Top-Chord-404036.pdf'],
      ['GFT DETAIL DRAWINGS TOP CHORD', 'gft/GFT_Detail-Drawings-Gen-3-Top-Chord-404037-Large-Format-Module-Compatible.pdf'],
    ];
  }

  static ULA() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'ula/ULA_Cert_Letter.pdf'],
      ['ULA Detail Drawings', 'ula/ULA_Detail-Drawings.pdf'],
      ['ULA Sales Brochure', 'ula/ULA-Sales Brochure.pdf'],
      ['ULA PRODUCT MARKETING Brochure', 'ula/ULA-UNIRAC-LARGE-ARRAY-Product-Marketing-Brochure.pdf'],
      ['ULA Installation Manual', 'ula/ULA_Installation-Guide_20241129.pdf'],
    ];
  }

  static ECOFOOT2PLUS() {
    return [
      ['CODE COMPLIANT ENGINEERING LETTER - NM', 'ecofoot2plus/ecofoot2plus_Cert_Letter_-_IOWA.pdf'],
      ['ECOFOOT2+ INSTALLATION GUIDE', 'ecofoot2plus/ECOFOOT2Installation-Guide-_20240823-v.5.0.pdf'],
      ['ECOFOOT2+ UL1703 FIRE LETTER', 'ecofoot2plus/EcoFoot2+-L2-ELS151221__Letter_of_Compliance-Fire.pdf'],
      [' ECOFOOT2+ UL2703 LETTER', 'ecofoot2plus/EcoFoot2Plus_UL2703_Bonding_Letter.pdf'],
      [' ECOFOOT2+ SALES BROCHURE', 'ecofoot2plus/ECOFOOT-2-Product-Marketing-Brochure.pdf'],
    ];
  }


  static getDocuments(productId: number, ascender_structure_types: number[], projectConfiguration : projectConfigurationState) {
    switch (productId) {
      case products.solarmount_2:
      case products.solarmount_hd:
      case products.solarmount_lt:
        return EngineeringProductDocuments.SM();
      case products.solarmount_ascender_flush:
        return EngineeringProductDocuments.SM_ASCENDER_FLUSH();
      case products.solarmount_2_tilt:
        return EngineeringProductDocuments.SMTilt(projectConfiguration.projectVersion);
      case products.nxt_tilt:
        return EngineeringProductDocuments.NxtTilt();
      case products.ascender:
        return EngineeringProductDocuments.ASCENDER(ascender_structure_types);
      case products.sunframe:
        return EngineeringProductDocuments.SF();
      case products.sfmEnhanced:
      case products.sfmInfinity:
        return EngineeringProductDocuments.SFM2();
      case products.metal_x:
        return EngineeringProductDocuments.METALX();
      case products.rm_5:
        return EngineeringProductDocuments.RM5();
      case products.rm:
        return EngineeringProductDocuments.RM10();
      case products.rm_10_evolution:
        return EngineeringProductDocuments.RM10Evo();
      case products.rm_gridflex:
        return EngineeringProductDocuments.RMGridFlex();
      case  products.rm_gridflex_10:
        return EngineeringProductDocuments.RMGridFlex10();
      case products.rm_dt:
        return EngineeringProductDocuments.RMDT();
      case products.gft:
        return EngineeringProductDocuments.GFT();
      case products.ula:
        return EngineeringProductDocuments.ULA();
      case products.ecofoot2_plus:
        return EngineeringProductDocuments.ECOFOOT2PLUS();
      case products.nxt_umount:
      case products.nxt_horizon_hd:
      case products.nxt_horizon_lt:
        return EngineeringProductDocuments.NH();
      case products.sm_tilt_pr:
      case products.sm_tilt_pr_lt:
        return EngineeringProductDocuments.SMTiltPR()
      default:
        break;
    }
  }

  printDocuments(documents: string[][]) {
    const { apiUrl } = config;
    return documents.map((document: [string, string], index: number) => {
      return (
        <div className="document" key={index}>
          <div className="name">
            {document[0]}
          </div>
          <div className="link">
            <a href={`${location.protocol}//${apiUrl}static/pdfs/${document[1]}`} className="print" download={true}>
              <i className="md-icon material-icons md-text--inherit">print</i>
            </a>
          </div>
        </div>
      );
    });
  }

  render() {
    const { productId, ascender_structure_types, projectConfiguration } = this.props;
    return (
      <div className="engineering-products-documents">
        <div className="main-title">
          Product documents
        </div>
        {this.printDocuments(EngineeringProductDocuments.getDocuments(productId, ascender_structure_types, projectConfiguration))}
      </div>
    );
  }
}
