import { isRM10, isRM10Evolution, isRM5, isRmGridflex10, products } from "__common/constants/products";
import { isMetricUnit } from "engineering/components/engineeringProjectDocuments/utils/unitTypes";
import upliftShearAllowableTooltip, { upliftShearAllowableGridflexTooltip } from "../fieldsTooltips/upliftShearAllowableTooltip";
import { lbsToKgs } from "__common/calculations/unitConversions";
import { round } from "lodash";
import { options } from "../fields/uAnchorType"
import { applyAttachmentCapacityChanges } from "__common/utils/versionCompare/versionCompare";

export const apiField = 'uplift_allowable';

export const upliftAllowabledefaultValue = 500;
export const upliftAllowabledefaultValueKg = round(lbsToKgs(upliftAllowabledefaultValue), 2);

export enum AttachmentTypeMinLimit {
  MIN = 1
}
export enum AttachmentTypeUpliftIFIMaxLimit {
  UNIRAC_FLASHLOC_RM = 500,
  U_ANCHOR_U2400 = 404,
  U_ANCHOR_U_2000 = 202,
  U_ANCHOR_U_2400 = 404,
  U_ANCHOR_U_2600 = 404,
  U_ANCHOR_U_2800 = 404,
  CUSTOM = 500,
}

export enum AttachmentTypeUpliftIFIMaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U2400), 1),
  U_ANCHOR_U_2000 = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U_2000), 1),
  U_ANCHOR_U_2400 = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U_2400), 1),
  U_ANCHOR_U_2600 = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U_2600), 1),
  U_ANCHOR_U_2800 = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U_2800), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeUpliftIFIMaxLimit.CUSTOM), 1),
}

export enum AttachmentTypeUpliftRM10MaxLimit {
  UNIRAC_FLASHLOC_RM = 818,
  U_ANCHOR_U2400 = 404,
  U_ANCHOR_U_2000 = 202,
  U_ANCHOR_U_2400 = 559,
  U_ANCHOR_U_2600 = 559,
  U_ANCHOR_U_2800 = 559,
  OMG = 667,
  CUSTOM = 1000,
}
export enum AttachmentTypeUpliftRMGridflex10MaxLimit {
  UNIRAC_FLASHLOC_RM = 500,
  U_ANCHOR_U2400 = 404,
  U_ANCHOR_U_2000 = Math.min(202, 500),
  U_ANCHOR_U_2400 = Math.min(559, 500),
  U_ANCHOR_U_2600 = Math.min(559, 500),
  U_ANCHOR_U_2800 = Math.min(559, 500),
  OMG = 500,
  CUSTOM = 500,
}
export enum AttachmentTypeUpliftRM10MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U2400), 1),
  U_ANCHOR_U_2000 = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U_2000), 1),
  U_ANCHOR_U_2400 = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U_2400), 1),
  U_ANCHOR_U_2600 = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U_2600), 1),
  U_ANCHOR_U_2800 = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U_2800), 1),
  OMG = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.OMG), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeUpliftRM10MaxLimit.CUSTOM), 1),
}

export enum AttachmentTypeUpliftRM5MaxLimit {
  UNIRAC_FLASHLOC_RM = 818,
  U_ANCHOR_U2400_MA = 404,
  U_ANCHOR_U2400_HW = 500,
  U_ANCHOR_U_2000 = 202,
  U_ANCHOR_U_2400 = 559,
  U_ANCHOR_U_2600 = 559,
  U_ANCHOR_U_2800 = 559,
  OMG = 667,
  CUSTOM = 1000,
}

export enum AttachmentTypeUpliftRM5MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.UNIRAC_FLASHLOC_RM), 1),
  U_ANCHOR_U2400_MA = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U2400_MA), 1),
  U_ANCHOR_U2400_HW = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U2400_HW), 1),
  U_ANCHOR_U_2000 = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U_2000), 1),
  U_ANCHOR_U_2400 = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U_2400), 1),
  U_ANCHOR_U_2600 = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U_2600), 1),
  U_ANCHOR_U_2800 = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U_2800), 1),
  OMG = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.OMG), 1),
  CUSTOM = round(lbsToKgs(AttachmentTypeUpliftRM5MaxLimit.CUSTOM), 1),
}
export enum AttachmentTypeUpliftRMGridflex10MaxLimitKg {
  UNIRAC_FLASHLOC_RM = round(lbsToKgs(500), 1),
  U_ANCHOR_U2400 = round(lbsToKgs(404), 1),
  U_ANCHOR_U_2000 = round(Math.min(lbsToKgs(AttachmentTypeUpliftRMGridflex10MaxLimit.U_ANCHOR_U_2000), lbsToKgs(500)), 1),
  U_ANCHOR_U_2400 = round(Math.min(lbsToKgs(AttachmentTypeUpliftRMGridflex10MaxLimit.U_ANCHOR_U_2400), lbsToKgs(500)), 1),
  U_ANCHOR_U_2600 = round(Math.min(lbsToKgs(AttachmentTypeUpliftRMGridflex10MaxLimit.U_ANCHOR_U_2600), lbsToKgs(500)), 1),
  U_ANCHOR_U_2800 = round(Math.min(lbsToKgs(AttachmentTypeUpliftRMGridflex10MaxLimit.U_ANCHOR_U_2800), lbsToKgs(500)), 1),
  OMG = round(lbsToKgs(500), 1),
  CUSTOM = round(lbsToKgs(500), 1),
}

function makeConfig(max: number = 500, defaultValue: number = upliftAllowabledefaultValue, label: string ='upliftAllowable', tooltip = upliftShearAllowableTooltip) {
  return {
    label,
    type: 'Number',
    apiField,
    missing: undefined,
    min: 1,
    max,
    default: defaultValue,
    tooltip,
  };
}


export default makeConfig();

export const upliftAllowableForOMG = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit)){
      return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimitKg.OMG, AttachmentTypeUpliftRMGridflex10MaxLimitKg.OMG, 'upliftAllowableMetric');
    }
    return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimit.OMG, AttachmentTypeUpliftRMGridflex10MaxLimit.OMG);  
  }
  if (isMetricUnit(inputUnit)){
    return makeConfig(AttachmentTypeUpliftRM10MaxLimitKg.OMG, AttachmentTypeUpliftRM10MaxLimitKg.OMG, 'upliftAllowableMetric');
  }
  return makeConfig(AttachmentTypeUpliftRM10MaxLimit.OMG, AttachmentTypeUpliftRM10MaxLimit.OMG);
} 
    

export const upliftAllowableForUAnchor = (productId: number = products.rm_gridflex, anchor_type = 1, projectVersion, inputUnit: number = 1, choice_of_fastend = undefined) => {
  if (isRM10(productId) || isRM10Evolution(productId) || (isRM5(productId) && !applyAttachmentCapacityChanges(productId, projectVersion))){
    if (isMetricUnit(inputUnit)) {
      const max_limit_metric = choice_of_fastend ? AttachmentTypeUpliftRM10MaxLimitKg[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM10MaxLimitKg] : AttachmentTypeUpliftRM10MaxLimitKg.U_ANCHOR_U2400; 
      return makeConfig(max_limit_metric, max_limit_metric, 'upliftAllowableMetric');
    }
    const max_limit = choice_of_fastend ? AttachmentTypeUpliftRM10MaxLimit[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM10MaxLimit] : AttachmentTypeUpliftRM10MaxLimit.U_ANCHOR_U2400;     
    return makeConfig(max_limit, max_limit);
  } else if (isRM5(productId)) {
    if (anchor_type === options.mechanicalAttachment.value){
      if (isMetricUnit(inputUnit)) {
        const max_limit_metric = choice_of_fastend ? AttachmentTypeUpliftRM5MaxLimitKg[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM5MaxLimitKg] : AttachmentTypeUpliftRM5MaxLimitKg.U_ANCHOR_U2400_MA; 
        return makeConfig(max_limit_metric, max_limit_metric, 'upliftAllowableMetric');
      }
      const max_limit = choice_of_fastend ? AttachmentTypeUpliftRM5MaxLimit[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM5MaxLimit] : AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U2400_MA;     
      return makeConfig(max_limit, max_limit);
    }
    else {
      if (isMetricUnit(inputUnit)) {
        const max_limit_metric = choice_of_fastend ? AttachmentTypeUpliftRM5MaxLimitKg[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM5MaxLimitKg] : AttachmentTypeUpliftRM5MaxLimitKg.U_ANCHOR_U2400_HW; 
        return makeConfig(max_limit_metric, max_limit_metric, 'upliftAllowableMetric');
      }
      const max_limit = choice_of_fastend ? AttachmentTypeUpliftRM5MaxLimit[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRM5MaxLimit] : AttachmentTypeUpliftRM5MaxLimit.U_ANCHOR_U2400_HW;     
      return makeConfig(max_limit, max_limit);
    }
    }
  else if (isRmGridflex10(productId)){
      if (isMetricUnit(inputUnit)) {
        const max_limit_metric = choice_of_fastend ? AttachmentTypeUpliftRMGridflex10MaxLimitKg[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRMGridflex10MaxLimitKg] : AttachmentTypeUpliftRMGridflex10MaxLimitKg.U_ANCHOR_U2400; 
        return makeConfig(max_limit_metric, max_limit_metric, 'upliftAllowableMetric');
      }
      const max_limit = choice_of_fastend ? AttachmentTypeUpliftRMGridflex10MaxLimit[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftRMGridflex10MaxLimit] : AttachmentTypeUpliftRMGridflex10MaxLimit.U_ANCHOR_U2400;     
      return makeConfig(max_limit, max_limit);
  }
  else {
    const max_limit = choice_of_fastend ? AttachmentTypeUpliftIFIMaxLimit[`U_ANCHOR_U_${choice_of_fastend}00` as keyof typeof AttachmentTypeUpliftIFIMaxLimit] : AttachmentTypeUpliftIFIMaxLimit.U_ANCHOR_U2400;     
    return  makeConfig(max_limit, max_limit, 'upliftAllowable', upliftShearAllowableGridflexTooltip);
  }

}
export const upliftAllowableForFlashloc = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId) ){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeUpliftRM10MaxLimitKg.UNIRAC_FLASHLOC_RM, AttachmentTypeUpliftRM10MaxLimitKg.UNIRAC_FLASHLOC_RM, 'upliftAllowableMetric');
    return makeConfig(AttachmentTypeUpliftRM10MaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeUpliftRM10MaxLimit.UNIRAC_FLASHLOC_RM);
  }
  if ( isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimitKg.UNIRAC_FLASHLOC_RM, AttachmentTypeUpliftRMGridflex10MaxLimitKg.UNIRAC_FLASHLOC_RM, 'upliftAllowableMetric');
    return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeUpliftRMGridflex10MaxLimit.UNIRAC_FLASHLOC_RM);
  }

  return makeConfig(AttachmentTypeUpliftIFIMaxLimit.UNIRAC_FLASHLOC_RM, AttachmentTypeUpliftIFIMaxLimit.UNIRAC_FLASHLOC_RM, 'upliftAllowable', upliftShearAllowableGridflexTooltip);
}

export const upliftAllowableForCustom = (productId: number = products.rm_gridflex, inputUnit: number = 1) => {
  if (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeUpliftRM10MaxLimitKg.CUSTOM, upliftAllowabledefaultValueKg, 'upliftAllowableMetric');
    return makeConfig(AttachmentTypeUpliftRM10MaxLimit.CUSTOM);
  }
  if ( isRmGridflex10(productId)){
    if (isMetricUnit(inputUnit))
      return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimitKg.CUSTOM, upliftAllowabledefaultValueKg, 'upliftAllowableMetric');
    return makeConfig(AttachmentTypeUpliftRMGridflex10MaxLimit.CUSTOM);
  }

  return makeConfig(AttachmentTypeUpliftIFIMaxLimit.CUSTOM, upliftAllowabledefaultValue, 'upliftAllowable', upliftShearAllowableGridflexTooltip);
}
