import React from 'react';
import { connect } from 'react-redux';
import { panelsEditorEnabled, shouldUseDesiredBuildingLengthWidth } from '__editor/panelsEditor/panelsEditorHelper';
import { checkModelData } from './Drawer/utils/checkModelData';
import { isAscender, isGFT,  isSMTiltPR, isULA } from '__common/constants/products';

type Props = {
  moduleSelector: moduleSelectorState,
  tiltedRoof: tiltedRoofState,
  productId: number,
  tilt: number | null | undefined,
  length: number | null,
  width: number | null,
  blank_map_building_width: number,
  blank_map_building_length: number,
  mapType: string,
  projectVersion: string,
};

class FogOfWar extends React.Component<Props> {
  _shouldAppeare() {
    const { moduleSelector: { selectedMfgId }, tiltedRoof: { roofPitch, structureTilt }, projectVersion, productId, length, width, mapType, blank_map_building_width, blank_map_building_length } = this.props;
    if (panelsEditorEnabled() && ((isGFT(productId) && length === null))) {
      return true;
    }

    if (panelsEditorEnabled() && roofPitch === '---') {
      return true;
    }

    if (panelsEditorEnabled() && shouldUseDesiredBuildingLengthWidth(mapType, productId, projectVersion) && (!blank_map_building_length || !blank_map_building_width)) {
      return true;
    }

    if (!panelsEditorEnabled() && (!selectedMfgId || !checkModelData())) {
      return true;
    }

    if(panelsEditorEnabled() && isSMTiltPR(productId) && (structureTilt === '---')){
      return true;
    }
  }

  render() {
    if (this._shouldAppeare()) {
      return (
        <div className="fog-of-war" />
      );
    }

    return null;
  }
}

function mapStateToProps(appState: appState) {
  return {
    moduleSelector: appState.moduleSelector,
    tiltedRoof: appState.tiltedRoof,
    length: appState.panels.desiredTableLength,
    width: appState.panels.desiredTableWidth,
    productId: appState.projectConfiguration.productId,
    mapType: appState.roofsSelector.mapType,
    blank_map_building_length: appState.background.blank_map_building_length,
    blank_map_building_width: appState.background.blank_map_building_width,
    projectEnvConfig : appState.projectConfiguration.projectEnvConfig,
    projectVersion: appState.projectConfiguration.projectVersion,
  };
}

export default connect(mapStateToProps)(FogOfWar);

