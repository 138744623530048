import { dispatch, state } from '../../store';
import { FETCH_MFGS_AND_MODELS_REQUEST } from 'actions';
import { getPanelsModel } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/loadProject';
import { isAscender, isCanadianChangesProduct } from '__common/constants/products';
import { cmsToInches, inchesToCms, kgsToLbs, lbsToKgs } from '__common/calculations/unitConversions';
import { isCustomaryUnit, isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { isCanadianZipcode } from 'projectDesign/components/projectConfiguration/utils/validation';

export function loadProject(productId: string, module: number, model: number, version: string) {
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, module, model, undefined, version));
}

export function loadProjectModule(productName: string, projectData?: any) {
  let { saveLoadProject: { dataToLoad }, projectConfiguration: { projectVersion, productId, inputUnit, projectEnvConfig: { zipcode } }, app: { isProjectError } } = state();
  let panelsModel;

  if (!isProjectError) {
    panelsModel = getSavedPanelsData(dataToLoad);
  } else {
    let { project_configuration: { model, module, module_length, module_thickness, module_watts, module_weight, module_width, input_unit, product, zipcode: zipCode }, version } = projectData;

    projectVersion = version;
    inputUnit = input_unit;
    zipcode = zipCode;
    productId = product;

    panelsModel = {
      id : model,
      module,
      watts: module_watts,
      width: module_width,
      height: module_length,
      thickness: module_thickness,
      weight: module_weight,
    };
  }

  if(isAscender(productId)){
    panelsModel.height = cmsToInches(panelsModel.height);
    panelsModel.width = cmsToInches(panelsModel.width);
    panelsModel.thickness = cmsToInches(panelsModel.thickness);
    panelsModel.weight = kgsToLbs(panelsModel.weight);
  }
  else if (isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) && !isMetricUnit(inputUnit)){
    panelsModel.height = inchesToCms(panelsModel.height);
    panelsModel.width = inchesToCms(panelsModel.width);
    panelsModel.thickness = inchesToCms(panelsModel.thickness);
    panelsModel.weight = inchesToCms(panelsModel.weight);
  }

  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productName, panelsModel.module, panelsModel.id, panelsModel, projectVersion, inputUnit));
}

export function loadDefaultModule(productId: string) {

  const { userPreferences: { module, model }, projectConfiguration: {projectVersion} } = state();
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, module, model, undefined, projectVersion));
}

export function loadDefaultModuleAroura(productId: string) {

  const { projectConfiguration: {projectVersion} } = state();
  dispatch(FETCH_MFGS_AND_MODELS_REQUEST(productId, 185, 1000001, undefined, projectVersion));
}

function getSavedPanelsData(dataToLoad): panelsModel | any {
  if (dataToLoad && Object.keys(dataToLoad).length) {
    return getPanelsModel(dataToLoad.project_configuration);
  }
}


export const convertModelData  = (modelData, to_unit: number) => {
  if(isCustomaryUnit(to_unit)) {
    modelData.thickness = Number(cmsToInches(modelData.thickness).toFixed(1));
    modelData.width = Number(cmsToInches(modelData.width).toFixed(1));
    modelData.height = Number(cmsToInches(modelData.height).toFixed(1));
    modelData.weight = Number(kgsToLbs(modelData.weight).toFixed(1));
  }
  else if(isMetricUnit(to_unit)) {
    modelData.thickness = Number(inchesToCms(modelData.thickness).toFixed(1));
    modelData.width = Number(inchesToCms(modelData.width).toFixed(1));
    modelData.height = Number(inchesToCms(modelData.height).toFixed(1));
    modelData.weight = Number(lbsToKgs(modelData.weight).toFixed(1));
  }
  return modelData;
}