import { isNxtHorizon, isSMAscenderFlush } from "__common/constants/products";
import { state } from "__common/store";
import { RoofType } from "../utils/constants";

export const apiField = 'attachment_type';

export enum AttachmentType {
  UNIRAC_FLASHLOC_RM = 1,
  U_ANCHOR_U2400 = 2,
  CUSTOM = 3,
  OMG = 21,
  PM_9000S = 19,
  PM_ADJUST = 20,
  MINI_1SS = 16,
  STAND_2SS = 17,
  WIDE_2SS = 18,
  SOLARHOOK_FLAT_TILE = 12,
  SOLARHOOK_9_IN_BASE = 13,
  SOLARHOOK_7_IN_BASE = 14,
  SOLARHOOK_UNIVERSAL =  15,
  L_FOOT_ONLY = 21,
  FLASHKIT_PRO = 22,
  FLASHLOC_COMP_KIT = 23,
  FLASHLOC_DUO = 24,
  STRONGHOLD_ATT_KIT_COMP = 27,
  STRONGHOLD_ATT_COMP_NS = 28,
  FLASHKIT_PRO_SB = 29,
  STRONGHOLD_ATT_BUTYL = 30,
  FLASHKIT = 31,
  SFM_BUTYL_DTD = 32,
  SM_BUTYL_DTD = 33,
  PERFORMANCE_HOOK = 34
}

export const IFI_ATTACHMENT_TYPE = [
  {
    value: AttachmentType.UNIRAC_FLASHLOC_RM,
    name: 'UNIRAC FLASHLOC RM',
  },
  {
    value: AttachmentType.U_ANCHOR_U2400,
    name: 'U-ANCHOR',
  },
  {
    value: AttachmentType.CUSTOM,
    name: 'CUSTOM',
  },
]

const RM10_ATTACHMENT_TYPE = [...IFI_ATTACHMENT_TYPE]
RM10_ATTACHMENT_TYPE.splice(2, 0, {
  value: AttachmentType.OMG,
  name: "OMG"
})

export const SM_FLUSH_ATT = [
  {
    value: AttachmentType.L_FOOT_ONLY,
    name: 'L Foot',
  },
  {
    value: AttachmentType.FLASHKIT_PRO,
    name: 'FLASHKIT PRO',
  },
  {
    value: AttachmentType.FLASHLOC_COMP_KIT,
    name: 'FLASHLOC COMP',
  },
  {
    value: AttachmentType.FLASHLOC_DUO,
    name: 'FLASHLOC DUO',
  },
  {
    value: AttachmentType.FLASHKIT_PRO_SB,
    name: 'FLASHKIT PRO SB',
  },
  {
    value: AttachmentType.SM_BUTYL_DTD,
    name: 'SM BUTYL',
  }
]

export const R_PANEL_ROOF_ATT = [
  {
    value: AttachmentType.PM_ADJUST,
    name: 'PM ADJUST SLOTTED',
  },
  {
    value: AttachmentType.PM_9000S,
    name: 'PM-9000S',
  },
]

export const NXT_ONLY_ATT = [
  {
    value: AttachmentType.STRONGHOLD_ATT_KIT_COMP,
    name: 'STRONGHOLD ATTACHMENT',
  },
  {
    value: AttachmentType.STRONGHOLD_ATT_BUTYL,
    name: 'STRONGHOLD ATT W/BUTYL',
  },
]

export const SFM_INFINITY_ATTACHMENT_TYPES = [
  {
    value: AttachmentType.FLASHKIT,
    name: 'FLASHKIT',
  },
  {
    value: AttachmentType.SFM_BUTYL_DTD,
    name: 'SFM Butyl',
  },
];

interface AttachmentTypeOption {
  value: AttachmentType;
  name: string;
}

const makeConfig = (data: AttachmentTypeOption[]) => ({
  label: 'attachmentType',
  type: 'Select',
  apiField,
  missing: undefined,
  data,
}
);

const config = makeConfig(IFI_ATTACHMENT_TYPE);

export const rm10AttachmentType = makeConfig(RM10_ATTACHMENT_TYPE);
export const sfmInfinityAttachmentType = makeConfig(SFM_INFINITY_ATTACHMENT_TYPES)

const keyToRemove = 'value';
const valueToRemove = AttachmentType.U_ANCHOR_U2400;
const flashlocRmValueRemove = AttachmentType.UNIRAC_FLASHLOC_RM;
export const attachmentTypesWithoutUanchor = makeConfig(removeUanchorFromList(IFI_ATTACHMENT_TYPE, keyToRemove, valueToRemove))
export const rm10AttachmentTypeWithoutUanchor = makeConfig(removeUanchorFromList(RM10_ATTACHMENT_TYPE, keyToRemove, valueToRemove))
export const attachmentTypeWithoutFlachlocrm = makeConfig(removeUanchorFromList(IFI_ATTACHMENT_TYPE, keyToRemove, flashlocRmValueRemove))
export const rm10AttachmentTypeWithoutFlachlocrm = makeConfig(removeUanchorFromList(RM10_ATTACHMENT_TYPE, keyToRemove, flashlocRmValueRemove))

export const smAndNxtAttachmentTypes = (roof_type, productTd) => {
  return {
    type: 'Select',
    label: 'attachmentType',
    apiField,
    missing : undefined,
    data: isNxtHorizon(productTd)? nhAttachmentData(roof_type) : smAttachmentData(roof_type),
  };
};

const smAttachmentData = (roofType) => {
  const {projectConfiguration: {railsProductId}} = state();

  if(isSMAscenderFlush(railsProductId) && roofType == RoofType.SHINGLE) {
    return [
      {
        value: AttachmentType.L_FOOT_ONLY,
        name: 'L Foot',
      },
      {
        value: AttachmentType.FLASHKIT_PRO,
        name: 'Flashkit Pro',
      },
      {
        value: AttachmentType.FLASHKIT_PRO_SB,
        name: 'Flashkit Pro SB',
      },
    ]
  }

  switch (roofType) {
    case  RoofType.R_PANEL:
      return R_PANEL_ROOF_ATT
    case RoofType.OTHERS:
      return getCorrectAttachmentsData(
        SM_FLUSH_ATT,
        [3,4,5]
      ) 
    default:
      return SM_FLUSH_ATT
  }
}

export const getCorrectAttachmentsData = (arr: AttachmentTypeOption[], indexes: number[]) => {
  return arr.filter((a,i)=> !indexes.includes(i))
}


const nhAttachmentData = (roofType) => {  
  switch (roofType) {
    case  RoofType.R_PANEL:
      return R_PANEL_ROOF_ATT
    default:
      return NXT_ONLY_ATT.concat(getCorrectAttachmentsData(
        SM_FLUSH_ATT,
        [2, 5]
      ))
  }
}

export function removeUanchorFromList(dataList, key, value) {
    return dataList.filter(item => item[key] !== value);
}

export default config;
