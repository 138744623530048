import _  from 'lodash';
import { products } from '__common/constants/products';
import { state } from '__common/store';
import { apply96and185InchRail, applyAdd185InchRail, applyAdd185InchRailForSML, greaterThanEqualToProjectVersion, removeGFT166Rail } from '__common/utils/versionCompare/versionCompare';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
export const railsSet1 = [{
  length: 132,
  selected: false,
}, {
  length: 168,
  selected: true,
}, {
  length: 208,
  selected: false,
}, {
  length: 240,
  selected: true,
}];

const smLtFlushRails = (projectVersion: string, selected_rails) => {
  const{projectConfiguration:{railsProductId}} = state();
 const rails = applyAdd185InchRailForSML(projectVersion) ? [{
   length: 168,
   selected: false,
 },
 ([products.solarmount_lt].includes(railsProductId) && {
   length: 185,
   selected: true,
 }),{
   length: 208,
   selected: false,
 },{
   length: 246,
   selected: false,
 }] : [{
   length: 168,
   selected: true,
 },
 ([products.solarmount_lt].includes(railsProductId) && {
   length: 185,
   selected: true,
 }),{
   length: 208,
   selected: false,
 },{
   length: 246,
   selected: true,
 }];

  const filteredRails = smRailsFilterationForRemovalOf208Rail(rails,selected_rails, projectVersion);

  return filteredRails;
} 

export const railsSet2 = [{
  length: 132,
  selected: false,
}, {
  length: 168,
  selected: true,
},{
  length: 185,
  selected: true,
}, {
  length: 208,
  selected: false,
}, {
  length: 246,
  selected: true,
}];

const smLtRails = [{
  length: 168,
  selected: true,
},{
  length: 208,
  selected: false,
},{
  length: 246,
  selected: true,
}];

const oneRails = [{
  length: 192,
  selected: true,
}];

const smHdRails = [{
  length: 144,
  selected: false,
}, {
  length: 168,
  selected: true,
}, {
  length: 204,
  selected: false,
}, {
  length: 246,
  selected: true,
}];

const nxtRails = (projectVersion: string, is_nxt_tilt=false) => {
  if(apply96and185InchRail(projectVersion) && !is_nxt_tilt) {
    return [
      { length: 84, selected: false },
      { length: 96, selected: false },
      { length: 168, selected: false },
      { length: 185, selected: true }
    ];
  }
  else if(greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['nxt_84_inch_rail'])) {
    return [
      { length: 84, selected: false },
      { length: 168, selected: true }
    ];
  }
  else {
    return [{ length: 168, selected: true }];
  }
}

const ascenderRails = (projectVersion: string) => {
  return applyAdd185InchRail(projectVersion) ? [{length: 185, selected: true,}] : [{length: 181, selected: true,}];
  }

const smStandardRails = (projectVersion: string, selected_rails) => {  
  const{projectConfiguration:{railsProductId}} = state();
  const rails = applyAdd185InchRail(projectVersion) && [9,30].includes(railsProductId) ? [{
    length: 132,
    selected: false,
  }, {
    length: 168,
    selected: false,
  },{
    length: 185,
    selected: true,
  }, {
    length: 208,
    selected: false,
  }, {
    length: 246,
    selected: false,
  }]: [{
    length: 132,
    selected: false,
  }, {
    length: 168,
    selected: true,
  },{
    length: 185,
    selected: false,
  }, {
    length: 208,
    selected: false,
  }, {
    length: 246,
    selected: true,
  }];

  const filteredRails = smRailsFilterationForRemovalOf208Rail(rails,selected_rails, projectVersion);

  return filteredRails;

}

const gftRails = (projectVersion: string) => {
  return [
   ...(removeGFT166Rail(projectVersion) ? [{length: 168 ,selected: true}] : [{length: 166 ,selected: true}] ),
   {
    length: 246,
    selected: true,
  }];
}
const ulaRails = [];

function smRailsFilterationForRemovalOf208Rail(rails, selected_rails, projectVersion){
  const index = rails.findIndex(item => item.length === 208);
  const is208railselected = selected_rails ? selected_rails.filter((rail) => rail.length === 208 && rail.selected) : [];
  if (index !== -1 && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['remove_sm_208_inch_rail']) || !is208railselected.length) {
      rails.splice(index, 1);  // Removes the object with length 208
  }
  return rails;
}

export function getDefaultRailsList(productId: number, railFinish: string) {
  const {projectConfiguration:{projectVersion}, bom : {extraParameters}} = state()
  switch (productId) {
    case 2:
      return  _.cloneDeep(smHdRails);
    case 99:
    case 30:
    case 9:
      return _.cloneDeep(smStandardRails(projectVersion, extraParameters?.selections?.selected_rails));
    case 11:
      return  _.cloneDeep(smLtFlushRails(projectVersion, extraParameters?.selections?.selected_rails));
    case 31:
      return  _.cloneDeep(smLtRails);
    case 5:
      return _.cloneDeep(oneRails);
    case 19:
      return _.cloneDeep(gftRails(projectVersion));
    case 20:
      return _.cloneDeep(ulaRails);
    case 25:
      return _.cloneDeep(nxtRails(projectVersion, false));
    case 34:
      return _.cloneDeep(nxtRails(projectVersion, true));
    case 29:
    case 32:
      return _.cloneDeep(ascenderRails(projectVersion))
    default:
      return _.cloneDeep(railsSet2);
  }
}

