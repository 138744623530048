export const apiField = 'snow_is';

export const SNOW_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY = [0.8, 1.0, 1.1, 1.2]; //here index indicates risk_category - 1

interface SnowIsField {
  type: string;
  label: string;
  apiField: string;
  missing?: any;
  min?: number;
  max?: number;
  step?: number;
}

const config: SnowIsField = {
  type: 'Number',
  label: 'snow_is',
  apiField,
  missing: undefined,
  min: 0.8,
  max: 1.5,
  step: 0.1,
};

export default config;
