import RmRoofTypeTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/rmRoofTypeTooltip';
import { isRM10Evolution, isRM5, isRMGridFlex, isRmGridflex10 } from '__common/constants/products';
import { uAnchorAttachments } from '__common/utils/versionCompare/versionCompare';


export const apiField = 'roof_type';

const RM_ROOF_TYPE = [
  {
    value: '5',
    name: 'MINERAL CAP',
  },
  {
    value: '6',
    name: 'EPDM',
  },
  {
    value: '7',
    name: 'TPO',
  },
  {
    value: '8',
    name: 'PVC',
  },
]

const RM_IFI_ROOF_TYPE = [...RM_ROOF_TYPE].concat([
  {
    value: '13',
    name: 'FINE GRAVEL',
  },
  {
    value: '14',
    name: 'HONED CONCRETE',
  },
  {
    value: '15',
    name: 'PAINTED CONCRETE',
  },
  {
    value: '16',
    name: 'BROOMED CONCRETE',
  }
])

const OTHER_ROOF_TYPE = [
  {
    value: '9',
    name: 'OTHER',
  },
]

const U_ANCHOR_ATTACHMENTS = [
  { 
    value: '17',
    name : 'KEE'
  },
  { 
    value: '18',
    name :  'TPA'
  },
  {
    value: '19',
    name : 'APP'
  },
  {
    value: '20',
    name : 'SBS'
  },
  {
    value : '21',
    name  : 'ACRYLIC'
  },
  {
    value : '22',
    name  : 'COATED'
  }
]

function makeConfig(data) {
  return {
    type: 'Select',
    label: 'roofType',
    apiField: apiField,
    missing: undefined,
    tooltip: RmRoofTypeTooltip,
    data
  };
}

export default function getRMRoofType(productId: number, projectVersion: string) {
  if ((isRMGridFlex(productId) || isRmGridflex10(productId)) && !uAnchorAttachments(projectVersion))
    return makeConfig([...RM_IFI_ROOF_TYPE].concat(OTHER_ROOF_TYPE));

  const roofTypes = ((isRMGridFlex(productId) || isRmGridflex10(productId)) && uAnchorAttachments(projectVersion)) ? RM_IFI_ROOF_TYPE : RM_ROOF_TYPE;
  const filteredItems = roofTypes.filter(item => item.value !== '5');
  const uAnchorAttachmentTypes = [...filteredItems].concat(U_ANCHOR_ATTACHMENTS)
  return (uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId))) ? makeConfig([...uAnchorAttachmentTypes].concat(OTHER_ROOF_TYPE)) :  makeConfig([...RM_ROOF_TYPE].concat(OTHER_ROOF_TYPE));
}
