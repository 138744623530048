import * as React from 'react';
import SVG from 'react-inlinesvg';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { MOVE_ARRAY_MODE_DISABLE, MOVE_MAP_MODE_DISABLE, SET_DRAW_RUBBER_BAND, SHOW_CURSOR, TOGGLE_ATTACHMENTS_DISABLE, DISABLE_BAYS_CONTAINER } from '__editor/panelsEditor/actions';
import { TERMINATE_ADVANCE_ROOF_SELECTING, TURN_OFF_MEASUREMENT_TOOL } from '__editor/googleMapsRoofsSelector/googleMapsRoofsSelectorActions';
import {   RESTORE_PANELS_FOR_NO_MOVEMENT, UNBLOCK_CREATING_PANEL } from '../../panels/panelsActions';
import { state } from '__common/store';
import { isULA } from '__common/constants/products';

class Draw extends React.Component<drawComponentState, {}> {
  action = () => {
    const { dispatch } = this.props;
    const {projectConfiguration:{projectEnvConfig:{is_aurora_project}}} = state();
    dispatch(UNBLOCK_CREATING_PANEL());
    if(!is_aurora_project) dispatch(SET_DRAW_RUBBER_BAND());
    dispatch(SHOW_CURSOR());
    dispatch(MOVE_MAP_MODE_DISABLE());
    if(this.props.moveArrayEnabled) {
      dispatch(RESTORE_PANELS_FOR_NO_MOVEMENT());
      dispatch(MOVE_ARRAY_MODE_DISABLE());
    }
    dispatch(TERMINATE_ADVANCE_ROOF_SELECTING());
    dispatch(TOGGLE_ATTACHMENTS_DISABLE());
    dispatch(TURN_OFF_MEASUREMENT_TOOL());
    dispatch(DISABLE_BAYS_CONTAINER())
  }

  render() {
    const { mode } = this.props.rubberBand;
    const { moveMapEnabled, moveArrayEnabled, length, width, product } = this.props;
    return (
      <Button flat={true} onClick={this.action} className="roofs-selector-button" tooltipLabel="Add modules" disabled={isULA(product) && (length === null || width === null)}>
        <SVG className={mode === 'draw' &&  !(moveMapEnabled || moveArrayEnabled) ? 'roofs-selector-control-panel-active' : ''} src={require('assets/media/icons/icon_add_module.svg')} />
      </Button>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state: appState) {
  return {
    moveMapEnabled: state.background.moveMapMode,
    moveArrayEnabled: state.background.moveArrayMode,
    rubberBand: state.rubberBand,
    length: state.panels.desiredTableLength,
    width: state.panels.desiredTableWidth,
    product: state.projectConfiguration.productId,
  };
}

export default connect(mapStateToProps)(Draw);
