import { isRmGridflex10 } from '__common/constants/products';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import shortestBuildingLengthTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/shortestBuildingLengthTooltip';

export const apiField = 'shortest_building_length';

function makeConfig(label: string= 'shortestBuildingLength', min: number = 20, max: number = 2000) {
  return {
    type: 'Number',
    apiField,
    label,
    missing: undefined,
    tooltip: shortestBuildingLengthTooltip,
    min,
    max,
  }
};

const config = makeConfig();

export const shortestBuildingLengthMetric = makeConfig('shortestBuildingLengthMetric', 6.1, 609.6);

export const shortestBuildingLengthMetricAscender = makeConfig('shortestBuildingLengthMetric', 6.1, 60);

export const shortestBuildingLengthSMFamilyNonAscenderFlushMetric = makeConfig('shortestBuildingLengthMetric', 6.1, 609.6);
// for GF10 in metric
export const shortestBuildingLengthGF10Metric = makeConfig('shortestBuildingLengthMetric', 6.1, 914.4);
// for GF10 in feet
export const shortestBuildingLengthGF10 = makeConfig('shortestBuildingLength', 20, 3000);

export const shortestBuildingLengthField = (inputUnit: number, productId: number) => isMetricUnit(inputUnit) ? isRmGridflex10(productId) ? shortestBuildingLengthGF10Metric : shortestBuildingLengthMetric : isRmGridflex10(productId) ? shortestBuildingLengthGF10 : config;

export default config;