import * as React from 'react';
import autobind from 'autobind-decorator';
import SVG from 'react-inlinesvg';
import { REQUEST_ADD_PANELS } from 'actions';
import Button from 'react-md/lib/Buttons/Button';
import { connect } from 'react-redux';
import { getRoofEdgesCoords } from '__editor/panelsEditor/components/roofEdges/roofEdgesCollisions';
import { draw_double_panels_on_area } from '__editor/panelsEditor/components/panels/utils/draw_double_panels_on_area';
import { draw_on_area } from '__editor/panelsEditor/components/panels/utils/draw_on_area';
import { createPanel } from '__editor/panelsEditor/components/panels/utils/panelsManagment';
import nextUid from '__common/calculations/nextUid';
import { isAscender, isGFT, isULA } from '__common/constants/products';
import { drawGftPanelsInTables } from '__editor/panelsEditor/components/panels/utils/drawGftPanelsInTables';
import { drawUlaPanelsInTables } from '__editor/panelsEditor/components/panels/utils/drawUlaPanelsInTables';
import { drawAscenderPanelsInTables } from '__editor/panelsEditor/components/panels/utils/drawAscenderPanelsInTables';

type Props = {
  dispatch: Function,
  background: any,
  projectConfiguration: projectConfigurationState,
  length?: number,
  width?: number,
  product?: number
};

class Autopopulate extends React.Component<Props, {}> {
  @autobind
  populateRoof() {
    const { dispatch, projectConfiguration: { productId } } = this.props;
    const points = getRoofEdgesCoords();
    if (points !== null) {
      let panels;
      if (isGFT(productId)) {
        panels = drawGftPanelsInTables('SE', points, createPanel, nextUid());
      }else if (isAscender(productId)) {
        panels = drawAscenderPanelsInTables('SE', points, createPanel, nextUid());
      } else if(isULA(productId)) {
        panels = drawUlaPanelsInTables('SE', points, createPanel, nextUid());
      } else if (productId === 15) {
        panels = draw_double_panels_on_area('SE', points, createPanel, nextUid());
      } else {
        panels = draw_on_area('SE', points, createPanel, nextUid());
      }
      
      dispatch(REQUEST_ADD_PANELS(panels));
    }
  }

  render() {
    const { projectConfiguration: { productId }, length, width } = this.props;
    return (
      <Button flat={true} onClick={this.populateRoof} className="roofs-selector-button" tooltipLabel="Populate roof" disabled={isULA(productId) && (length === null || width === null)}>
        <SVG src={require('assets/media/icons/icon_fill_roof.svg')} />
      </Button>
    );
  }
}

function mapStateToProps(state: appState) {
  return {
    background: state.background,
    projectConfiguration: state.projectConfiguration,
    length: state.panels.desiredTableLength,
    width: state.panels.desiredTableWidth,
    product: state.projectConfiguration.productId,

  };
}

export default connect(mapStateToProps)(Autopopulate);
