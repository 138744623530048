import azimuth from 'projectDesign/components/projectConfiguration/fields/helioscope_azimuth.json';
import { ballastBlockWeightField } from 'projectDesign/components/projectConfiguration/fields/ballastBlockWeight';
import { ballastBlockHalfWeightField } from 'projectDesign/components/projectConfiguration/fields/ballastHalfBlockWeight';
import roundingHalfBlock from 'projectDesign/components/projectConfiguration/fields/roundingHalfBlock';
import { shortestBuildingLengthField } from 'projectDesign/components/projectConfiguration/fields/shortestBuildingLength';
import { longestBuildingLengthField } from 'projectDesign/components/projectConfiguration/fields/longestBuildingLength';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import { getElevationField } from 'projectDesign/components/projectConfiguration/fields/elevation';
import frameSizeUp from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_up.json';
import frameSizeWide from 'projectDesign/components/projectConfiguration/fields/helioscope_frame_size_wide.json';
import oldBuildingCodeMeanRecurrenceInterval from 'projectDesign/components/projectConfiguration/fields/meanRecurrenceInterval';
import asce716meanRecurrenceInterval from 'projectDesign/components/projectConfiguration/fields/asce716meanRecurrenceInterval';
import orientation from 'projectDesign/components/projectConfiguration/fields/helioscope_orientation.json';
import racking from 'projectDesign/components/projectConfiguration/fields/helioscope_racking.json';
import rm10ParapetHeight from 'projectDesign/components/projectConfiguration/fields/rm10ParapetHeight';
import rmIFIParapetHeight from 'projectDesign/components/projectConfiguration/fields/rmIFIParapetHeight';
import RMIFIsetBackDistance, { RMSetbackDistance, SetbackDistanceMetric } from 'projectDesign/components/projectConfiguration/fields/setBackDistance';
import rm5ParapetHeight from 'projectDesign/components/projectConfiguration/fields/rm5ParapetHeight';
import { rmDTbuildingHeight, rm10rm10evobuildingHeight, buildingHeightMetric } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import getRMRoofType from 'projectDesign/components/projectConfiguration/fields/rm5roofType';
import subcategory from 'projectDesign/components/projectConfiguration/fields/roofTypeSubCategoryField';
import getManufacturerType from 'projectDesign/components/projectConfiguration/fields/manufacturerField';
import getColourType from 'projectDesign/components/projectConfiguration/fields/colourField';
import getchoiceOfFastendType from 'projectDesign/components/projectConfiguration/fields/choiceOfFastend';
import rmDTParapetHeight from 'projectDesign/components/projectConfiguration/fields/rmDTparapetHeight';
import rmWindExposure from 'projectDesign/components/projectConfiguration/fields/rmWindExposure';
import rmRoofExposure from 'projectDesign/components/projectConfiguration/fields/rmRoofExposure';
import rowSpacing from 'projectDesign/components/projectConfiguration/fields/helioscope_row_spacing.json';
import { centralSupportForRM10, centralSupportForRM10evo } from 'projectDesign/components/projectConfiguration/fields/centralSupport';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import seismicS1 from 'projectDesign/components/projectConfiguration/fields/seismicS1';
import setSpacing from 'projectDesign/components/projectConfiguration/fields/helioscope_setback.json';
import { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import { getWindSpeedField } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import getAllowMechanicalAttachments from 'projectDesign/components/projectConfiguration/fields/allowMechanicalAttachments';
import nbccWindPressure from 'projectDesign/components/projectConfiguration/fields/nbccWindPressure';
import nbccCategory from 'projectDesign/components/projectConfiguration/fields/nbccCategory';
import nbccRoughness from 'projectDesign/components/projectConfiguration/fields/nbccRoughness';
import attachmentOptimizationCriteria, { AttachmentOptimization, getAttachmentOptimizationCriteriaField } from 'projectDesign/components/projectConfiguration/fields/attachmentOptimizationCriteria';
import totalWeightOnRoofLimit from 'projectDesign/components/projectConfiguration/fields/totalWeightOnRoofLimit';
import roofPsfLimit from 'projectDesign/components/projectConfiguration/fields/roofPsfLimit';
import attachmentType, { AttachmentType, attachmentTypeWithoutFlachlocrm, attachmentTypesWithoutUanchor, rm10AttachmentType, rm10AttachmentTypeWithoutFlachlocrm, rm10AttachmentTypeWithoutUanchor } from 'projectDesign/components/projectConfiguration/fields/attachmentType';
import psfControl, { rmPsfControl } from 'projectDesign/components/projectConfiguration/fields/psfControl';
import getSoilClassField from 'projectDesign/components/projectConfiguration/fields/soilClass';
import { upliftAllowableForCustom, upliftAllowableForFlashloc, upliftAllowableForOMG, upliftAllowableForUAnchor } from 'projectDesign/components/projectConfiguration/fields/upliftAllowable';
import { shearAllowableForCustom, shearAllowableForFlashloc, shearAllowableForOMG, shearAllowableForUAnchor } from 'projectDesign/components/projectConfiguration/fields/shearAllowable';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import { state } from '__common/store';
import {
  isRM10,
  isRM5,
  isRMDT,
  isRMFamily,
  isRMGridFlex,
  isRMIFIProduct,
  isRM10Evolution,
  isEcoFoot2Plus,
  isRM10orRM10Evo,
  isCanadianChangesProduct,
  isRmGridflex10
} from '__common/constants/products';
import {
  checkLongestBuildingLength,
  checkParapetHeight,
  checkRoofType,
  checkWindExposure,
  checkParapetHeightRmIFIEvo,
  checkFieldNotEmpty, checkSeismicS1, checkSeismicSs, checkMaxDownPointLoad,  checkBuildingHeight, checkShortestBuildingLength, checkWindDeflectors, checkSeismicSds, checkTornadoSpeed, checkSeismicSd1,
} from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { isASCE716or722BuildingCode } from '../projectConfiguration';
import { isASCE705, isASCE710, isASCE716, isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import riskCategory, { riskRMIToIIICategory } from '../fields/riskCategory';
import _, { isBoolean, isString } from 'lodash';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import centralRailsCount from '../fields/centralRailsCount';
import { mandatoryMidSupportRmGridflex } from '../fields/mandatorymidsupport';
import allowManualAttachments from '../fields/allowManualAttachments';
// import allowNorthBays from '../fields/allowNorthBays';
import allowSecondaryBallastTray from '../fields/allowSecondaryBallastTray';
import limitDownPointLoads from '../fields/limitDownPointLoads';
import allowNorthBays from '../fields/allowNorthBays';
import allowDualAttachments from '../fields/allowDualAttachments';
import fullyAdheredField from '../fields/fullyAdhered';
import seismicIpField from 'projectDesign/components/projectConfiguration/fields/seismicIp';
import snowIsField from 'projectDesign/components/projectConfiguration/fields/snowIs';
import windIwField from 'projectDesign/components/projectConfiguration/fields/windIw';

import velocityPressureExposureCoefficientKz from '../fields/velocityPressureExposureCoefficientKz';
import groundElevationFactorKe from '../fields/groundElevationFactorKe';
import windDirectionalityFactorKd from '../fields/windDirectionalityFactorKd';
import numericalCoefficient from '../fields/numericalCoefficient';
import designLifeFactorFc from '../fields/designLifeFactorFc';
import rmTopographicalFactorKzt from '../fields/rmTopographicalFactorKzt';
import { applyCentralSupportForRM5, applyRM5Revamp, applyShortestBuildingLength, applyEcoFoot2PlusRM10andEvoSetbackChanges, greaterThanEqualToProjectVersion, uAnchorAttachments, showLandScapeBlocksOrientation, showDesignLifeFactorFor705And710, uAnchorImprovements } from '__common/utils/versionCompare/versionCompare';
import windDeflectors, { windDeflectorGF10 }  from '../fields/windDeflectors';
import anchorType from '../fields/uAnchorType';
import moduleType from '../fields/moduleType';
import buildingStorey from '../fields/buildingStorey';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { deadLoadFactorInputField } from 'projectDesign/components/projectConfiguration/fields/rmDeadLoadFactor';
import { disableMandatoryMidSupportCondition } from '../utils/updateModuleDims';
import { VERSION_MAP } from '__common/utils/versionCompare/version_info';
import { buildingHeightCheck, riskCategoryCheckForTornadoSpeed } from '../constraints/constraints';
import { contains_all, isCanadianZipcode } from '../utils/validation';
import { maxDownPointLoadToBeAllowedField } from '../fields/maxDownPointLoadToBeAllowed';
import rainLoad from '../fields/rainLoad';
import { asce710716BuildingCode, asce716BuildingCode, asce710716722BuildingCode, asce722BuildingCodeRM5 } from '../fields/buildingCode';
import seismicSds from 'projectDesign/components/projectConfiguration/fields/seismicSds';
import seismicSd1 from 'projectDesign/components/projectConfiguration/fields/seismicSd1';
import tornadoSpeed, { metricTornadoSpeedField } from 'projectDesign/components/projectConfiguration/fields/tornadoSpeed';
import additionalRail from '../fields/additionalRail';
import blocksOrientation from '../fields/blocksOrientation';
import RmGridflex10ParapetHeight from '../fields/RmGridflex10ParapetHeight';
import addRoofPadsEverywhere from '../fields/roofPadsEveryWhereField';
import useFriction from '../fields/useFrictionField';
import { isUniracOrNuevoSolUser } from 'app/components/HeaderComponent/utils/getProducts';
import { extractUniqueColourValues, extractUniqueFastendValues, extractUniqueMfValues, filterByColour, filterByFast, filterByManufacturer, getDataBySubCategoryField, getDataByKey, u_anchor_roofs_list, } from '../utils/uAnchorAttachmentsFilters';
import { RmRoofType, UanchorSubCategoryType } from '../utils/constants';
import loadSharingType, { loadSharingOptions } from '../fields/loadSharingType';
import frictionFactor from '../fields/frictionFactor';
import thermalFactor from '../fields/thermalFactor';
import ul3741Required from '../fields/ul3741Required';
import { uAnchorRoof } from '../utils/updateProjectOption';

const DEAD_LOAD_FACTOR_MODIFICATION_VERSION_DATE = '05/26/2020';

const shouldShowAttachmentFields = (productId, allow_mechanical_attachments, is_half_block_allowed, allow_secondary_ballast_tray, attachment_optimization_criteria, attachment_type, roof_type, manufacturer, colour, sub_category, isStaffuser, projectVersion) => {

  if (!isRMIFIProduct(productId) && !isRmGridflex10(productId) && !isRM10(productId) && !isRM10Evolution(productId) && !(isRM5(productId) && applyRM5Revamp(projectVersion))) return {};
  return {
    shouldShowAllowManualAttachmentsField: ((isRMIFIProduct(productId) || (isRM10Evolution(productId) || isRM5(productId)) && applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion)) || isRmGridflex10(productId)) && isStaffuser && !!allow_mechanical_attachments,
    shouldShowAttachmentOptimizationCriteriaField: !!allow_mechanical_attachments,
    shouldShowAllowDualAttachmentsField: isStaffuser && !!allow_mechanical_attachments && is_half_block_allowed && allow_secondary_ballast_tray,
    shouldShowRoofPsfLimitField: !!allow_mechanical_attachments && attachment_optimization_criteria === AttachmentOptimization.PSF_LIMIT,
    shouldShowTotalWeightOnRoofLimitField: !!allow_mechanical_attachments && attachment_optimization_criteria === AttachmentOptimization.TOTAL_WEIGHT_ON_THE_ROOF,
    shouldShowAttachmentTypeField: !!allow_mechanical_attachments,
    shouldShowManufacturerField : !!allow_mechanical_attachments && (uAnchorImprovements(projectVersion) ? true : attachment_type === AttachmentType.U_ANCHOR_U2400) && roof_type && u_anchor_roofs_list.includes(parseInt(roof_type)),
    shouldShowColourField : !!allow_mechanical_attachments && (uAnchorImprovements(projectVersion) ? true : ((manufacturer != undefined) && attachment_type === AttachmentType.U_ANCHOR_U2400 && manufacturer)) && roof_type && u_anchor_roofs_list.includes(parseInt(roof_type)),
    shouldShowChoiceOfFastenedField : !!allow_mechanical_attachments && attachment_type === AttachmentType.U_ANCHOR_U2400 && (uAnchorImprovements(projectVersion) ? true : manufacturer) && roof_type && u_anchor_roofs_list.includes(parseInt(roof_type)),
    shouldShowPsfControl: !!allow_mechanical_attachments && attachment_optimization_criteria === AttachmentOptimization.PSF_LIMIT,
    shouldShowUpliftShearFlashloc: !!allow_mechanical_attachments && attachment_type === AttachmentType.UNIRAC_FLASHLOC_RM,
    shouldShowUpliftShearUAnchor: !!allow_mechanical_attachments && attachment_type === AttachmentType.U_ANCHOR_U2400,
    shouldShowUpliftShearCustom: !!allow_mechanical_attachments && attachment_type === AttachmentType.CUSTOM,
    shouldShowUpliftShearOMG: !!allow_mechanical_attachments && attachment_type === AttachmentType.OMG,
    shouldShowAnchorType: !uAnchorAttachments(projectVersion) && applyRM5Revamp(projectVersion) && !!allow_mechanical_attachments && attachment_type === AttachmentType.U_ANCHOR_U2400,
  };
};

const shouldShowModuleType = (deflectors) => {
  return deflectors == 3
}

const gridFlex10ProductId = 33;

const shouldShowAddRoofPadsEverywherCheckBox = (productsList, gridflex10ProductId) => {
    return productsList.includes(gridflex10ProductId);
}


export const isProjectOlderThanDeadLoadFactorModificationVersion = (project_created_date: string): boolean => {
  if (!project_created_date) return false;
  return new Date(project_created_date) < new Date(DEAD_LOAD_FACTOR_MODIFICATION_VERSION_DATE);
};


export default isHelioscope => {
  const {
    projectConfiguration: {
      projectEnvConfig: {
        wind_exposure,
        building_height,
        roof_type,
        use_friction,
        sub_category,
        manufacturer,
        colour,
        choice_of_fastend,
        parapet_height,
        parapet_height_input,
        building_length,
        shortest_building_length,
        seismic_ss,
        seismic_s1,
        seismic_sds,
        seismic_sd1,
        building_code,
        dead_load_factor_modification,
        created,
        allow_mechanical_attachments,
        attachment_optimization_criteria,
        roof_psf_limit,
        total_weight_on_roof_limit,
        attachment_type,
        risk_category,
        psf_control,
        is_half_block_allowed,
        uplift_allowable,
        shear_allowable,
        topographical_factor_kzt,
        wind_speed,
        tornado_speed,
        limit_down_point_loads,
        max_down_point_load_to_be_allowed,
        allow_secondary_ballast_tray,
        deflectors,
        anchor_type,
        zipcode,
        snow_load,
        rain_load,
        blocks_orientation,
        elevation: current_elevation,
        friction_factor
      },
      inputUnit,
      productId,
      projectVersion,
    },
    user: {
      isPrivilegedUser,
      isStaff,
      products,
      email,
    },
    moduleSelector : {
      modelData,
      selectedMfgId,
    }
  } = state();

  let Helioscope = undefined;
  let parapetHeight;
  let meanRecurrenceInterval;
  let setBackDistance = [];

  if (applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) || isRmGridflex10(productId)) {
    if (isMetricUnit(inputUnit)) {
      setBackDistance = [SetbackDistanceMetric]
    } else {
      setBackDistance = [RMSetbackDistance];
    }
  } else if (isRMIFIProduct(productId)) {
    setBackDistance = [RMIFIsetBackDistance];
  }

  const isLimitedOption = ((wind_exposure === 'D' || risk_category > 3 || wind_speed > 140) && !isProjectOlderThanDeadLoadFactorModificationVersion(created));
  const isLimitedForSpecialConditions = isLimitedOption || isASCE705(building_code);
  const deadLoadFactor = isASCE705(building_code) ? null : deadLoadFactorInputField(isLimitedOption, dead_load_factor_modification);

  let soilClass = null;
  if (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || isRmGridflex10(productId)) {
    soilClass = getSoilClassField(building_code, productId);
  }

  if (isASCE716or722BuildingCode(building_code) || isCanadianZipcode(zipcode)) {
    meanRecurrenceInterval = asce716meanRecurrenceInterval;
  } else {
    meanRecurrenceInterval = oldBuildingCodeMeanRecurrenceInterval;
  }

  if (isRM5(productId) ) {
    parapetHeight = rm5ParapetHeight(inputUnit);
  }

  const showNoDeflectorOption = !greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP["exculde_no_deflector_option_under_wind_deflectors"])

  if (isRM10(productId)) {
    parapetHeight = rm10ParapetHeight(inputUnit);
  }

  if (isRMDT(productId)) {
    parapetHeight = rmDTParapetHeight;
  }

  if (isRMIFIProduct(productId)) {
    parapetHeight = rmIFIParapetHeight();
  }

  if (isRM10Evolution(productId) ) {
    parapetHeight = rmIFIParapetHeight(inputUnit);
  }

  if (isRmGridflex10(productId) ) {
    parapetHeight = RmGridflex10ParapetHeight(inputUnit);
  }

  if (isHelioscope && isRM5(productId)) {
    Helioscope = {
      fields: [
        azimuth,
        frameSizeUp,
        frameSizeWide,
        orientation,
        racking,
        rowSpacing,
        setSpacing,
      ],
    };
  }
  else if (isHelioscope && isRM10(productId) || isRMDT(productId) || isRMGridFlex(productId) || isRM10Evolution(productId) || isEcoFoot2Plus(productId)) {
    Helioscope = {
      fields: [
        azimuth,
        frameSizeUp,
        frameSizeWide,
        orientation,
        racking,
        setSpacing,
      ],
    };
  }
  let rmRoofType = getRMRoofType(productId, projectVersion);
  let manufacturerType;
  let colourType;
  let choiceOfFastendType;
  let dontShowColourField = false;

  if((isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && uAnchorAttachments(projectVersion) && (uAnchorImprovements(projectVersion) ? true : attachment_type === AttachmentType.U_ANCHOR_U2400)){
      let uAnchorData = getDataByKey(roof_type);
      const filteredMfValues = extractUniqueMfValues(uAnchorData);
      manufacturerType = getManufacturerType(filteredMfValues);

      const filteredColourValues = extractUniqueColourValues(uAnchorData)
      colourType = getColourType(filteredColourValues)
      if (colourType.data.length == 1 && colourType.data[0].value == 'N'){
        uAnchorData = filterByColour(uAnchorData, 'N');
        dontShowColourField = true;
      }
      const filteredChoiceOfFastendValues = extractUniqueFastendValues(uAnchorData)
      choiceOfFastendType = getchoiceOfFastendType(projectVersion, filteredChoiceOfFastendValues)

  }
    

  const subCategoryField = subcategory(roof_type);

  const shortestBuildingLength = shortestBuildingLengthField(inputUnit, productId);

  const longestBuildingLength = longestBuildingLengthField(inputUnit, productId);

  const maxDownPointLoadToBeAllowed = maxDownPointLoadToBeAllowedField(inputUnit);

  const isFMGorDSA = [loadSharingOptions.fmg.value,loadSharingOptions.dsa.value].includes(state().projectConfiguration.projectEnvConfig?.load_sharing_type);

  if (isRMFamily(productId)) {
    rmWindExposure.missing = !checkWindExposure(wind_exposure);
    rmDTbuildingHeight.missing = !checkBuildingHeight(building_height * 1);
    rm10rm10evobuildingHeight.missing = !checkBuildingHeight(building_height * 1);
    rmRoofType.missing = !checkRoofType(roof_type * 1);
    parapetHeight.missing = isRMIFIProduct(productId) || isRM10Evolution(productId) || isRmGridflex10(productId) ? !checkParapetHeightRmIFIEvo(parapet_height_input, inputUnit) : !checkParapetHeight(parapet_height);
    longestBuildingLength.missing = !isRMIFIProduct(productId) && !checkLongestBuildingLength(building_length * 1);
    shortestBuildingLength.missing = !checkShortestBuildingLength(shortest_building_length * 1);
    windDeflectors().missing = applyRM5Revamp(projectVersion) ? !checkWindDeflectors(deflectors * 1): false;
    if (deadLoadFactor) deadLoadFactor.missing = !dead_load_factor_modification;
    maxDownPointLoadToBeAllowed.missing = limitDownPointLoads && !checkMaxDownPointLoad(max_down_point_load_to_be_allowed, false, inputUnit)
  }

  if((isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && uAnchorAttachments(projectVersion) && (uAnchorImprovements(projectVersion) ? true : attachment_type === AttachmentType.U_ANCHOR_U2400)){
    manufacturerType.missing = !checkFieldNotEmpty(manufacturer);
    colourType.missing = !checkFieldNotEmpty(colour);
    choiceOfFastendType.missing = !checkFieldNotEmpty(choice_of_fastend);
    if(roof_type == RmRoofType.SBS || roof_type == RmRoofType.COATED){
      subCategoryField.missing = !checkFieldNotEmpty(sub_category);
    }
  }

  const allowMechanicalAttachments = getAllowMechanicalAttachments(building_code, risk_category, productId, projectVersion);

  const {
    shouldShowAllowManualAttachmentsField,
    shouldShowAttachmentOptimizationCriteriaField,
    shouldShowAllowDualAttachmentsField,
    shouldShowRoofPsfLimitField,
    shouldShowTotalWeightOnRoofLimitField,
    shouldShowAttachmentTypeField,
    shouldShowManufacturerField,
    shouldShowColourField,
    shouldShowChoiceOfFastenedField,
    shouldShowPsfControl,
    shouldShowUpliftShearCustom,
    shouldShowUpliftShearFlashloc,
    shouldShowUpliftShearUAnchor,
    shouldShowUpliftShearOMG,
    shouldShowAnchorType
  } = shouldShowAttachmentFields(productId, allow_mechanical_attachments, is_half_block_allowed, allow_secondary_ballast_tray, attachment_optimization_criteria, attachment_type, roof_type, manufacturer, colour, sub_category,  isStaff, projectVersion);

  const upliftAllowableForUAnchorObj = upliftAllowableForUAnchor(productId, anchor_type, projectVersion, inputUnit, choice_of_fastend);
  const shearAllowableForUAnchorObj = shearAllowableForUAnchor(productId,  anchor_type, projectVersion, inputUnit, choice_of_fastend);
  const upliftAllowableForFlashlocObj = upliftAllowableForFlashloc(productId, inputUnit);
  const shearAllowableForFlashlocObj = shearAllowableForFlashloc(productId, inputUnit);
  const upliftAllowableForCustomObj = upliftAllowableForCustom(productId, inputUnit);
  const shearAllowableForCustomObj = shearAllowableForCustom(productId, inputUnit);
  const upliftAllowableForOMGObj = upliftAllowableForOMG(productId, inputUnit);
  const shearAllowableForOMGObj = shearAllowableForOMG(productId, inputUnit);
  const psfControlObj = isRM10(productId) || isRM10Evolution(productId) || isRM5(productId) || isRmGridflex10(productId) ? rmPsfControl : psfControl;

  if (isRMIFIProduct(productId) || isRmGridflex10(productId) || isRM10(productId) || isRM10Evolution(productId) || applyRM5Revamp(projectVersion)) {
    allowMechanicalAttachments.missing = !checkFieldNotEmpty(Number(allow_mechanical_attachments));
    attachmentOptimizationCriteria.missing = shouldShowAttachmentOptimizationCriteriaField && !checkFieldNotEmpty(attachment_optimization_criteria);
    roofPsfLimit.missing = shouldShowRoofPsfLimitField && !checkFieldNotEmpty(roof_psf_limit);
    totalWeightOnRoofLimit.missing = shouldShowTotalWeightOnRoofLimitField && !checkFieldNotEmpty(total_weight_on_roof_limit);
    attachmentType.missing = shouldShowAttachmentTypeField && !checkFieldNotEmpty(attachment_type);
    seismicS1.missing = !checkSeismicS1(seismic_s1);
    psfControlObj.missing = shouldShowPsfControl && !checkFieldNotEmpty(psf_control);
    upliftAllowableForUAnchorObj.missing = shouldShowUpliftShearUAnchor && !checkFieldNotEmpty(uplift_allowable);
    shearAllowableForUAnchorObj.missing = shouldShowUpliftShearUAnchor && !checkFieldNotEmpty(shear_allowable);
    upliftAllowableForFlashlocObj.missing = shouldShowUpliftShearFlashloc && !checkFieldNotEmpty(uplift_allowable);
    shearAllowableForFlashlocObj.missing = shouldShowUpliftShearFlashloc && !checkFieldNotEmpty(shear_allowable);
    upliftAllowableForCustomObj.missing = shouldShowUpliftShearCustom && !checkFieldNotEmpty(uplift_allowable);
    shearAllowableForCustomObj.missing = shouldShowUpliftShearCustom && !checkFieldNotEmpty(shear_allowable);
    upliftAllowableForOMGObj.missing = shouldShowUpliftShearOMG && !checkFieldNotEmpty(uplift_allowable);
    shearAllowableForOMGObj.missing = shouldShowUpliftShearOMG && !checkFieldNotEmpty(shear_allowable);
    topographicalFactorKzt.missing = !checkFieldNotEmpty(topographical_factor_kzt);
  }

  if(isCanadianChangesProduct(productId)) {
    rainLoad.missing = !checkFieldNotEmpty(rain_load);
    buildingHeightMetric.missing = !checkBuildingHeight(building_height * 1);
  }


  seismicSs.missing = !checkSeismicSs(seismic_ss);

  let buildingCode = asce716BuildingCode;
  if(isRM5(productId) || isRM10(productId) || isRMDT(productId))
    buildingCode = asce722BuildingCodeRM5;
  else if(isRM10Evolution(productId) || isRMGridFlex(productId) || isRmGridflex10(productId))
    buildingCode = asce710716722BuildingCode;
  else if (isRMIFIProduct(productId) || isRM10Evolution(productId) || isCanadianZipcode(zipcode))
    buildingCode = asce710716BuildingCode;

  const windSpeed = getWindSpeedField(inputUnit, state().projectConfiguration.projectEnvConfig?.load_sharing_type === loadSharingOptions.fmg.value);

  const elevation = getElevationField(inputUnit);

  const isCentralSupportRequiredForRm5 = applyCentralSupportForRM5(projectVersion)

  const buildingHeight = isMetricUnit(inputUnit) ? buildingHeightMetric : isRM10orRM10Evo(productId) || isRM5(productId) || isRMIFIProduct(productId) || isRmGridflex10(productId) ? rm10rm10evobuildingHeight : rmDTbuildingHeight;

  const snowLoad = getSnowLoadField(((isRMIFIProduct(productId) || isRmGridflex10(productId) || isRM5(productId) || isRM10orRM10Evo(productId)) && isPrivilegedUser) || isRMDT(productId), inputUnit);

  const ballastBlockHalfWeight = ballastBlockHalfWeightField(inputUnit);

  const rm5orRm10attachmentOptimizationCriteria = getAttachmentOptimizationCriteriaField(productId);
  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, null, elevation);

  const isHalfBlockAllowed = (is_half_block_allowed) => {
    return ((isString(is_half_block_allowed) && is_half_block_allowed === 'true') || (isBoolean(is_half_block_allowed) && is_half_block_allowed));
  };

  const frictionFactorField = frictionFactor(productId)

  const shouldNotShowFlashlocrm = (UanchorSubCategoryType.SILICONE_BASED == uAnchorRoof(roof_type, sub_category) && uAnchorAttachments(projectVersion))

  const tornadoSpeedField = isASCE722(building_code) && riskCategoryCheckForTornadoSpeed(risk_category) ? isMetricUnit(inputUnit) ? metricTornadoSpeedField : tornadoSpeed : [];
  const seismicSdsField = isASCE722(building_code) ? seismicSds : [];
  const seismicSd1Field = isASCE722(building_code) ? seismicSd1 : [];
  seismicSds.missing = !checkSeismicSds(seismic_sds);
  seismicSd1.missing = !checkSeismicSd1(seismic_sd1);
  tornadoSpeed.missing = !checkTornadoSpeed(tornado_speed);
  snowLoad.missing = !checkFieldNotEmpty(snow_load);
  elevation.missing = !checkFieldNotEmpty(current_elevation);
  riskCategory.missing = !checkFieldNotEmpty(risk_category);
  riskRMIToIIICategory.missing = !checkFieldNotEmpty(risk_category);
  windSpeed.missing = !checkFieldNotEmpty(wind_speed);
  if ((isRmGridflex10(productId) || isRMGridFlex(productId))){
    frictionFactorField.missing = !checkFieldNotEmpty(friction_factor)
  }
  
  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Helioscope,
    HelioscopeRoofPitchSelector: isRMGridFlex(productId),
    Client: {
      label: '',
      fields: [clientName, clientAddress],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        ...(isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) ? [nbccRoughness, nbccWindPressure, rainLoad] : [rmWindExposure, windSpeed]),
        elevation,
        ...(!isASCE716or722BuildingCode(building_code)?[meanRecurrenceInterval]:[]),
        snowLoad,
        seismicSs,
        ...(isRMIFIProduct(productId) || applyRM5Revamp(projectVersion) || applyEcoFoot2PlusRM10andEvoSetbackChanges(productId, projectVersion) || isRmGridflex10(productId)? [seismicS1] : []),
        seismicSdsField,
        seismicSd1Field,
        ...(isCanadianChangesProduct(productId) && isCanadianZipcode(zipcode) ? [nbccCategory] : isRMDT(productId) ? [riskRMIToIIICategory] : [riskCategory]),
        ...(isRMIFIProduct(productId) ? [soilClass] : []),
        ...((isStaff || isPrivilegedUser) && (isRM10(productId) || isRM10Evolution(productId) || isRM5(productId) || isRMDT(productId) || isRMGridFlex(productId) || isRmGridflex10(productId)) ? [deadLoadFactorInputField(isLimitedForSpecialConditions, dead_load_factor_modification)] : []),
        ...(isRMIFIProduct(productId) ? [topographicalFactorKzt] : []),
        tornadoSpeedField,
      ],
    },
    WindPressureInputs: {
      label: 'additionalUserInputsPage',
      fields: [
        ...(!isRMGridFlex(productId)?[velocityPressureExposureCoefficientKz,
        groundElevationFactorKe,
        windDirectionalityFactorKd,
        rmTopographicalFactorKzt,
        numericalCoefficient]:[]),
        ...(isASCE705(building_code) || isASCE710(building_code) || !showDesignLifeFactorFor705And710(projectVersion) ? [designLifeFactorFc] : []),
        ...((isRmGridflex10(productId) || isRMGridFlex(productId)) && isFMGorDSA ? [windIwField]:[]),
      ],
    },
    LoadSharing: {
      label: 'additionalUserInputsPage',
      fields: [
        loadSharingType,
        frictionFactorField,
        ...((isRmGridflex10(productId) || isRMGridFlex(productId)) && isFMGorDSA ? [fullyAdheredField]:[]),
      ],
    },
    SnowLoadInputs: {
      label: 'additionalUserInputsPage',
      fields: [
        ...(!isRMGridFlex(productId)?[rmRoofExposure]:[]),
        ...(isRmGridflex10(productId) || isRMGridFlex(productId)?[thermalFactor]:[]),
        ...((isRmGridflex10(productId) || isRMGridFlex(productId)) && isFMGorDSA ? [snowIsField]:[]),

      ]
    },
    ...(isRmGridflex10(productId) || (isRMGridFlex(productId) && isFMGorDSA)? {SeismicInputs:{
      label: 'additionalUserInputsPage',
      fields: [
        ...(!isRMGridFlex(productId)?[soilClass]:[]),
        ...((isRmGridflex10(productId) || isRMGridFlex(productId)) && isFMGorDSA ? [seismicIpField]:[]),
      ]
    }}:null),
    BuildingDescription: {
      label: 'Building description',
      fields: [
        buildingHeight,
        ...((applyRM5Revamp(projectVersion) || isRmGridflex10(productId) || isRM10orRM10Evo(productId) || isRMGridFlex(productId) || isRMDT(productId)) && isASCE716or722(building_code) && buildingHeightCheck(building_height, inputUnit) ? [buildingStorey] : []),
        rmRoofType,
        ...(uAnchorAttachments(projectVersion) && !uAnchorImprovements(projectVersion) && attachment_type === AttachmentType.U_ANCHOR_U2400 && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (roof_type == RmRoofType.SBS || roof_type == RmRoofType.COATED) ? [subCategoryField] : []),
        ...(shouldShowManufacturerField && uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) ? uAnchorImprovements(projectVersion) ? [manufacturerType] : (roof_type == RmRoofType.SBS || roof_type == RmRoofType.COATED) ? sub_category ? [manufacturerType] : [] : [manufacturerType] : []) ,
        ...(shouldShowColourField && uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (!(dontShowColourField)) ? [colourType] : [] ),
        ...(uAnchorAttachments(projectVersion) && uAnchorImprovements(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (roof_type == RmRoofType.SBS || roof_type == RmRoofType.COATED)  ? [subCategoryField] : []),
        ...(isRM10Evolution(productId) ? [allowNorthBays] : []),
        parapetHeight,
        ...setBackDistance,
        ...(!isRMIFIProduct(productId) ? [longestBuildingLength] : []),
        ...(applyShortestBuildingLength(projectVersion) ? [shortestBuildingLength] : []),
      ],
    },
    BlockWeight: {
      label: '',
      fields: [
        ...(isRMGridFlex(productId) || isRmGridflex10(productId) || isRM10(productId) || isRM10Evolution(productId) || applyRM5Revamp(projectVersion) ? [roundingHalfBlock] : []),
        ballastBlockWeightField(inputUnit),
        ...(isHalfBlockAllowed(is_half_block_allowed) ? (isRMGridFlex(productId) ? [ballastBlockHalfWeight, allowSecondaryBallastTray] : [ballastBlockHalfWeight]) : []),
        ...(isRmGridflex10(productId) && showLandScapeBlocksOrientation(projectVersion)?[blocksOrientation]:[]),
        ...(isRmGridflex10(productId) && !(contains_all(modelData.name, "Q,PEAK,DUO,XL,G11") || [375,164,93,247,281,8,362,341].includes(selectedMfgId)) ? [ ul3741Required ] : []),
        ...(isRMGridFlex(productId) || isRmGridflex10(productId) ? isRmGridflex10(productId)? [] :[centralRailsCount] : []),
        ...(applyRM5Revamp(projectVersion)  ? [windDeflectors(showNoDeflectorOption)] : []),
        ...(applyRM5Revamp(projectVersion) && shouldShowModuleType(deflectors) ? [moduleType] : [])
      ],
    },

    ...(isRM10(productId) || isRM10Evolution(productId) ? {
      centralSupportForRM: (isRM10(productId) ? centralSupportForRM10 : centralSupportForRM10evo)
      ,
    } : {}),

    ...((isRM10Evolution(productId) || isRM10(productId) || isRM5(productId)) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['use_unfactored_attachment_capacity_for_seismic_calcs_all_RMs']) && (shouldShowAddRoofPadsEverywherCheckBox(products, gridFlex10ProductId) || isUniracOrNuevoSolUser(email))? {
      useFriction : useFriction,
      } : {}),

    ...((isRM10Evolution(productId) || isRM10(productId)) && greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['use_unfactored_attachment_capacity_for_seismic_calcs_all_RMs']) && use_friction && (shouldShowAddRoofPadsEverywherCheckBox(products, gridFlex10ProductId) || isUniracOrNuevoSolUser(email))? {
      roofPadsEverywhere : addRoofPadsEverywhere,} : {}),

    ...(isRM5(productId) && isCentralSupportRequiredForRm5 ? {
      centralSupportForRM: greaterThanEqualToProjectVersion(projectVersion, VERSION_MAP['rm5_mid_supports']) ? centralSupportForRM10evo : centralSupportForRM10
      ,
    } : {}), ...(isRMGridFlex(productId) ? {
      mandatorymidsupport: (mandatoryMidSupportRmGridflex(disableMandatoryMidSupportCondition(modelData.height, snow_load))),
    } : {}),

    ...(isRMGridFlex(productId) || isRmGridflex10(productId) || isRM10(productId) || isRM10Evolution(productId) || isRMDT(productId) || applyRM5Revamp(projectVersion) ? {
      DownPointLoad: {
        label: 'Down Point Load',
        fields: [
          limitDownPointLoads,
          ...(limit_down_point_loads ? [maxDownPointLoadToBeAllowed] : []),
          
        ]
      },
    } : {}),

    ...(isRMGridFlex(productId) || isRmGridflex10(productId) || isRM10(productId) || isRM10Evolution(productId) || applyRM5Revamp(projectVersion) ? {
      AttachmentInputs: {
        label: 'Attachment Inputs',
        fields: [
          allowMechanicalAttachments,
          ...(shouldShowAllowManualAttachmentsField ? [allowManualAttachments] : []),
          ...(shouldShowAllowDualAttachmentsField && !(isRmGridflex10(productId)) ? [allowDualAttachments] : []),
          ...(shouldShowAttachmentOptimizationCriteriaField ? [isRM5(productId) || isRM10(productId) || isRM10Evolution(productId) || isRmGridflex10(productId) ? rm5orRm10attachmentOptimizationCriteria : attachmentOptimizationCriteria] : []),
          ...(shouldShowPsfControl ? [psfControlObj] : []),
          ...(shouldShowRoofPsfLimitField ? [roofPsfLimit] : []),
          ...(shouldShowTotalWeightOnRoofLimitField ? [totalWeightOnRoofLimit] : []),
          ...(shouldShowAttachmentTypeField ? [((isRM5(productId) || isRM10Evolution(productId) || isRmGridflex10(productId)) && uAnchorAttachments(projectVersion) && roof_type && !u_anchor_roofs_list.includes(parseInt(roof_type))) ? rm10AttachmentTypeWithoutUanchor : (isRM5(productId) || isRM10Evolution(productId) || isRmGridflex10(productId)) ? 
                  shouldNotShowFlashlocrm ? rm10AttachmentTypeWithoutFlachlocrm :rm10AttachmentType : (isRMGridFlex(productId) && uAnchorAttachments(projectVersion) && roof_type && !u_anchor_roofs_list.includes(parseInt(roof_type))) ? attachmentTypesWithoutUanchor :  shouldNotShowFlashlocrm ? attachmentTypeWithoutFlachlocrm : attachmentType] : []),
          // ...(shouldShowColourField && uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (!([21, 22].includes(roof_type))) ? [colourType] : [] ),
          // ...(shouldShowChoiceOfFastenedField && uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (([21, 22].includes(roof_type) && !colour) || colour) ? [choiceOfFastendType] : []),
          ...(shouldShowChoiceOfFastenedField && uAnchorAttachments(projectVersion) && (isRM10Evolution(productId)||isRM5(productId)||isRMGridFlex(productId)||isRmGridflex10(productId)) && (uAnchorImprovements(projectVersion) ? true : ((dontShowColourField && !colour) || colour)) ? [choiceOfFastendType] : []),
          ...(shouldShowAnchorType ? [anchorType] : []),
          ...(shouldShowUpliftShearFlashloc ? [upliftAllowableForFlashlocObj, shearAllowableForFlashlocObj] : []),
          ...(shouldShowUpliftShearUAnchor ? [upliftAllowableForUAnchorObj, shearAllowableForUAnchorObj] : []),
          ...(shouldShowUpliftShearCustom ? [upliftAllowableForCustomObj, shearAllowableForCustomObj] : []),
          ...(shouldShowUpliftShearOMG ? [upliftAllowableForOMGObj, shearAllowableForOMGObj] : []),
        ],
      },
    } : {}),
  };

  return cfg;
};

