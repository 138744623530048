import { options as railArrangementTypeOptions } from 'projectDesign/components/projectConfiguration/fields/railArrangementType';

interface GftRailInstallationGuide {
  value: string;
  name: string;
  options: number[];
}

export const SharedRail: GftRailInstallationGuide = {
  value: 'GFT Shared Rail Installation Guide',
  name: 'Shared Rail',
  options: [railArrangementTypeOptions.shared3rail.value, railArrangementTypeOptions._5rail.value, railArrangementTypeOptions._6rail.value],
};

export const _4Rail: GftRailInstallationGuide = {
  value: 'GFT Installation Guide',
  name: '4 rail',
  options: [railArrangementTypeOptions._4rail.value],
};
