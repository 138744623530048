import { allRoofsHasPanels, allRoofsHaveRoofPitch, hasNoRoofs, getTotalModules } from '../../../../googleMapsRoofsSelector/components/drawingManager/drawingManagerHelper';
import { AUTO_SAVE_PROJECT, AUTO_SAVE_PROJECT_FINISHED, SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH } from './saveLoadProjectActions';
import { OPEN_CONFIRM_CLEAR_ARRAYS_MODAL, OPEN_DRAWER_PAGE, SAVE_PROJECT, SET_GONE_THROUGH_THE_PANEL_EDITOR_FOR_EXPOSURE_CORRECT_CALCULATIONS, SET_RAILS_PRODUCT_ID, UPDATE_PANELS } from 'actions';
import { dispatch, state } from '__common/store';
import { metersToInches, degreesToRadians, inchesToMeters } from 'maths';
import { panelsEditorEnabled, deactivePanelsEditor } from '__editor/panelsEditor/panelsEditorHelper';
import { radiansToDegrees } from '__common/calculations/radiansToDegrees';
import { validateProject } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import {
  getProductName,
  isRM10,
  isRM10Evolution,
  products,
  isRMGridFlex,
  isRMIFIProduct,
  isEcoFoot2Plus,
  isRM5,
  isNxtHorizon,
  isSolarMount,
  isGroundProduct,
  isSMTiltPR,
  isAscender,
  isAdditionalUserInputEnabledProduct,
  isRM10orRM10Evo,
  isRmGridflex10,
  isRMDT,
  isSMTilt,
  isNxtTilt,
} from '__common/constants/products';
import { shouldAutoSave } from './utils/autosave';
import { getCurrentTime } from '__common/calculations/currentTime';
import _, { isBoolean, isString, toNumber } from 'lodash';
import { convertToSaveData } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/panelsSaveData';
import { ROOF_PITCH } from '__editor/panelsEditor/components/tiltedRoof/roofPitch';
import { getRealRoofEdgesBoundsMemoized as getRealRoofEdgesBounds } from '__editor/panelsEditor/components/background/background';
import { polygonIsClockwise } from '__editor/panelsEditor/components/leadEdge/utils/backgroundRoofEdgesRotation';
import { isBlankMap, isGoogleMap } from '__common/constants/map';
import { isASCE716or722, isASCE722 } from '__common/constants/buildingCodes';
import { buildingHeightCheck } from 'projectDesign/components/projectConfiguration/constraints/constraints';
import { canGotoPanelsEditor, goToPanelsEditor } from '__editor/components/roofsSelector/components/roofsSelectorDrawingManager/roofsSelectorDrawingManager';
import { showErrorAlert } from '__common/modules/alerts';
import selectedArea from '__editor/googleMapsRoofsSelector/models/selectedArea';
import { isModuleSelectorValid } from '__common/components/moduleSelector/moduleSelectorHelper';
import RoofShape from '__editor/bingMapsRoofsSelector/models/selectedArea';
import { PRODUCTS_WITH_STRUCTURE_TILT } from 'projectDesign/components/projectConfiguration/utils/constants';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';
import { getBingMap } from '__editor/bingMapsRoofsSelector/components/bingMapsRoofsSelectorMap/bingMapsRoofsSelectorMap';
import Leaflet, { LatLngLiteral } from 'leaflet';
import { addAttachmentsOnCornersAndEdgesForEachArray } from '__editor/panelsEditor/components/panels/utils/dividePanelsintoSubArrays';


let autoSaveTimeout: ReturnType<typeof setTimeout>;

let allowToPanelEditor = true;

export const saveProject = (productId: string, projectId?: string, withBom?: boolean, clickingContinueFromDesignpage?: boolean) => {
  const { settings: { calculateZoneClassication }, background: { panelEditorActive }, drawingManager: { roofs }, projectConfiguration: { verifiedFlyouts, gone_through_panel_editor, gone_through_panel_editor_for_exposure_correct_calculations, projectEnvConfig: { created_user_is_staff, helioscope_id }, projectConfigurated }, user: { isStaff } } = state()
  const { projectConfiguration : { projectEnvConfig } } = state()
  if (isAdditionalUserInputEnabledProduct(products[productId]) && (projectConfigurated ? !(created_user_is_staff && isStaff) : !isStaff)) {
    delete verifiedFlyouts['additional-user-inputs']
  }
  for (const [key, value] of Object.entries(verifiedFlyouts)) {
    if (!value) {
      setTimeout(() => {
        const missingEl = document.getElementById('save-changes') as HTMLInputElement
        if (missingEl) {
          missingEl.scrollIntoView({ behavior: 'smooth' })
          missingEl.classList.add('missing-element-highlight')
        }
      }, 20);
      dispatch(OPEN_DRAWER_PAGE(key))
      return
    }
  }
  const roofOrsite = isGroundProduct(products[productId]) ? "Site" : "Roof";
  if (!canGotoPanelsEditor()) {
    // checking whether can goto panels before we show the user popup to enter panels editor in case of missing roof panels.
    return;
  }

  if (hasNoRoofs()) {
    const errorMessage = `
    Your Project has no roof area(s)! Please add atleast one to run the project.
    `;
    return showErrorAlert(errorMessage);
  }

  const RoofAreaNotSeen = Object.values(roofs).filter(roof => roof.seen === false);
  const roofAreaWithZeroPanels = helioscope_id ? Object.values(roofs).filter(roof => !roof.panels.length).map(roof=>roof.roofName) : null;

  const PanelEditorNotOpenForExposureRecaluculation = Object.values(roofs).filter(roof => roof.isPanelEditorOpenForExposureRecaluculation === false);

 if(PanelEditorNotOpenForExposureRecaluculation.length === 0){
    dispatch(SET_GONE_THROUGH_THE_PANEL_EDITOR_FOR_EXPOSURE_CORRECT_CALCULATIONS(true));
 }

  // show popup even when clicked continue inside panel editor
  if (!allRoofsHaveRoofPitch()) {
    return dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        `Roof Pitch Missing.`,
        `To add roof pitch please click on "+" symbol on the ${roofOrsite} area or click on YES, CONTINUE`,
        () => {
          goToPanelsEditorFromContinue();
        },
      ),
    );
  };

  // show user popup if user click on continue button when empty roof/site area has been present.
  if (!allRoofsHasPanels() && clickingContinueFromDesignpage && allowToPanelEditor) {
    if (helioscope_id && roofAreaWithZeroPanels) {
      return dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          ``,
          `Seems like the roof areas: ${roofAreaWithZeroPanels.join()} are empty. Please insert the panels or delete those roof areas`,
          null,
          null,
          null,
          null,
          'OK'
        ),
      );
    }
    else {
      return dispatch(
        OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
          `Add panels. `,
          `To add panels
          please click on "+" symbol on the ${roofOrsite} area
             or
          click on YES, CONTINUE`,
          () => {
            goToPanelsEditorFromContinue();
          },
        ),
      );
    }

  };

  if (helioscope_id && !gone_through_panel_editor && !panelEditorActive) {

    return dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        ``,
        `Please verify the roof areas
        click on OK for redirecting to the panel editor`,
        () => {
          goToPanelsEditorFromContinueForHelioscope(RoofAreaNotSeen[0].id);
        },
      ),
    );

  }

  if (!(isAdditionalUserInputEnabledProduct(products[productId]) && isStaff)) {
    delete verifiedFlyouts['additional-user-inputs']
  }
  for (const [key, value] of Object.entries(verifiedFlyouts)) {
    if (!value.checked || value.error) {
      setTimeout(() => {
        const missingEl = document.getElementById('save-changes') as HTMLInputElement
        missingEl.scrollIntoView({ behavior: 'smooth' })
        missingEl.classList.add('missing-element-highlight')
      }, 20);
      dispatch(OPEN_DRAWER_PAGE(key))
      return
    }
    for (const [key, value] of Object.entries(verifiedFlyouts)) {
      if (key !== 'undefined' && (!value.checked || value.error)) {
        setTimeout(() => {
          const missingEl = document.getElementById('save-changes') as HTMLInputElement
          if (missingEl) {
            missingEl.scrollIntoView({ behavior: 'smooth' })
            missingEl.classList.add('missing-element-highlight')
          }
        }, 20);
        dispatch(OPEN_DRAWER_PAGE(key))
        return
      }
    }
    if (calculateZoneClassication) {
      return
    }

    if (!validateProject(products[productId], true)) {
      return false;
    }

    // show warning popup if empty roof/site area has been drawn.
  if (!allRoofsHasPanels()) {
    allowToPanelEditor = false;
    return dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        `Empty ${roofOrsite} Area !`,
        `At least one of the ${roofOrsite} areas is empty! Add panels to save the Empty ${roofOrsite} area. Do you want to continue?
      The empty ${roofOrsite} area will be deleted automatically if you continue.`,
        () => {
          continueToSaveProject(productId, projectId, withBom);
        },
        () => {
          modifyAllowToPanelEditor(true);
        },
      ),
    );
  }

  if (!gone_through_panel_editor_for_exposure_correct_calculations && !panelEditorActive && PanelEditorNotOpenForExposureRecaluculation.length){
    return dispatch(
      OPEN_CONFIRM_CLEAR_ARRAYS_MODAL(
        ``,
        `Please open panel editor 
        for all Roof areas to recalculate exposure. 
        Click YES to redirect to panel editor`,
        () => {
          goToPanelsEditorFromContinueForHelioscope(PanelEditorNotOpenForExposureRecaluculation[0].id);
        },
      ),
    );
    
  }

    if (!validatePanelQtyFromPermitForm(projectEnvConfig, roofs)) {
      return
    }
    
    return continueToSaveProject(productId, projectId, withBom);
  };
}
export const continueToSaveProject = (productId: string, projectId?: string, withBom?: boolean) => {
  const { saveLoadProject: { autoSaving } } = state();
  modifyAllowToPanelEditor(true)
  if (autoSaving) {
    dispatch(SAVE_PROJECT_STARTED_BEFORE_AUTO_SAVE_FINISH({ isSaveProjectPending: true }));
    return false;
  }
  const savingData = parseDataForSaving(products[productId], projectId);

  if (savingData && validateDataForSaving(savingData)) {
    dispatch(SAVE_PROJECT(savingData, productId, projectId, withBom));
    dispatch(AUTO_SAVE_PROJECT_FINISHED());
  }
};

export function goToPanelsEditorFromContinue(){
  const { drawingManager : {roofs}, roofsSelector : {mapType}, projectConfiguration: {productId}} = state();
  if (roofs && Object.keys(roofs).length > 0){
    let roofPitchOrStructureTiltKey = 'roofPitch'
    if (PRODUCTS_WITH_STRUCTURE_TILT.includes(productId)){
      roofPitchOrStructureTiltKey = 'structureTilt'
    }
    const withoutPanelsRoofId = Object.keys(roofs).find(roofId => !(roofs[roofId].hasOwnProperty('panels'))|| roofs[roofId].panels?.length === 0 || !roofs[roofId][roofPitchOrStructureTiltKey] || roofs[roofId][roofPitchOrStructureTiltKey]==="---");
    const coords  = roofs[withoutPanelsRoofId].coords;
    const roofid = toNumber(withoutPanelsRoofId)

    const getRoofInfo = (mapType: string): { roofCenter, roofId } => {
      let roof;
      if (isGoogleMap(mapType)) {
        roof = selectedArea(coords, roofid);
        return { roofCenter: roof.overlay, roofId: roof.id }
      }
      else {
        roof = RoofShape(coords, roofid);
        return { roofCenter: roof.overlay.getBounds().getCenter(), roofId: roof.id }
      }
    }

    const { roofCenter, roofId } = getRoofInfo(mapType);
    if (isModuleSelectorValid()) {
      goToPanelsEditor(roofCenter, roofId);
    }
    return;
  }
}

export function goToPanelsEditorFromContinueForHelioscope(roofIdNo: number){
  const { drawingManager : {roofs}, roofsSelector : {mapType}} = state();
  if (roofs && Object.keys(roofs).length > 0){
    
    const coords  = roofs[roofIdNo].coords;
    const roofid = toNumber(roofIdNo);

  const getRoofInfo = (mapType: string) : {roofCenter, roofId} => {
    let roof;
    if (isGoogleMap(mapType)) {
      roof = selectedArea(coords, roofid);
      return {roofCenter: roof.overlay, roofId: roof.id}
    }
    else {
      roof = RoofShape(coords, roofid);
      return {roofCenter: roof.overlay.getBounds().getCenter(), roofId: roof.id}
    }   
  }

  const {roofCenter, roofId} = getRoofInfo(mapType);
  if (isModuleSelectorValid()) {
    goToPanelsEditor(roofCenter, roofId);
  }
  return;
  }
}

export const modifyAllowToPanelEditor = (modify: boolean) => {
  allowToPanelEditor = modify;
}

export const autoSaveProject = (productId: string, projectId?: string) => {
  clearTimeout(autoSaveTimeout);

  const { projectConfiguration : { projectEnvConfig }, drawingManager : { roofs } } = state()

  if (!validatePanelQtyFromPermitForm(projectEnvConfig, roofs)) {
    return
  }

  if (!validateProject(products[productId], false) || !allRoofsHasPanels() || !allRoofsHaveRoofPitch()) {
    return false;
  }

  autoSaveTimeout = setTimeout(
    () => {
      try {
        if (shouldAutoSave()) {
          const savingData = parseDataForSaving(products[productId], projectId);
          if (savingData && validateDataForSaving(savingData)) {
            dispatch(AUTO_SAVE_PROJECT(savingData, productId, projectId));
          }
        }
      } catch { }

      clearTimeout(autoSaveTimeout);
      autoSaveTimeout = undefined;
    },
    2000,
  );
};

export const clearAutoSaveProjectTimeout = () => {
  clearTimeout(autoSaveTimeout);
};

export const arePanelsEmptyInSelectedRoofArea = (panels) => {
  return !panels?.panels?.length;
}

export function savePanelEditorAndSaveProject() {
  const { panels, projectConfiguration: { productId, projectId, gone_through_panel_editor, projectEnvConfig: {helioscope_id, is_aurora_project} }, drawingManager: { roofs }, background: {selectedRoofId} } = state();
  const RoofAreaNotSeen = Object.values(roofs).filter(roof => roof.seen === false).map(roof=>{return roof.id});
  if (panelsEditorEnabled()) {
    if(helioscope_id && !gone_through_panel_editor){
      if(RoofAreaNotSeen.includes(selectedRoofId)){
        const errorMessage = arePanelsEmptyInSelectedRoofArea(panels)?
        `Seems like roof area is empty. Kindly go back and delete the roof area called ${roofs[selectedRoofId].roofName}`:
        `Please delete the ${roofs[selectedRoofId].overflowingSetbackLine.numberOfOverflowedPanels} overflowed modules in the roof area called ${roofs[selectedRoofId].roofName}`;
        return showErrorAlert(errorMessage);
      }
      else{
        goToPanelsEditorFromContinueForHelioscope(RoofAreaNotSeen[0]);
      }
    }
    else{
      return deactivePanelsEditor(() => {
        if (!validateProject(productId, true)) {
          return;
        }
  
        if (roofs && Object.keys(roofs).length) {
          saveProject(getProductName(productId), projectId);
        }
      });  
    }
    
  }

  if (!validateProject(productId, true)) {
    return;
  }

  if (roofs && Object.keys(roofs).length && gone_through_panel_editor) {
    if (is_aurora_project) {
      saveProject(getProductName(productId), projectId, false, true);
    }else {
      saveProject(getProductName(productId), projectId);
    }
  }
}

export function clearAutoSaveTimeout() {
  clearTimeout(autoSaveTimeout);
}

function validateDataForSaving(savingData) {
  return savingData.roofs && savingData.project_configuration;
}

export function getRoofCoords(shape: any): google.maps.LatLngLiteral[] {
  const shapeLength = shape.getPath().length;
  const cords = [];
  for (let index = 0; index < shapeLength; index++) {
    const cord = shape.getPath().getAt(index);
    cords.push({
      lat: cord.lat(),
      lng: cord.lng(),
    });
  }
  return cords;
}

function parsePanels(panels: panelInState[], productId: number, buildingCode: number, roofPitch: number, panelBayMapping?:panelBayMapping): panelsSaveData[] {
  return panels.reduce<panelsSaveData[]|any>(
    (acc, panel: panelInState) => {
      const panelToSave = convertToSaveData(panel, productId, buildingCode, roofPitch, panelBayMapping?.get(panel.id));
      return [...acc, panelToSave];
    },
    []);
}

function getLeadEdge(roofId: number | string): any {
  const { leadEdgeRoofSelector } = state();
  return leadEdgeRoofSelector.leadEdges[roofId];
}

function parseRoofs(roofs: { [roofId: string]: drawingManagerRoof } | null, productId: number, buildingCode: number, mapType: string): parsedRoofs[] {
  const { projectConfiguration: { projectEnvConfig: { tilt }, projectVersion }, user: { isStaff }, settings: { rowSpacing, columnSpacing } } = state()
  if (roofs !== null) {
    return Object.keys(roofs).map((roofId: string) => {
      const roof = roofs[roofId];

      if (isSMTiltPR(productId)) {
        if (roof.structureTilt === "5" || roof.structureTilt === "15") {
          roof.rowSpacing = inchesToMeters(8)
        } else if (roof.structureTilt === "10") {
          roof.rowSpacing = inchesToMeters(12)
        }
      }

      if (!roof.panels || roof.panels.length === 0) {
        return undefined;
      }
      if ((isRMGridFlex(productId) || isRmGridflex10(productId)) && !state().projectConfiguration.projectEnvConfig?.fully_adhered && state().projectConfiguration.projectEnvConfig?.allow_manual_attachments){
        const [rowSpacingPx, columnSpacingPx] = [roof.rowSpacing/roof.metersPerPixel, columnSpacing/roof.metersPerPixel];
        roof.panels = addAttachmentsOnCornersAndEdgesForEachArray(roof.panels, rowSpacingPx, columnSpacingPx, state().projectConfiguration.projectEnvConfig?.load_sharing_type);
      }
      const roofPitch = roof.roofPitch ?
        isRMGridFlex(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRM10orRM10Evo(productId) || (isSMTilt(productId) && adjustableTiltSystem(projectVersion)) || isNxtTilt(productId) ?
          roof.roofPitch === '---' || roof.roofPitch === 'null' ? 
            0 : roof.roofPitch
          : ROOF_PITCH[roof.roofPitch]
        : 0;

      const { coords, marker, zoom, roofEdgesPixiCords, bgScale, blank_map_building_length, blank_map_building_width, panelsRotationDegrees, lowEdgeToRoof, panelBayMapping } = roof;

      let parsedRoof: parsedRoofs = {
        /** when you change model of what's stored in the backend update the version number */
        roofCoords: coords,
        panels: parsePanels(roof.panels, productId, buildingCode, roofPitch, panelBayMapping??new Map()),
        bgRotation: roof.bgRotation,
        bgOffset: roof.bgOffset,
        leadEdge: getLeadEdge(roofId),
        panelsModel: roof.panelsModel,
        pixelPerMeter: 1 / roof.metersPerPixel,
        roofName: roof.roofName,
        marker: roof.marker,
        zoom: roof.zoom,
        row_spacing_in: getRowSpacing(productId, roof.rowSpacing),
        table_row_spacing_in: getTableRowSpacing(roof.tableRowSpacing),
        id: roof.id,
        schemaVersion: isRMGridFlex(productId) ? 2 : roof.schemaVersion,
      };

      if (isRMIFIProduct(productId) || isRM10(productId) || isRM10Evolution(productId) || isNxtHorizon(productId) || isSolarMount(productId) || isSMTiltPR(productId) || isAscender(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRmGridflex10(productId) ) {
        let roofCoordsPixels = [];
        if (isBlankMap(mapType) && roofEdgesPixiCords) {
          roofCoordsPixels = roofEdgesPixiCords.map(roofPoint => ({
            x: roofPoint.x * bgScale.x,
            y: roofPoint.y * bgScale.y,
          }));
          parsedRoof = { ...parsedRoof, blank_map_building_length, blank_map_building_width, bgScale };
        } else if (!isBlankMap(mapType)) {
          const rotatedPoly = getRealRoofEdgesBounds({ roofEdges: coords, roofCenter: marker, bgOffSet: { x: roof.bgOffset.x, y: roof.bgOffset.y }, zoom, rotationRadians: degreesToRadians(roof.bgRotation), roofPitch: null, productId, tilt }, false, true);
          roofCoordsPixels = rotatedPoly.points;
        }

        if (isRM10Evolution(productId)) {
          parsedRoof = { ...parsedRoof, panelsRotationDegrees };
        }

        if (isAscender(productId)) {
          parsedRoof = { ...parsedRoof, lowEdgeToRoof };
        }

        // for the PIXI canvas the Y axis increases top to bottom, hence reversing y axis
        let transformedRoofCoordsPixels = roofCoordsPixels.map(({ x, y }) => {
          return { x, y: -y };
        });

        if (polygonIsClockwise(transformedRoofCoordsPixels)) {
          transformedRoofCoordsPixels = transformedRoofCoordsPixels.reverse();
        }
        parsedRoof.roofCoordsPixels = transformedRoofCoordsPixels;
      }

      if (!parsedRoof.row_spacing_in || !roof.rowSpacing || isRM10(productId) || isRM10Evolution(productId)) {
        delete parsedRoof.row_spacing_in;
      }

      if (roof.exposureRecaluclationHash) {
        parsedRoof.exposureRecaluclationHash = roof.exposureRecaluclationHash;
      }

      if (isSMTiltPR(productId)) {
        parsedRoof.structureTilt = roof.structureTilt;
      }
      if (roof.roofPitch) {
        if (isRMGridFlex(productId) || isEcoFoot2Plus(productId) || isRM5(productId) || isRM10orRM10Evo(productId) ||  isRmGridflex10(productId) || (isSMTilt(productId) && adjustableTiltSystem(projectVersion)) || isNxtTilt(productId)) {
          parsedRoof.roofPitch =  roof.roofPitch === '---' || roof.roofPitch === 'null' ? 0 : Number(roof.roofPitch);
        } else {
          const [val1, val2] = roof.roofPitch.split('/').map(val => Number(val));
          const roofPitchRadians = Math.atan(val1 / val2);
          parsedRoof.roofPitch = Math.round(radiansToDegrees(roofPitchRadians));
        }
      } else if (isRM5(productId) || isRM10orRM10Evo(productId)) {
        parsedRoof.roofPitch = 0
      }
      else {
        delete parsedRoof.roofPitch;
      }

      const roofObstructions = getRoofObstructions(Number(roofId));

      if (roofObstructions) {
        parsedRoof.obstructions = roofObstructions;
      }

      return parsedRoof;
    }).filter(roof => roof !== undefined);
  }
}

function getRowSpacing(productId: number, rowSpacing: number) {

  if (isRMGridFlex(productId) || isRM5(productId) || isRmGridflex10(productId)) {  // these products have multiple options for row spacing
    return Number(metersToInches(rowSpacing).toFixed(1));
  }
  if (isSMTiltPR(productId)) {
    return Number(metersToInches(rowSpacing).toFixed(2));
  }
  return Number(metersToInches(state().settings.rowSpacing).toFixed(2));
}

function getTableRowSpacing(tableRowSpacing: number) {
  if (tableRowSpacing) {
    return Number(metersToInches(tableRowSpacing).toFixed(2));
  }
  return tableRowSpacing;
}

function parseModelData(projectEnvConfig, modelData: modelData, selectedMfgId, selectedModelId) {
  return {
    ...projectEnvConfig,
    model_id: selectedModelId || modelData.id,
    module_id: selectedMfgId || modelData.module,
    module_manufacturer: projectEnvConfig.is_aurora_project ? projectEnvConfig.external_mfg :  null,
    module_model: projectEnvConfig.is_aurora_project ? projectEnvConfig.external_model : null,
    module_watts: modelData.watts,
    module_width: modelData.width,
    module_length: modelData.height,
    module_thickness: modelData.thickness,
    module_weight: modelData.weight
  };
}

function parseDataForSaving(productId: number, projectId: string): sfmDataForProjectSaving {
  const { roofsSelector: { mapType }, projectConfiguration: { projectName, railsProductId, inputUnit, projectConfigurated, verifiedFlyouts, projectVersion }, user: { isStaff } } = state();
  const { drawingManager: { roofs }, moduleSelector: { modelData, selectedMfgId, selectedModelId }, settings: { rowSpacing, columnSpacing }, roofsSelectorMap: { location } } = state();

  let projectEnvConfig = _.cloneDeep(state().projectConfiguration.projectEnvConfig);

  projectEnvConfig.product_id = productId;

  if (rowSpacing) {
    projectEnvConfig.row_spacing = Number(metersToInches(rowSpacing).toFixed(4));

    if (projectEnvConfig.row_spacing === 11) {
      projectEnvConfig.row_spacing = '11.0';
    }
  }

  if (projectEnvConfig.pro_clamps === 1) {
    projectEnvConfig.pro_clamps = true;
  }

  if ((isString(projectEnvConfig.is_half_block_allowed) && projectEnvConfig.is_half_block_allowed === 'true')
    || (isBoolean(projectEnvConfig.is_half_block_allowed) && projectEnvConfig.is_half_block_allowed)) {
    projectEnvConfig.is_half_block_allowed = true;
  } else {
    projectEnvConfig.is_half_block_allowed = false;
  }

  if (projectEnvConfig.pro_clamps === 0) {
    projectEnvConfig.pro_clamps = false;
  }

  if (projectEnvConfig.env_factors_for === null) {
    projectEnvConfig.env_factors_for = '';
  }

  if (columnSpacing) {
    projectEnvConfig.column_spacing = Number(metersToInches(columnSpacing).toFixed(4));
  }

  if (!projectEnvConfig.client_address) {
    projectEnvConfig.client_address = location;
  }

  if ((isString(projectEnvConfig.ns_diagnol_brace) && projectEnvConfig.ns_diagnol_brace === 'true')
    || (isBoolean(projectEnvConfig.ns_diagnol_brace) && projectEnvConfig.ns_diagnol_brace)) {
    projectEnvConfig.ns_diagnol_brace = true;
  } else {
    projectEnvConfig.ns_diagnol_brace = false;
  }

  projectEnvConfig.custom_title = projectName;

  if (projectEnvConfig.deflectors && (!isRM5(productId) && !isRmGridflex10(productId))) {
    // coerce to No deflectors case for now
    projectEnvConfig.deflectors = 0;
  }

  projectEnvConfig.productId = projectEnvConfig.product_id;

  projectEnvConfig.map_type = parseMapType(mapType);
  projectEnvConfig = parseModelData(projectEnvConfig, modelData, selectedMfgId, selectedModelId);
  projectEnvConfig.location = location;
  if (railsProductId !== null) {
    projectEnvConfig.product_id = railsProductId || projectEnvConfig.productId;
    if (projectEnvConfig.product_id === 99) {
      projectEnvConfig.product_id = 9;
    }
    if(projectEnvConfig.adjustable_tilt_system && projectEnvConfig.adjustable_tilt_system === 34){
      projectEnvConfig.product_id = 34;
      dispatch(SET_RAILS_PRODUCT_ID(34))
    }
  }

  if (projectEnvConfig.preferred_span === null) {
    delete projectEnvConfig.preferred_span;
  }

  const { building_height, building_code } = projectEnvConfig;
  if(!isASCE716or722(building_code) || !buildingHeightCheck(building_height, inputUnit)){
    delete projectEnvConfig?.building_height_greater_than_6_storey;
  }

  if(!isASCE722(building_code)){
    delete projectEnvConfig.seismic_sds;
    delete projectEnvConfig.seismic_sd1;
    delete projectEnvConfig.tornado_speed;
    delete projectEnvConfig.long_transition_periods_tl;
    delete projectEnvConfig.longest_row_length;
    delete projectEnvConfig.shortest_row_length;
  }

  if(isASCE722(building_code)) {
    if(isNxtHorizon(productId) || isSolarMount(productId) || isSMTiltPR(productId)) {
      delete projectEnvConfig.seismic_sd1;
      delete projectEnvConfig.seismic_s1;
    }
    if(isRMDT(productId)) {
      delete projectEnvConfig.seismic_s1;
    }
  }

  const parsedRoofs = parseRoofs(roofs, productId, projectEnvConfig.building_code, mapType);

  projectEnvConfig.input_unit = inputUnit;

  const project_configuration = parseProjectConfig(projectEnvConfig);
  project_configuration['verified_flyouts'] = verifiedFlyouts
  if (projectVersion) {
    project_configuration['version'] = projectVersion
  }

  if (!projectId || projectId.length === 0) {
    project_configuration.created_user_time = getCurrentTime();
  } else {
    delete project_configuration.created_user_time;
  }
  const additional_user_inputs = [
    'velocity_pressure_exposure_coefficient_kz',
    'ground_elevation_factor_ke',
    'wind_directionality_factor_kd',
    'topographical_factor_kzt',
    'roof_exposure',
    'soil_class',
    'numberical_coefficient',
    'design_life_factor_fc',
    'thermal_factor',
    'friction_factor',
    'load_sharing_type',
    'fully_adhered',
    'seismic_ip',
    'snow_is',
    'wind_iw',
  ]
  const { 
    velocity_pressure_exposure_coefficient_kz, 
    ground_elevation_factor_ke, 
    wind_directionality_factor_kd, 
    topographical_factor_kzt, 
    roof_exposure, soil_class, 
    numberical_coefficient, 
    design_life_factor_fc, 
    thermal_factor,
    friction_factor,
    load_sharing_type,
    created_user_is_staff,
    fully_adhered,
    seismic_ip,
    snow_is,
    wind_iw} = project_configuration;
  if ((isAdditionalUserInputEnabledProduct(productId)) && ((!projectConfigurated && isStaff) || created_user_is_staff)) {
    additional_user_inputs.forEach(input => {
      if (!(['topographical_factor_kzt','soil_class'].includes(input) && isRMGridFlex(productId)))
      delete project_configuration[input];
    });
    return {
      project_configuration,
      roofs: parsedRoofs,
      additional_user_inputs: { 
        velocity_pressure_exposure_coefficient_kz, 
        ground_elevation_factor_ke, 
        wind_directionality_factor_kd, 
        topographical_factor_kzt, 
        roof_exposure, soil_class, 
        numberical_coefficient, 
        design_life_factor_fc,
        thermal_factor,
        friction_factor,
        load_sharing_type,
        fully_adhered,
        seismic_ip,
        snow_is,
        wind_iw,
       },
    };
  } else {
    return {
      project_configuration,
      roofs: parsedRoofs,
    };
  }
}

function getRoofObstructions(roofId: number) {
  const { obstructions: { obstructions } } = state();

  if (obstructions && obstructions[roofId] && Object.keys(obstructions[roofId]).length) {
    return obstructions[roofId];
  }
}

function parseMapType(mapType: string) {
  const GOOGLE_MAPS = 1;
  const BING_MAPS = 2;
  const WHITE_MAPS = 3;

  switch (mapType) {
    case 'google':
      return GOOGLE_MAPS;
    case 'bing':
      return BING_MAPS;
    case 'white':
      return WHITE_MAPS;
    default:
      GOOGLE_MAPS;
  }
}

export function parseProjectConfig(projectEnvConfig: projectEnvConfig) {

  if (projectEnvConfig.product) {
    delete projectEnvConfig.product;
  }

  if (projectEnvConfig.building_code) {
    projectEnvConfig.building_code = projectEnvConfig.building_code * 1;
  }

  if (projectEnvConfig.building_height) {
    projectEnvConfig.building_height = projectEnvConfig.building_height * 1;
  }

  if (projectEnvConfig.elevation !== undefined) {
    projectEnvConfig.elevation = projectEnvConfig.elevation * 1;
  }

  if (projectEnvConfig.parapet_height_input !== undefined) {
    projectEnvConfig.parapet_height_input = projectEnvConfig.parapet_height_input * 1;
  }

  if (projectEnvConfig.mean_recurrence_interval) {
    projectEnvConfig.mean_recurrence_interval = projectEnvConfig.mean_recurrence_interval * 1;
  }

  if (projectEnvConfig.roof_type) {
    projectEnvConfig.roof_type = projectEnvConfig.roof_type * 1;
  }

  if (projectEnvConfig.seismic_ss) {
    projectEnvConfig.seismic_ss = projectEnvConfig.seismic_ss * 1;
  }

  if (projectEnvConfig.seismic_s1) {
    projectEnvConfig.seismic_s1 = projectEnvConfig.seismic_s1 * 1;
  }
 
  if (projectEnvConfig.seismic_sds) {
    projectEnvConfig.seismic_sds = projectEnvConfig.seismic_sds * 1;
  }

  if (projectEnvConfig.seismic_sd1) {
    projectEnvConfig.seismic_sd1 = projectEnvConfig.seismic_sd1 * 1;
  }

  if (projectEnvConfig.tornado_speed) {
    projectEnvConfig.tornado_speed = projectEnvConfig.tornado_speed * 1;
  }

  if (projectEnvConfig.long_transition_periods_tl) {
    projectEnvConfig.long_transition_periods_tl = projectEnvConfig.long_transition_periods_tl * 1;
  }

  if (projectEnvConfig.longest_row_length) {
    projectEnvConfig.longest_row_length = parseFloat((projectEnvConfig.longest_row_length * 1).toFixed(2));
  }

  if (projectEnvConfig.shortest_row_length) {
    projectEnvConfig.shortest_row_length = parseFloat((projectEnvConfig.shortest_row_length * 1).toFixed(2));
  }

  if (projectEnvConfig.seismic_sds) {
    projectEnvConfig.seismic_sds = projectEnvConfig.seismic_sds * 1;
  }

  if (projectEnvConfig.seismic_sd1) {
    projectEnvConfig.seismic_sd1 = projectEnvConfig.seismic_sd1 * 1;
  }

  if (projectEnvConfig.tornado_speed) {
    projectEnvConfig.tornado_speed = projectEnvConfig.tornado_speed * 1;
  }

  if (projectEnvConfig.snow_load) {
    projectEnvConfig.snow_load = projectEnvConfig.snow_load * 1;
  }

  if (projectEnvConfig.wind_speed) {
    projectEnvConfig.wind_speed = projectEnvConfig.wind_speed * 1;
  }

  if (projectEnvConfig.preferred_span) {
    projectEnvConfig.preferred_span = Number(projectEnvConfig.preferred_span) * 1;
  }

  if (projectEnvConfig.building_length) {
    projectEnvConfig.building_length = projectEnvConfig.building_length * 1;
  }

  if (!isNaN(projectEnvConfig.ice_thickness) && projectEnvConfig.ice_thickness !== null) {
    projectEnvConfig.ice_thickness = Math.floor(projectEnvConfig.ice_thickness * 100) / 100;
  }

  if (!isNaN(projectEnvConfig.wind_on_ice) && projectEnvConfig.wind_on_ice !== null) {
    projectEnvConfig.wind_on_ice = Math.floor(projectEnvConfig.wind_on_ice * 100) / 100;
  }

  if (!isNaN(projectEnvConfig.front_edge_height) && projectEnvConfig.front_edge_height !== null) {
    projectEnvConfig.front_edge_height = Math.floor(projectEnvConfig.front_edge_height * 100) / 100;
  }

  if (!isNaN(projectEnvConfig.foundation_diameter) && projectEnvConfig.foundation_diameter !== null) {
    projectEnvConfig.foundation_diameter = projectEnvConfig.foundation_diameter * 1;
  }

  if (!isNaN(projectEnvConfig.parapet_height) && projectEnvConfig.parapet_height !== null) {
    projectEnvConfig.parapet_height = projectEnvConfig.parapet_height * 1;
  }

  if (!isNaN(projectEnvConfig.parapet_height_input) && projectEnvConfig.parapet_height_input !== null) {
    projectEnvConfig.parapet_height_input = projectEnvConfig.parapet_height_input * 1;
  }

  if (!isNaN(Number(projectEnvConfig.allow_north_bays)) && projectEnvConfig.allow_north_bays !== null) {
    projectEnvConfig.allow_north_bays = projectEnvConfig.allow_north_bays * 1;
  }

  if (!isNaN(Number(projectEnvConfig.central_support)) && projectEnvConfig.central_support !== null) {
    projectEnvConfig.central_support = projectEnvConfig.central_support * 1;
  }

  if(!isNaN(Number(projectEnvConfig.add_roof_pads_everywhere)) && projectEnvConfig.add_roof_pads_everywhere !== null){
    projectEnvConfig.add_roof_pads_everywhere = projectEnvConfig.add_roof_pads_everywhere *1;
  }

  if(!isNaN(Number(projectEnvConfig.use_friction)) && projectEnvConfig.use_friction !== null){
    projectEnvConfig.use_friction = projectEnvConfig.use_friction *1;
  }

  if (projectEnvConfig.mandatory_mid_support !== null) {
    projectEnvConfig.mandatory_mid_support = projectEnvConfig.mandatory_mid_support * 1;
  }

  if (!isNaN(Number(projectEnvConfig.allow_mechanical_attachments)) && projectEnvConfig.allow_mechanical_attachments !== null) {
    projectEnvConfig.allow_mechanical_attachments = projectEnvConfig.allow_mechanical_attachments * 1;
  }

  if (!isNaN(projectEnvConfig.total_weight_on_roof_limit) && projectEnvConfig.total_weight_on_roof_limit !== null) {
    projectEnvConfig.total_weight_on_roof_limit = Math.floor(projectEnvConfig.total_weight_on_roof_limit);
  }

  if (!isNaN(projectEnvConfig.roof_psf_limit) && projectEnvConfig.roof_psf_limit !== null) {
    projectEnvConfig.roof_psf_limit = Math.floor(projectEnvConfig.roof_psf_limit * 100) / 100;
  }

  if (!isNaN(projectEnvConfig.uplift_allowable) && projectEnvConfig.uplift_allowable !== null) {
    projectEnvConfig.uplift_allowable = projectEnvConfig.uplift_allowable * 1;
  }

  if (!isNaN(projectEnvConfig.shear_allowable) && projectEnvConfig.shear_allowable !== null) {
    projectEnvConfig.shear_allowable = projectEnvConfig.shear_allowable * 1;
  }

  if (!isNaN(projectEnvConfig.allow_manual_attachments) && projectEnvConfig.allow_manual_attachments !== null) {
    projectEnvConfig.allow_manual_attachments = projectEnvConfig.allow_manual_attachments * 1;
  }

  if (!isNaN(projectEnvConfig.limit_down_point_loads) && projectEnvConfig.limit_down_point_loads !== null) {
    projectEnvConfig.limit_down_point_loads = projectEnvConfig.limit_down_point_loads * 1;
  }

  if (!isNaN(projectEnvConfig.max_down_point_load_to_be_allowed) && projectEnvConfig.max_down_point_load_to_be_allowed !== null) {
    projectEnvConfig.max_down_point_load_to_be_allowed = projectEnvConfig.max_down_point_load_to_be_allowed * 1;
  }

  return projectEnvConfig;
}

export function validatePanelQtyFromPermitForm(projectEnvConfig, roofs) {
  if (projectEnvConfig.hasOwnProperty('planset_configuration') && projectEnvConfig['planset_configuration']['panel_quantity'] !== getTotalModules(roofs)) {
    showErrorAlert(`Please verify the panel quantity from the intake form with the drawn modules.`)
    return false
  }
  return true
}

