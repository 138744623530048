import { ReportProblemActionTypes } from 'actionsConstants';
import { TypeOfSupport } from './constants';

export function SHOW_REPORT_PROBLEM_FORM() {
  return {
    type: ReportProblemActionTypes.SHOW_REPORT_PROBLEM_FORM,
  };
}

export function HIDE_REPORT_PROBLEM_FORM() {
  return {
    type: ReportProblemActionTypes.HIDE_REPORT_PROBLEM_FORM,
  };
}

export function SEND_REPORT_PROBLEM_FORM(body: { name?: string, email?: string, description?: string, qr?: string, project_links?: string, file?: File, captcha?: string, contact_support_category?: boolean }) {
  return {
    type: ReportProblemActionTypes.SEND_REPORT_PROBLEM_FORM,
    payload: {
      body,
    },
  };
}

export function CLEAR_REPORT_PROBLEM_ERRORS() {
  return {
    type: ReportProblemActionTypes.CLEAR_REPORT_PROBLEM_ERRORS,
  };
}

export function SUCCESS_SEND_REPORT_PROBLEM_FORM() {
  return {
    type: ReportProblemActionTypes.SUCCESS_SEND_REPORT_PROBLEM_FORM,
  };
}

export function ERROR_SEND_REPORT_PROBLEM_FORM(errors: { response: {[field: string]: string} }) {
  return {
    type: ReportProblemActionTypes.ERROR_SEND_REPORT_PROBLEM_FORM,
    payload: {
      errors: errors.response,
    },
  };
}

export function START_REPORT_FORM_LOADING() {
  return {
    type: ReportProblemActionTypes.START_REPORT_FORM_LOADING,
  };
}

export function RESET_PROBLEM_FORM() {
  return {
    type: ReportProblemActionTypes.RESET_PROBLEM_FORM,
  };
}

export function SET_PROBLEM_FORM_DATA(field: string, value: string | string[] | File) {
  return {
    type: ReportProblemActionTypes.SET_PROBLEM_FORM_DATA,
    payload: {
      field,
      value,
    },
  };
}

export function SHOW_IMAGE(enable:boolean, fileName:string) {
  return {
    type: ReportProblemActionTypes.SHOW_IMAGE,
    payload: {
      enable,
      fileName
    },
  };
}

export function SELECT_TYPE_OF_CONTACT_SUPPORT(contact_support_category: TypeOfSupport) {
  return {
    type: ReportProblemActionTypes.SELECT_TYPE_OF_CONTACT_SUPPORT,
    payload: {
      contact_support_category,
    },
  };
}

export function SET_TICKET_ID(data) {
  const {ticket_id} = data;
  return {
    type: ReportProblemActionTypes.SET_TICKET_ID,
    payload: {
      ticket_id,
    },
  };
}