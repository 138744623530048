import { isGFT, isSolarMount, isULA, isSMTilt, isNxtHorizon, isSMTiltPR, isAscender, isNxtTilt, isSM2Standard, isSMTiltPRRegular, products } from "__common/constants/products";
import { showErrorAlert } from "__common/modules/alerts";
import { state } from "__common/store";
import { applyAdd185InchRail } from "__common/utils/versionCompare/versionCompare";

export function getRailsUrl(projectId: string, rails: railsList, clampsChoice: boolean|number, selectOnlySMHDRails?: boolean): string {
  const { bom: { productId } ,projectConfiguration : { projectEnvConfig : { mid_clamps_choices , end_clamps_choices ,tilt,clamps_choices }, railsProductId } } = state();
  const railsQuery = createRailsGetQueryString(rails);
  const standardClampsQuery = createClampsQueryString(clampsChoice);
  const smMidAndEndClampsQuery = ((isSolarMount(productId) && tilt === null)|| (isNxtHorizon(productId)) || (isNxtTilt(productId)) || isSMTiltPR(productId))?createClampQueryStringForSMFlush(mid_clamps_choices,end_clamps_choices):createClampQueryStringForSMFlush(clamps_choices,clamps_choices);
  const nhMidAndEndClampsQuery = createClampQueryStringForNxt(mid_clamps_choices,end_clamps_choices);
  let query = railsQuery;
  
  if (!isGFT(productId)){
    query = [railsQuery, standardClampsQuery].join('&');
  }

  if(isULA(productId)){
    query = `smhd=${selectOnlySMHDRails ? 1 : 0}`;
    return `api/v1/rails/${projectId}/?${query}`;
  }

  if (isSolarMount(productId) || isSMTilt(productId) || isNxtHorizon(productId) || isNxtTilt(productId) || isSMTiltPR(productId) ||  isAscender(productId)  ) {
    if(isSolarMount(productId) && [products.solarmount_lt].includes(railsProductId)){
      const targetedRailIndex = 3;
      query = [queryStringModificationForSmltRails(railsQuery, targetedRailIndex), smMidAndEndClampsQuery].join('&');
    }
    else{
      query = [railsQuery, smMidAndEndClampsQuery].join('&');
    }
  }
  
  if (isNxtHorizon(productId) || isNxtTilt(productId)) {
    query = [railsQuery, nhMidAndEndClampsQuery].join('&');
  }

  return `api/v1/rails/${projectId}/?${query}`;
}

function queryStringModificationForSmltRails(railsQuery, targetedRailIndex){
  let params = new URLSearchParams(railsQuery);
  
  const targetedRail = params.get(`rail${targetedRailIndex}`);

  if (targetedRail === "246") {
      params.delete(`rail${targetedRailIndex}`); 
      params.append(`rail${targetedRailIndex + 1}`, "246"); 
  } 

  const modifiedQueryString = params.toString();
  return modifiedQueryString;

}

function createRailsGetQueryString(rails: railsList) {
  const{projectConfiguration:{projectVersion, productId, railsProductId}}=state();
  
  if(((isSM2Standard(productId) || isSMTiltPRRegular(productId) || isSMTilt(productId))) && [9,30].includes(railsProductId)){
    let gg=[];
    let selectedRailsList = rails.map((rail, index)=>({ ...rail, index })).filter((rail) => rail.selected);
    
    const railsTrial = [...selectedRailsList].reverse(); // or array.slice().reverse()
    const finalisedSelectedRails = railsTrial.map((rail)=>{
      if(rail.index === 4 || gg.includes(rail.index)){
        rail.index -= 1;
        gg.push(rail.index);
      }
      return {...rail, index: rail.index};
    }).reverse();

    return finalisedSelectedRails.map((rail) => `rail${rail.index + 1}=${rail.length}`)
  .join('&');
  }

  return rails
  // assign index to be able to use it after filtering out
  .map((rail, index) => ({ ...rail, index }))
  .filter((rail) => rail.selected)
  .map((rail) => `rail${rail.index + 1}=${rail.length}`)
  .join('&');
}

function createClampsQueryString(clampsChoice: boolean|number): string {
  return `standard=${clampsChoice? Number(clampsChoice) : 0}`; 
}

function createClampQueryStringForSMFlush(midClampsChoices : any, endClampsChoices : any): string {
  return `mid_clamps=${midClampsChoices? Number(midClampsChoices) : 1}&end_clamps=${endClampsChoices? Number(endClampsChoices) : 1}`;
}

function createClampQueryStringForNxt(midClampsChoices : any, endClampsChoices : any): string {
  return `mid_clamps=${midClampsChoices? Number(midClampsChoices) : 4}&end_clamps=${endClampsChoices? Number(endClampsChoices) : 4}`;
}

export function deleteLastOccurrence(objects) {
  for (let i = objects.length - 1; i >= 0; i--) {
    if (objects[i].index === 0 && objects[i].selected === false) {
      objects.splice(i, 1);
      break;  // Exit the loop after deleting the last occurrence
    }
  }
  const resultant_object = objects.map(obj=> ({length: obj.length, selected: obj.selected}));
  return resultant_object;
}
