import * as React from 'react';
import ArrayDimensions from 'projectDesign/components/arrayDimensions';
import CustomName from '__common/components/CustomName';
import Drawer from '__common/components/Drawer/Drawer';
import RoofPitchSelector from 'projectDesign/components/RoofPitchSelector';
import RoofZonesAutofill from 'projectDesign/components/RoofZonesAutofill';
import RowSpacing from 'projectDesign/components/RowSpacing';
import SetArrayTransparent from 'projectDesign/components/SetArrayTransparent';
import DesiredTableSize from 'projectDesign/components/DesiredTableSize';
import SVG from 'react-inlinesvg';
import { connect } from 'react-redux';
import { products, isGFT, isULA, isRM5,} from '__common/constants/products';
import { SET_ARRAY_NAME } from 'actions';
import ObstructionsComponent from '../obstructions/ObstructionsComponent';
import { roofHasObstructions } from '../obstructions/obstructions';
import RoofZonesComponent from '../roofZones/roofZonesComponent';
import RecheckExposureButton from 'projectDesign/components/RecheckExposureButton';
import DesiredTableSizeUla from 'projectDesign/components/DesiredTableSizeUla';
import BuildingLengthWidth from 'projectDesign/components/BuildingLengthWidth';
import TableRowSpacingGFT from 'projectDesign/components/TableRowSpacingGFT';
import StructureTypeChange from 'projectDesign/components/StructureTypeChange';
import StructureTypeSpanTilt from 'projectDesign/components/StructureTypeSpanTilt';
import StructureTypeTilt from 'projectDesign/components/StructureTypeTilt';
import LowEdgeToRoof from 'projectDesign/components/LowEdgeToRoof';
import PanelSpacingUpdator from 'projectDesign/components/PanelSpacingUpdator';
import { PANEL_SPACINGS_UPDATOR_PERMISSION } from 'user/userGroups';
import StructureTiltSelector from 'projectDesign/components/StructureTiltSelector';
import PanelsRotation from 'projectDesign/components/PanelsRotation';
import { applyPanelRotationFactorChanges, applyEcoFoot2PlusRM10andEvoSetbackChanges, applyRM5SetbackChanges, adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';
import { isBlankMap } from '__common/constants/map';

class EditorControlPanel extends React.Component<editorControlPanelComponentState, {}> {

  getArrayName = () => {
    const { background: { selectedRoofId }, drawingManager: { roofs } } = this.props;
    return roofs[selectedRoofId].roofName;
  }

  hasExclusiveStaffPermission = () => {
    return this.props.userPermissions.indexOf(PANEL_SPACINGS_UPDATOR_PERMISSION) > -1;
  }

  SM() {
    const { dimensions: { ns, ew }, projectEnvConfig:{ is_aurora_project }} = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        {this.hasExclusiveStaffPermission() && <PanelSpacingUpdator />}
        {!is_aurora_project && (
          <>
            <SetArrayTransparent />
            <RoofZonesAutofill />
          </>
        )}
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  SMTiltPR() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <StructureTiltSelector />
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  NxtHorizon() {
    const { dimensions: { ns, ew }, projectEnvConfig:{ is_aurora_project }} = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        {!is_aurora_project && (
          <>
            <SetArrayTransparent />
            <RoofZonesAutofill />
          </>
        )}
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  SMTilt(productId) {
    const { dimensions: { ns, ew }, projectVersion } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        {adjustableTiltSystem(projectVersion) ? <RoofPitchSelector productId={productId}/> : null}
        <SetArrayTransparent />
        <RoofZonesAutofill />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }
  
  Ascender() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <SetArrayTransparent />
        <div   style={{display:'inline-flex',  flexWrap: 'wrap',   gap: '12px'}}>
          <StructureTypeChange />
          <StructureTypeTilt />
        </div>
        <StructureTypeSpanTilt />
        <LowEdgeToRoof />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
      </div>
    );
  }

  SfmEnhanced() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        <SetArrayTransparent />
        <RoofZonesAutofill />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  MetalX() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        <SetArrayTransparent />
        <RoofZonesAutofill />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  SF() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        <SetArrayTransparent />
        <RoofZonesAutofill />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  RM5(productId) {
    const { dimensions: { ns, ew }, mapType, projectVersion } = this.props;
    return (
      <div className="panels-options">
        <div>
          <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
          <RoofPitchSelector productId={productId}/>
          {isBlankMap(mapType) && applyRM5SetbackChanges(projectVersion) ? <BuildingLengthWidth /> : ' '}
          <SetArrayTransparent />
          <RowSpacing />
          <ArrayDimensions ns={ns} ew={ew} />
          <RoofZonesComponent />
          <RecheckExposureButton />
        </div>
      </div>
    );
  }

  RM10(productId) {
    const { dimensions: { ns, ew }, mapType, projectVersion } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector productId={productId}/>
        {isBlankMap(mapType) && applyEcoFoot2PlusRM10andEvoSetbackChanges(products.rm, projectVersion) || (isRM5(productId) && applyRM5SetbackChanges(projectVersion))? <BuildingLengthWidth /> : ' '}
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  RM10Evolution(productId) {
    const { dimensions: { ns, ew }, mapType, projectVersion, isStaff } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        {applyPanelRotationFactorChanges(projectVersion) && isStaff ? <PanelsRotation />: null}
        <RoofPitchSelector productId={productId}/>
        {isBlankMap(mapType) && applyEcoFoot2PlusRM10andEvoSetbackChanges(products.rm_10_evolution, projectVersion) ? <BuildingLengthWidth /> : ' '}
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        {this.hasExclusiveStaffPermission() && <PanelSpacingUpdator />}
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  RMGridFlex10(productId) {
    const { dimensions: { ns, ew }, mapType, projectVersion } = this.props;    
    
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector productId={productId}/>
        <RowSpacing productId={productId}/>
        {isBlankMap(mapType) ? <BuildingLengthWidth /> : ' '}
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  ecoFoot2Plus(productId: number) {
    const { dimensions: { ns, ew }, mapType, projectVersion} = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        {isBlankMap(mapType) && applyEcoFoot2PlusRM10andEvoSetbackChanges(products.ecofoot2_plus, projectVersion) ? <BuildingLengthWidth /> : ' '}
        <SetArrayTransparent />
        <RoofPitchSelector productId={productId} />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  RM10Ultra() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector />
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
      </div>
    );
  }

  RMGridFlex(productId: number) {
    const { dimensions: { ns, ew }, mapType } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <RoofPitchSelector productId={productId} />
        <RowSpacing productId={productId} />
        {isBlankMap(mapType) ? <BuildingLengthWidth /> : ' '}
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
      </div>
    );
  }

  RMDT() {
    const { dimensions: { ns, ew } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <SetArrayTransparent />
        <ArrayDimensions ns={ns} ew={ew} />
        <RoofZonesComponent />
        <RecheckExposureButton />
      </div>
    );
  }

  GFT() {
    const { dimensions: { ns, ew }, drawingManager: { roofs }, background: { selectedRoofId } } = this.props;
    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <SetArrayTransparent />
        <DesiredTableSize />
        {roofs[selectedRoofId].tableRowSpacing != 0 ? <TableRowSpacingGFT /> : null}
        <ArrayDimensions ns={ns} ew={ew} />
      </div>
    );
  }

  ULA() {
    const { dimensions: { ns, ew } } = this.props;

    return (
      <div className="panels-options">
        <CustomName onChange={this.setArrayName} value={this.getArrayName()} placeHolder="Array name" />
        <SetArrayTransparent />
        <DesiredTableSizeUla />
        <ArrayDimensions ns={ns} ew={ew} />
      </div>
    );
  }

  renderEditorOptions(productId: number) {
    switch (productId) {
      case products.solarmount_2:
      case products.solarmount_hd:
      case products.solarmount_lt:
      case products.solarmount_ascender_flush:
        return this.SM();
      case products.solarmount_2_tilt:
      case products.nxt_tilt:
      // case products.adjustable_tilt_system:
        return this.SMTilt(productId);
      case products.sunframe:
        return this.SF();
      case products.sfmEnhanced:
      case products.sfmInfinity:
        return this.SfmEnhanced();
      case products.metal_x:
        return this.MetalX();
      case products.rm_5:
        return this.RM5(productId);
      case products.rm:
        return this.RM10(productId);
      case products.rm_dt:
        return this.RMDT();
      case products.rm_10_evolution:
        return this.RM10Evolution(productId);
      case products.gft:
        return this.GFT();
      case products.ula:
        return this.ULA();
      case products.rm_10_ultra:
        return this.RM10Ultra();
      case products.rm_gridflex:
        return this.RMGridFlex(productId);
      case products.rm_gridflex_10:
        return this.RMGridFlex10(productId);
      case products.ecofoot2_plus:
        return this.ecoFoot2Plus(productId);
      case products.ascender:
        return this.Ascender()
      case products.nxt_umount:
      case products.nxt_horizon_lt:
      case products.nxt_horizon_hd:
      // case products.nxt_tilt:
        return this.NxtHorizon()
      case products.sm_tilt_pr:
      case products.sm_tilt_pr_lt:
        return this.SMTiltPR()
      default:
        break;
    }
  }

  setArrayName = (arrayName: string) => {
    const { dispatch, background: { selectedRoofId } } = this.props;
    dispatch(SET_ARRAY_NAME(arrayName, selectedRoofId));
  }

  render() {
    const { productId, tiltedRoof: { roofPitch }, background: { selectedRoofId }, tableLength, tableWidth } = this.props;
    const { projectEnvConfig : { tilt } } = this.props
    const PAGES = [{
      page: 'module selection',
      icon: <SVG src={require('assets/media/icons/sidebar_pitch.svg')} />,
      postRender: undefined,
      active: true,
      keepOpen: roofPitch === '---' || (isGFT(products[productId]) && tableLength === null),
      tooltip: 'Installation details',
      content: (
        <div className="panels-editor-control-panel">
          {this.renderEditorOptions(products[productId])}
        </div>),
    }, {
      page: 'obstructions',
      icon: <SVG src={require('assets/media/icons/icon_town.svg')} />,
      active: false,
      disabled: !roofHasObstructions(selectedRoofId),
      tooltip: 'Obstructions',
      content: (
        <div className="panels-editor-control-panel">
          <ObstructionsComponent />
        </div>),
    }];

    return <Drawer saveButton={true} productId={products[productId]} pages={PAGES} mainClass="project-description-component" />;
  }
}

function mapStateToProps(state: appState) {
  return {
    dimensions: state.dimensions,
    background: state.background,
    drawingManager: state.drawingManager,
    tiltedRoof: state.tiltedRoof,
    obstructions: state.obstructions,
    tableLength: state.panels.desiredTableLength,
    tableWidth: state.panels.desiredTableWidth,
    mapType: state.roofsSelector.mapType,
    userPermissions: state.user.permissions,
    projectEnvConfig : state.projectConfiguration.projectEnvConfig,
    projectVersion: state.projectConfiguration.projectVersion,
    isStaff: state.user.isStaff,
    railsProductId: state.projectConfiguration.railsProductId,
  };
}

export default connect(mapStateToProps)(EditorControlPanel);
