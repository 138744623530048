import { SOIL_CLASS } from "projectDesign/components/projectConfiguration/fields/soilClass";
import { RoofExposureOption } from "projectDesign/components/projectConfiguration/fields/types/roofExposure";
import { calculateKe, calculateKz, get_design_life_factor, get_numerical_coefficient } from "projectDesign/components/projectConfiguration/utils/coefficients";
import { isASCE716or722 } from "__common/constants/buildingCodes";
import store from "__common/store";

export function getEcoFoot2PlusProjectConfigurationData(projectEnvConfig: any) {
  const {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    seismic_s1,
    elevation,
    dead_load_factor_modification,
    mean_recurrence_interval,
    building_height,
    roof_type,
    risk_category,
    parapet_height,
    parapet_height_input,
    setback_distance,
    block_weight,
    wind_exposure,
    building_length,
    env_factors_for,
    client_address,
    client_name,
    created,
    updated,
    created_user_time,
    imported_helioscope_layout,
    deflectors,
    ecofoot_surface,
    slip_sheet,
    shortest_building_length,
    central_support,
    limit_down_point_loads,
    max_down_point_load_to_be_allowed,
    topographical_factor_kzt,
    velocity_pressure_exposure_coefficient_kz,
    numberical_coefficient,
    design_life_factor_fc,
    wind_directionality_factor_kd,
    roof_exposure,
    ground_elevation_factor_ke,
    soil_class,
    created_user_is_staff,
    building_height_greater_than_6_storey,
    nbcc_category,
    nbcc_roughness,
    nbcc_wind_pressure,
    rain_load,
    seismic_sds,
    seismic_sd1,
    tornado_speed,
    helioscope_id,
  } = projectEnvConfig;


  const {
    projectConfiguration,
  } = store.getState();
  return {
    zipcode,
    building_code,
    wind_speed,
    snow_load,
    seismic_ss,
    seismic_s1,
    elevation,
    mean_recurrence_interval,
    dead_load_factor_modification,
    building_height,
    roof_type,
    risk_category,
    parapet_height,
    parapet_height_input,
    setback_distance,
    block_weight,
    wind_exposure,
    building_length,
    env_factors_for,
    client_address,
    client_name,
    created,
    updated,
    created_user_time,
    imported_helioscope_layout,
    deflectors,
    ecofoot_surface,
    slip_sheet,
    shortest_building_length,
    central_support,
    limit_down_point_loads,
    max_down_point_load_to_be_allowed,
    topographical_factor_kzt: topographical_factor_kzt || 1,
    velocity_pressure_exposure_coefficient_kz : velocity_pressure_exposure_coefficient_kz || calculateKz(wind_exposure, building_height, zipcode, projectConfiguration?.projectVersion),
    wind_directionality_factor_kd: wind_directionality_factor_kd || 0.85,
    roof_exposure: roof_exposure || RoofExposureOption.partiallyExposed.value,
    ground_elevation_factor_ke: ground_elevation_factor_ke || calculateKe(elevation, isASCE716or722(building_code)),
    numberical_coefficient: numberical_coefficient || get_numerical_coefficient(building_code, elevation),
    design_life_factor_fc: mean_recurrence_interval === 50 && design_life_factor_fc === 0.8649000000000001 ? 1 : design_life_factor_fc ? design_life_factor_fc : get_design_life_factor(mean_recurrence_interval),
    soil_class: soil_class || (isASCE716or722(building_code) ? SOIL_CLASS.D_DEFAULT : SOIL_CLASS.D),
    created_user_is_staff,
    building_height_greater_than_6_storey,
    nbcc_category,
    nbcc_roughness,
    nbcc_wind_pressure,
    rain_load,
    seismic_sds,
    tornado_speed,
    seismic_sd1,
    helioscope_id,
  };
}
