export const apiField = 'seismic_ip';

export const SEISMIC_IMPORTANCE_FACTOR_BASED_ON_RISK_CATGORY = [1.0, 1.0, 1.25, 1.5]; //here index indicates risk_category - 1

interface SeismicIpField {
  type: string;
  label: string;
  apiField: string;
  missing?: any;
  min?: number;
  max?: number;
  step?: number;
}

const config: SeismicIpField = {
  type: 'Number',
  label: 'seismic_ip',
  apiField,
  missing: undefined,
  min: 1,
  max: 1.5,
  step: 0.1,
};

export default config;
