import { lbsToKgs } from '__common/calculations/unitConversions';
import { isMetricUnit } from 'engineering/components/engineeringProjectDocuments/utils/unitTypes';
import { round } from 'lodash';
import ballastBlockHalfWeightTooltip from 'projectDesign/components/projectConfiguration/fieldsTooltips/ballastBlockHalfWeightTooltip';
export const apiField = 'half_block_weight'

function makeConfig(max: number = 100, min: number = 1, label:string= 'ballastHalfBlockWeightLbs') {
  return {
    type: 'Number',
    min,
    max,
    apiField,
    label,
    tooltip: ballastBlockHalfWeightTooltip,
  };
}

const config  = makeConfig();

export default config;

export const ballastBlockHalfWeightField = (inputUnit: number = 1) => isMetricUnit(inputUnit) ?  makeConfig(round(lbsToKgs(100), 1), 1, 'ballastHalfBlockWeightKg') : config;

