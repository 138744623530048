export const apiField = 'is_ul3741_required';

export default {
  label: 'isUL3741Required',
  type: 'Select',
  apiField,
  missing: undefined,
  data: [
    { value: 1, name: 'YES' },
    { value: 0, name: 'NO' },
  ],
};
