import { asce705WindSpeedForSmTilt as windSpeed } from 'projectDesign/components/projectConfiguration/fields/windSpeed';
import { smTiltBuildingCode as buildingCode } from 'projectDesign/components/projectConfiguration/fields/buildingCode';
import { asce705BuildingHeightForSmTilt , adjustableTiltBuildingHeight } from 'projectDesign/components/projectConfiguration/fields/buildingHeight';
import clientName from 'projectDesign/components/projectConfiguration/fields/clientName';
import clientAddress from 'projectDesign/components/projectConfiguration/fields/clientAddress';
import elevation from 'projectDesign/components/projectConfiguration/fields/elevation';
import rafterSpacing from 'projectDesign/components/projectConfiguration/fields/rafterSpacing';
import {railFinish  as smRailFinish, adjustableTiltSystemRailFinish}from 'projectDesign/components/projectConfiguration/fields/railFinish';
import roofType from 'projectDesign/components/projectConfiguration/fields/roofType';
import  { getSnowLoadField } from 'projectDesign/components/projectConfiguration/fields/snowLoad';
import span from 'projectDesign/components/projectConfiguration/fields/span';
import tilt, {tiltForAdjustableTilt} from 'projectDesign/components/projectConfiguration/fields/tilt';
import WindExposure from 'projectDesign/components/projectConfiguration/fields/smTiltWindExposure';
import { checkEnvironmentalFactors } from '../utils/checkEnvironmentalFactors';
import {  checkBuildingHeight, checkFieldNotEmpty, checkRafterSpacing, checkSeismic } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/validateProject';
import { getASCE716722BuildingDescriptionResidentalAdditionalInputs } from '../fields/getASCE716BuildingDescriptionResidentalAdditionalInputs';
import { getASCE716722LocationsResidentalAdditionalInputs } from '../fields/getASCE716LocationsResidentalAdditionalInputs';
import { smTiltClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';
import { state } from '__common/store';
import { adjustableTiltSystem } from '__common/utils/versionCompare/versionCompare';
import AdjustableTiltSystemField from '../fields/adjustableTiltSystem';
import topographicalFactorKzt from '../fields/topographicalFactorKzt';
import seismicSs from 'projectDesign/components/projectConfiguration/fields/seismicSs';
import { nhMidAndEndClamps } from 'projectDesign/components/projectConfiguration/fields/smClamps';


export default (buildingCodeValue: number) => {


  const { moduleSelector: { modelData }, projectConfiguration: {projectEnvConfig: {rafter_spacing_inches, adjustable_tilt_system, seismic_ss, topographical_factor_kzt, building_height,}, projectVersion}} = state();

  const snowLoad = getSnowLoadField();

  checkEnvironmentalFactors(buildingCode, windSpeed, snowLoad, seismicSs, elevation);
  const railFinish = adjustableTiltSystem(projectVersion) ? adjustableTiltSystemRailFinish : smRailFinish;
  
  seismicSs.missing = !checkSeismic(seismic_ss);
  let topographicalFactorKztField;
  if(adjustableTiltSystem(projectVersion)) {
    topographicalFactorKzt.missing = !checkFieldNotEmpty(topographical_factor_kzt);
    topographicalFactorKztField = topographicalFactorKzt;
  } else {
    topographicalFactorKztField = [];
  }

  rafterSpacing.missing = !checkRafterSpacing(Number(rafter_spacing_inches));
  adjustableTiltBuildingHeight.missing = !checkBuildingHeight(building_height * 1);


  const cfg = {
    zipCode: 'zipcode',
    Area: 'Peoria, AZ',
    WindSpeed: 'wind_speed',
    SnowFall: 'snow_load',
    Client: {
      label: '',
      fields: [
        clientName,
        clientAddress,
      ],
    },
    LocationAndLoads: {
      label: 'loads',
      fields: [
        buildingCode,
        WindExposure,
        windSpeed,
        snowLoad,
        elevation,
        seismicSs,
        ...getASCE716722LocationsResidentalAdditionalInputs(buildingCodeValue),
        topographicalFactorKztField,
      ],
    },
    BuildingDescription: {
      label: 'Building description',
      fields: [
        adjustableTiltSystem(projectVersion) ? adjustableTiltBuildingHeight : asce705BuildingHeightForSmTilt,
        roofType,
        adjustableTiltSystem(projectVersion) ? (adjustable_tilt_system === 9  ? smTiltClamps(modelData.height) : nhMidAndEndClamps('end_clamps_choices','end_clamps')) : smTiltClamps(modelData.height),
        adjustableTiltSystem(projectVersion) && adjustable_tilt_system === 34 ? nhMidAndEndClamps('mid_clamps_choices','mid_clamps') : [],
        ...getASCE716722BuildingDescriptionResidentalAdditionalInputs(buildingCodeValue),
      ],
    },
    railFinish,
    Spans: {
      label: '',
      fields: [
        adjustableTiltSystem(projectVersion) ? AdjustableTiltSystemField : [],
        span,
        rafterSpacing,
        adjustableTiltSystem(projectVersion) ? tiltForAdjustableTilt : tilt,
      ],
    },
    // railFinish,
    railTypes: true,
  };

  return cfg;
};

