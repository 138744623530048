export const DEBUG = {
  /** display debug messages for checking exposure coverage */
  showExposureCoverageDebug: false,
  /** display exposure extent area on panels editor */
  showExposureCoverageAreas: false,
  /** display panel's id on canvas within panels editor. */
  showPanelId: false,
  showSlopMark: true,
  showBayId: false,
  showGroupId: false,
  showCornerAndEdgeIndex: false,
  /** show different outline colors for each configuration of exposed and exposed neighbour */
  highlightExposedAndExposedNeighbour: false,
  showFlatASCE716HelperPoints: false,
  angle315checkerHelper: false,
  showExposureZoneCollisionArea: false,
  showExposureZonePolygonCheckEdge: false,
  showPanelsRestrictedArea: false,
  showPanelsStickyPoints: false,
  showPanelsStickyPointsLabels: false,
  showCursorSnappingLogs: false,
  showPageErrorModal: false,
  showCursorPosition: false,
  enablePanelsRedo: false,
  enablePanelsUndo: false,
};
