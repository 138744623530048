import { Action } from '__common/store/action';
import { changeUrlOnFirstAutoSave } from '__editor/components/roofsSelector/components/roofsSelectorSaveLoadProject/utils/autosave';
import { ProjectConfigurationActionTypes } from 'actionsConstants';
import { windWarning } from 'projectDesign/components/projectConfiguration/utils/windWarning';
import { dispatch } from '__common/store';
import AppHistory from '__common/modules/history';


export function LOAD_PROJECT_CONFIGURATION(projectEnvConfig: projectEnvConfig, productId?: number, verified_flyouts?: verifiedFlyouts) {
  return {
    type: ProjectConfigurationActionTypes.LOAD_PROJECT_CONFIGURATION,
    payload: {
      projectEnvConfig,
      verified_flyouts,
      productId,
    },
  };
}

export function LOAD_HELIOSCOPE_PROJECT_CONFIGURATION(projectEnvConfig: projectEnvConfig) {
  return {
    type: ProjectConfigurationActionTypes.LOAD_HELIOSCOPE_PROJECT_CONFIGURATION,
    payload: {
      projectEnvConfig,
    },
  };
}

export function SET_PROJECT_OPTION(field: string, value: any) {
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_OPTION,
    payload: {
      field,
      value,
    },
  };
}

export function CLEAR_WARNING(field: string) {
  return {
    type: ProjectConfigurationActionTypes.CLEAR_WARNING,
    payload: {
      field,
    },
  };
}

export function FETCH_MINIMUM_VALID_SETBACK_DISTANCE() {
  return {
    type: ProjectConfigurationActionTypes.FETCH_MINIMUM_VALID_SETBACK_DISTANCE,
  };
}

export function SET_SEISMIC_SETBACK_DISTANCE(seismicSetbackDistance: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_SEISMIC_SETBACK_DISTANCE,
    payload: {
      seismicSetbackDistance,
    },
  };
}


export function CLEAR_INVALID_FIELD(field: string){
  return {
    type: ProjectConfigurationActionTypes.CLEAR_INVALID_FIELD,
    payload: {
      field,
    },
  };
}

export function CLEAR_PROJECT_CONFIGURATION() {
  return {
    type: ProjectConfigurationActionTypes.CLEAR_PROJECT_CONFIGURATION,
  };
}

export function SET_PRODUCT_ID(productId: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_PRODUCT_ID,
    payload: {
      productId,
    },
  };
}

export function SET_PROJECT_VERSION(projectVersion: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_VERSION,
    payload: {
      projectVersion,
    },
  };
}

export function SET_PROJECT_ID(projectId: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_ID,
    payload: {
      projectId,
    },
  };
}

export function SET_PRODUCT_TITLE(productTitle: { type: string, name: string }) {
  return {
    type: ProjectConfigurationActionTypes.SET_PRODUCT_TITLE,
    payload: {
      productTitle,
    },
  };
}

export function CLEAR_PRODUCT_TITLE() {
  return {
    type: ProjectConfigurationActionTypes.CLEAR_PRODUCT_TITLE,
  };
}

export function SET_ZIP_AND_ADDRESS_CODE(zipCode: string, address: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_ZIP_AND_ADDRESS_CODE,
    payload: {
      zipCode,
      address,
    },
  };
}

export function SET_GONE_THROUGH_THE_PANEL_EDITOR(gone_through_panel_editor: boolean) {
  return {
    type: ProjectConfigurationActionTypes.SET_GONE_THROUGH_THE_PANEL_EDITOR,
    payload: {
      gone_through_panel_editor,
    },
  };
}

export function SET_GONE_THROUGH_THE_PANEL_EDITOR_FOR_EXPOSURE_CORRECT_CALCULATIONS(gone_through_panel_editor_for_exposure_correct_calculations: boolean){
  return {
    type: ProjectConfigurationActionTypes.SET_GONE_THROUGH_THE_PANEL_EDITOR_FOR_EXPOSURE_CORRECT_CALCULATIONS,
    payload: {
      gone_through_panel_editor_for_exposure_correct_calculations,
    },
  };
}

export function SET_IS_AURORA_PROJECT(is_aurora_project: boolean) {
  return {
    type: ProjectConfigurationActionTypes.SET_IS_AURORA_PROJECT,
    payload: {
      is_aurora_project,
    },
  };
}

export function GET_ENVIRONMENTAL_FACTORS(lat: number, lng: number, zipcode: string, buildingCode: number, productId: number, risk_category: number) {
  return {
    type: ProjectConfigurationActionTypes.GET_ENVIRONMENTAL_FACTORS,
    payload: {
      lat,
      lng,
      zipcode,
      buildingCode,
      productId,
      risk_category,
    },
  };
}

export interface SET_ENVIRONMENTAL_FACTORS_ACTION {
  zipcode: string;
  productId: number;
  factors: {
    wind_speed: number;
    wind_special: boolean;
    snow_load: number; 
    elevation: number;
    dead_load_factor_modification?: number;
    additional_load_combination?:number,
    adjustable_tilt_system?: number,
    seismic_ss?: number;
    seismic_s1?: number;
    building_code?: number;
  };
}

export function SET_ENVIRONMENTAL_FACTORS(
  zipcode: string, 
  productId: number, 
  factors: { wind_speed: number, wind_special: boolean, snow_load: number, elevation: number, seismic_s1: number, seismic_ss:number, building_code?: number},
): Action<SET_ENVIRONMENTAL_FACTORS_ACTION> {
  if (factors.wind_special) {
    windWarning();
  }
  
  return {
    type: ProjectConfigurationActionTypes.SET_ENVIRONMENTAL_FACTORS,
    payload: {
      zipcode,
      factors,
      productId,
    },
  };
}

export function CLEAR_ENV_FACTORS_FOR() {
  return {
    type: ProjectConfigurationActionTypes.CLEAR_ENV_FACTORS_FOR,
  };
}

export function ENV_LOADING_FINISHED() {
  return {
    type: ProjectConfigurationActionTypes.ENV_LOADING_FINISHED,
  };
}

export function BLANK_ENVIRONMENTAL_FACTORS(zipcode: string, productId: number) {
  return {
    type: ProjectConfigurationActionTypes.BLANK_ENVIRONMENTAL_FACTORS,
    payload: {
      zipcode,
      productId,
    },
  };
}

export function SET_PROJECT_NAME(projectName: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_NAME,
    payload: {
      projectName,
    },
  };
}

export function SET_CITY_NAME(area: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_CITY_NAME,
    payload: {
      area,
    },
  };
}

export function SET_RAILS_PRODUCT_ID(railsProductId: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_RAILS_PRODUCT_ID,
    payload: {
      railsProductId,
    },
  };
}

export function SET_SPAN(preferred_span: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_SPAN,
    payload: {
      preferred_span,
    },
  };
}

export function CHANGE_STRUCTURE_TYPE_TILT(structureType: number, tilt: number) {
  return {
    type: ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE_TILT,
    payload: {
      structureType,
      tilt,
    },
  };
}

export function CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN(structureType: number, preferred_span: any) {
  return {
    type: ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE_PREFERRED_SPAN,
    payload: {
      structureType,
      preferred_span,
    },
  };
}

export function SAVE_PROJECT_TITLE(customTitle: string, projectId: string) {
  return {
    type: ProjectConfigurationActionTypes.SAVE_PROJECT_TITLE,
    payload: {
      customTitle,
      projectId,
    },
  };
}

export function OPEN_DRAWER_PAGE(page: string) {
  return {
    type: ProjectConfigurationActionTypes.OPEN_DRAWER_PAGE,
    payload: {
      openedDrawerPage: page,
    },
  };
}

export function SET_PROJECT_CONFIGURED() {
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_CONFIGURED,
  };
}

export function RESET_PROJECT_CONFIGURED() {
  return {
    type: ProjectConfigurationActionTypes.RESET_PROJECT_CONFIGURED,
  };
}

export function SET_PROJECT_ID_ON_AUTOSAVE(data) {
  let project_id = data
  changeUrlOnFirstAutoSave(project_id);
  return {
    type: ProjectConfigurationActionTypes.SET_PROJECT_ID_ON_AUTOSAVE,
    payload: {
      projectId: project_id,
    },
  };
}

export function REMOVE_OPTION_FROM_CONFIG(field: string) {
  return {
    type: ProjectConfigurationActionTypes.REMOVE_OPTION_FROM_CONFIG,
    payload: {
      field,
    },
  };
}

export function REMOVE_OPTIONS_FROM_CONFIG(...fields: string[]) {
  return {
    type: ProjectConfigurationActionTypes.REMOVE_OPTIONS_FROM_CONFIG,
    payload: {
      fields,
    },
  };
}

export interface SET_FIELD_INVALID_ACTION {
  fieldName: string;
  message: string;
}

export function SET_FIELD_INVALID(field: string, message: string): Action<SET_FIELD_INVALID_ACTION> {
  return {
    type: ProjectConfigurationActionTypes.SET_FIELD_INVALID,
    payload: {
      fieldName: field,
      message,
    },
  };
}


export interface SET_FIELD_WARNING_ACTION {
  fieldName: string;
  message: string;
}

export function SET_FIELD_WARNING(field: string, message: string): Action<SET_FIELD_WARNING_ACTION> {
  return {
    type: ProjectConfigurationActionTypes.SET_FIELD_WARNING,
    payload: {
      fieldName: field,
      message,
    },
  };
}


export interface FETCH_VALID_RAIL_ARRANGEMENT_LIST_ACTION {
  projectId: string;
}

export function FETCH_VALID_RAIL_ARRANGEMENT_LIST(projectId: string): Action<FETCH_VALID_RAIL_ARRANGEMENT_LIST_ACTION> {
  return {
    type: ProjectConfigurationActionTypes.FETCH_VALID_RAIL_ARRANGEMENT_LIST,
    payload: {
      projectId,
    },
  };
}

export function SET_RAIL_ARRANGEMENT_VALID_LIST(railArrangementTypeValidList: number[]) {
  return {
    type: ProjectConfigurationActionTypes.SET_RAIL_ARRANGEMENT_VALID_LIST,
    payload: {
      railArrangementTypeValidList,
    },
  };
}

export function RESET_CLAMP_CHECK() {
  return {
    type: ProjectConfigurationActionTypes.RESET_CLAMP_CHECK,
  };
}

export function SET_CLAMP_CHECK_FAILED() {
  return {
    type: ProjectConfigurationActionTypes.SET_CLAMP_CHECK_FAILED,
  };
}
export function CHANGE_STRUCTURE_TYPE(structure_type: any) {
  return {
    type: ProjectConfigurationActionTypes.CHANGE_STRUCTURE_TYPE,
    payload: {
      structure_type: structure_type,
    },
  };
}

export function SET_DEAD_LOAD_FACTOR_MODIFICATION(dead_load_factor_modification: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_DEAD_LOAD_FACTOR_MODIFICATION,
    payload: {
      dead_load_factor_modification,
    },
  };
}

export function SET_ADDITIONAL_LOAD_COMBINATION(additional_load_combination: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_ADDITIONAL_LOAD_COMBINATION,
    payload: {
      additional_load_combination,
    },
  };
}

export function SET_ADJUSTABLE_TILT_SYSTEM(adjustable_tilt_system: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_ADJUSTABLE_TILT_SYSTEM,
    payload: {
      adjustable_tilt_system
    },
  };
}

export function SET_SEISMIC_DESIGN_CATEGORY(seismicDesignCategory: string) {
  return {
    type: ProjectConfigurationActionTypes.SET_SEISMIC_DESIGN_CATEGORY,
    payload: {
      seismicDesignCategory,
    },
  };
}

export function TOGGLE_INPUT_UNIT() {
  return {
    type: ProjectConfigurationActionTypes.TOGGLE_INPUT_UNIT,
  };
}

export function SET_INPUT_UNIT(inputUnit: number) {
  return {
    type: ProjectConfigurationActionTypes.SET_INPUT_UNIT,
    payload: {
      inputUnit,
    },
  };
}

export function SET_FLYOUT_VERIFIED(flyout: string, value: boolean, error = false) {
  return {
    type: ProjectConfigurationActionTypes.SET_FLYOUT_VERIFIED,
    payload: {
      flyout,
      value,
      error,
    },
  };
}

export function LOAD_AURORA_PROJECT_CONFIGURATION(projectEnvConfig: projectEnvConfig) {
  dispatch(SET_PRODUCT_ID(projectEnvConfig.aurora_default_product_id))
  return {
    type: ProjectConfigurationActionTypes.LOAD_AURORA_PROJECT_CONFIGURATION,
    payload: {
      projectEnvConfig,
    },
  };
}

export function UPDATE_AURORA_PROJECT_CONFIGURATION(projectEnvConfig: projectEnvConfig) {
  return {
    type: ProjectConfigurationActionTypes.UPDATE_AURORA_PROJECT_CONFIGURATION,
    payload: {
      projectEnvConfig,
    },
  };
}

export function REDIRECT_TO_AURORA_PROJECT(aurora_project_id, aurora_design_id) {
  if (aurora_project_id && aurora_design_id) {
    AppHistory.push(`/aurora/design/config/${aurora_project_id}/${aurora_design_id}/`)
  }

  return {
    type: ProjectConfigurationActionTypes.REDIRECT_TO_AURORA_PROJECT,
  };
}
