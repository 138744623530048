export const apiField = 'wind_iw';

interface WindIwField {
  type: string;
  label: string;
  apiField: string;
  missing?: any;
  min?: number;
  max?: number;
  step?: number;
}

const config: WindIwField = {
  type: 'Number',
  label: 'wind_iw',
  apiField,
  missing: undefined,
  min: 1,
  max: 1.5,
  step: 0.1,
};

export default config;
