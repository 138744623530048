import { state } from '__common/store';
import { isSMFamily, isSF, isSFMFamily, isRMFamily, isNxtHorizon, isNxtTilt, isSMTiltPR, isAscender, isEcoFoot2Plus, isMetalX } from '__common/constants/products';
import { showErrorAlert } from '__common/modules/alerts';

const limitation = {
  // Real Allowable limit for panels are limitation/2
  Sm: 6000,
  SFM: 1000,
  RMFamily: 15000,
};

export function showOverMaxAllowedPanelsAlert(overNumber: number, limit: number) {
  const {  } = state();
  
  const message = `Please generate your calculations in smaller segments through multiple U-builder projects.`;
  
  showErrorAlert(message, 10);
}

// export function limitaionWariningWhenPanelOffsetHit(limit: number, overNumber: number) {  
//   const message = `Structure validates till ${limit/2}, After that entire panels on the roof will be deleted, Please generate your calculations in smaller segments through multiple U-builder projects.`
//   if ([limitation.Sm, limitation.RMFamily].includes(limit) && overNumber - 1000 > limit/2) {
//        showErrorAlert(message, 10);
//   }else if (limitation.SFM === limit && overNumber - 500 > limit/2) {
//     showErrorAlert(message, 10);
//   }
// }

// export function validateMaxAllowedPanels(newPanels: number) {
//   const { drawingManager: { roofs }, background: { selectedRoofId }, projectConfiguration: { productId }, panels: { panels } } = state();
//   const limit = getProductLimitation(productId);
//   const roofsIds = Object.keys(roofs).map(id => Number(id));
//   const roofsIdsWithoutCurrentRoof = roofsIds.filter(id => id !== selectedRoofId);
//   const panelsNumber = roofsIdsWithoutCurrentRoof.map(roofId => roofs[roofId].panels ? roofs[roofId].panels.length : 0).reduce((a, b) => (a + b), 0);
//   const currentPanelsNumber = panels ? panels.length : 0;
//   const total = newPanels + panelsNumber + currentPanelsNumber;
//   const isOver = total > limit;
//   const overNumber = total - limit;

//   // Checking the panels limit,and giving the user warning before the limit hit the desired quantity
//   limitaionWariningWhenPanelOffsetHit(limit, total)

//   if (isOver) {
//     showOverMaxAllowedPanelsAlert(overNumber, limit);
//   }

//   return isOver;
// }


export function limitaionWariningWhenPanelOffsetHit(limit: number, overNumber: number) {  
  const message = `Structure validates till ${limit/2}, After that entire panels on the roof will be deleted, Please generate your calculations in smaller segments through multiple U-builder projects.`
  if ([limitation.Sm, limitation.RMFamily].includes(limit) && overNumber > (limit/2 -1000) && overNumber <= limit/2) {
       showErrorAlert(message, 10);
  }else if (limitation.SFM === limit && overNumber > (limit/2 -500) && overNumber <= limit/2) {
    showErrorAlert(message, 10);
  }
}

export function validateMaxAllowedPanels(newPanels: number) {
  const { drawingManager: { roofs }, background: { selectedRoofId }, projectConfiguration: { productId }, panels: { panels } } = state();
  const limit = getProductLimitation(productId);

  // Checking the panels limit,and giving the user warning before the limit hit the desired quantity
  limitaionWariningWhenPanelOffsetHit(limit, newPanels)

  const isOver = newPanels > limit/2

  if (isOver) {
    showOverMaxAllowedPanelsAlert(newPanels, limit);
  }

  return isOver;
}

function getProductLimitation(productId: number) {
  if (isSMFamily(productId) || isSF(productId) || isNxtHorizon(productId) || isNxtTilt(productId) || isSMTiltPR(productId) || isAscender(productId)) {
    return limitation.Sm;
  }

  if (isSFMFamily(productId) || isMetalX(productId)) {
    return limitation.SFM;
  }

  if (isRMFamily(productId) || isEcoFoot2Plus(productId)) {
    return limitation.RMFamily;
  }
}
